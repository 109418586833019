import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';

import ClientDebtTypeForm from '../components/client_debt_type-form';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const ClientDebtType = () => {
  const [isNew, setIsNew] = useState(false);
  const params = useParams();
  const location = useLocation();

  const { client_debt_typeId } = params;
  const [client_debt_type, setClientDebtType] = useState({
    name: '',
    active: true,
  });

  function findClientDebtType(id) {
    return apiFetch(`/client_debt_types/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    setIsNew(location.pathname === '/client_debt_types/new');
    if (client_debt_typeId) {
      const client_debt_type = await findClientDebtType(client_debt_typeId);
      setClientDebtType(client_debt_type);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setClientDebtType({ ...client_debt_type, [name]: value });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setClientDebtType({ ...client_debt_type, [name]: checked });
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="Client Debt Type Form">
      {
        location.pathname === '/client_debt_types/new' || client_debt_type ? (
          < ClientDebtTypeForm
            client_debt_type={client_debt_type}
            isNew={isNew}
            handleTextChange={handleTextChange}
            handleToggleChange={handleToggleChange}
          />
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress size={100} />
          </div>
        )
      }
    </Layout>
  );
};

export default ClientDebtType;
