/* eslint-disable no-case-declarations */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { NumericFormat, PatternFormat } from 'react-number-format';

import AddIcon from '@mui/icons-material/Add';
import AddCircle from '@mui/icons-material/AddCircle';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiButton from '@mui/material/Button';
import MuiSelect from '@mui/material/Select';
import MuiTextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import { colors, goldButtonStyling } from './styles';

export function TextField(props) {
  const {
    type = 'text',
    label,
    name,
    value,
    onChange,
    disabled = false,
    required,
    min = null,
    max = null,
    expand = false,
    fullWidth = false,
    xs = null,
    style = {},
    inputStyle = {},
    sx = {},
    error = false,
    errorMsg = null,
  } = props;

  const sizes = [props.sm, props.md, props.lg, props.xl];

  const width = fullWidth ? 12 : expand ? 6 : xs || 3;

  const getFormattedValueAndError = () => {
    let err = false;
    switch (type) {
      case 'date':
        const formattedValue = moment(value).format('YYYY-MM-DD');
        const year = moment(formattedValue).year();
        err = year > 2100
          || (label === 'DL Expiration Date'
            && moment(formattedValue).isBefore(moment())
          );
        return { formattedValue, err, errMsg: 'Please submit a valid date' };
      case 'year':
        err = (value?.length === 4 && (value > max || value < min)) || value?.length > 4;
        return { formattedValue: value, err, errMsg: `Year must be between ${min} and ${max}` };
      default:
        return { formattedValue: value, err };
    }
  };

  const { formattedValue, err, errMsg } = getFormattedValueAndError();

  return (
    <Grid item xs={width} {...sizes}>
      <div style={{ width: '100%', style }}>
        <MuiTextField
          type={type}
          value={formattedValue}
          name={name}
          label={label}
          error={error || err}
          helperText={error || err ? errorMsg || errMsg : null}
          sx={{ width: '100%', ...sx, ...style }}
          InputProps={{ sx: { ...inputStyle, marginTop: 'auto', marginBottom: 'auto' }, min, max }}
          required={required}
          onChange={onChange}
          disabled={disabled}
        />
      </div>
    </Grid>
  );
}

export function TextArea(props) {
  const {
    name,
    value,
    onChange,
    disabled = false,
    style = {},
    required,
    xs = 12,
  } = props;

  const [textAreaH, setTextAreaH] = useState('auto');
  const [divH, setDivH] = useState('auto');
  const textAreaRef = useRef(null);

  const containerStyle = { minHeight: divH, ...style };
  if (required && !value) {
    containerStyle.border = '1px solid red';
  }

  useEffect(() => {
    setDivH(`${textAreaRef.current.scrollHeight}px`);
    setTextAreaH(`${textAreaRef.current.scrollHeight}px`);
  }, [value]);

  const onChangeHandler = (e) => {
    setTextAreaH('auto');
    setDivH(`${textAreaRef.current.scrollHeight}px`);
    onChange(e);
  };

  return (
    <Grid item xs={xs}>
      <div className="input-container" style={containerStyle}>
        <textarea
          ref={textAreaRef}
          value={value}
          name={name}
          onChange={onChangeHandler}
          disabled={disabled}
          rows={1}
          style={{ height: textAreaH, resize: 'none', ...style }}
        />
      </div>
    </Grid>
  );
}

export function Select(props) {
  const {
    label,
    name,
    value,
    onChange,
    style,
    children,
    required,
    error,
    errorMsg,
    disabled = false,
  } = props;
  const containerStyle = style || {};

  return (
    <Grid item xs={3}>
      <div style={containerStyle}>
        <FormControl fullWidth variant="outlined" error={error}>
          <InputLabel htmlFor={name}>{label}</InputLabel>
          <MuiSelect
            label={label}
            value={value}
            name={name}
            onChange={onChange}
            required={required}
            disabled={disabled}
            inputProps={{
              id: name,
            }}
          >
            <MenuItem value="" disabled>
              <em>Select an option</em>
            </MenuItem>
            {children}
          </MuiSelect>
          <FormHelperText>{error && errorMsg}</FormHelperText>
        </FormControl>
      </div>
    </Grid>
  );
}

export function Button(props) {
  const {
    title = '[ Enter Button Label ]',
    variant = 'contained',
    addIcon = false,
    householdadd = false,
    gold = false,
  } = props;
  let icon = props.startIcon;
  let styling = { ...props.style };

  if (gold && !props.disabled) styling = { ...styling, ...goldButtonStyling };
  if (householdadd) {
    styling = { ...styling, color: colors.primaryColor };
    icon = icon || <AddCircle />;
  }

  return (
    <MuiButton
      {...props}
      variant={householdadd ? null : variant}
      startIcon={(addIcon && <AddIcon />) || icon}
      style={styling}
    >
      {props.children || title}
    </MuiButton>
  );
}

export function GridForm(props) {
  return (
    <Box
      sx={{
        paddingLeft: 2,
        width: props.width || 800,
        maxWidth: '100%',
        '& .MuiTextField-root': { m: 1 },
        '& .MuiFormControl-root': { m: 1 },
        '& .input-container': { m: 1 },
      }}
    >
      {props.children}
    </Box>
  );
}

export const MaskInput = ({
  value,
  onChange = null,
  name,
  xs = null,
  style = {},
  sx = {},
  mask_type,
  ...rest
}) => {
  const sizes = [rest.sm, rest.md, rest.lg, rest.xl];

  const width = rest.fullWidth ? 12 : rest.expand ? 6 : xs || 3;

  let pattern;

  switch (mask_type) {
    case 'ssn':
      pattern = '###-##-####';
      break;
    case 'ein':
      pattern = '##-#######';
      break;
    case 'tel':
      pattern = '(###) ###-####';
      break;
    case 'zip':
      pattern = value?.length > 5 ? '#####-####' : '#########';
      break;
    case 'year':
      pattern = '####';
      break;
    default:
      break;
  }

  const handleChange = (event) => {
    if (onChange) {
      onChange({ value: event.value, name });
    }
  };

  return (
    <Grid item xs={width} {...sizes}>
      <div style={{ width: '100%', ...style }}>
        {
          mask_type === 'currency' ? (
            <NumericFormat
              customInput={rest.customInput || MuiTextField}
              value={value}
              thousandSeparator={true}
              prefix="$"
              onValueChange={handleChange}
              fixedDecimalScale={true}
              decimalScale={2}
              allowNegative={false}
              style={style}
              {...rest}
              inputProps={{
                style: {
                  width: '100%',
                  ...sx,
                  ...style,
                },
              }}
            />
          ) : (
            <PatternFormat
              format={rest.pattern || pattern}
              customInput={MuiTextField}
              value={value}
              onValueChange={handleChange}
              sx={{ width: '100%', ...sx, ...style }}
              {...rest}
            />
          )
        }
      </div>
    </Grid>
  );
};

export function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
