import React, { useState } from 'react';

import useSWR from 'swr';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import makeStyles from '@mui/styles/makeStyles';

import { DataGrid } from '@mui/x-data-grid';

import { colors } from '../lib/styles';
import DownloadButton from '../components/download-csv';

const useStyles = makeStyles({
  tintedBackground: {
    backgroundColor: colors.blackOpacity,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'count',
    headerName: 'Records',
    align: 'right',
    width: 120,
  },
  {
    field: 'total_amount',
    headerName: 'Agent Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.total_amount.toFixed(2)}</span>;
    },
  },
  {
    field: 'total_business',
    headerName: 'Total Business',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.total_business.toFixed(2)}</span>;
    },
  },
  {
    field: 'created',
    headerName: 'Month',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <span>{params.row.created ? params.row.created.substring(0, 7) : ''}</span>;
    },
  },
];

const StrongholdPayments = () => {
  const classes = useStyles();
  const [err] = useState(null);

  const { data: payments, loading: paymentsLoading } = useSWR('/payments/month?orderBy=created&limit=5000');
  const rows = (payments || []).map((p, id) => {
    return {
      ...p,
      id,
      total_amount: Number(p.total_amount),
      total_business: Number(p.total_business),
    };
  });
  return (
    <div style={classes.container}>
      <Typography variant="h4">Agent Payments by Month</Typography>
      <div style={classes.mainBody}>
        <div>{err?.toString()}</div>
        {rows && rows.length ? (<DownloadButton rows={rows} name="payments-monthly"/>) : ''}
        <Paper className={classes.tintedBackground}>
          {!paymentsLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={rows} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </div>
  );
};

export default StrongholdPayments;
