import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';

import { colors } from '../lib/styles';
import DownloadButton from '../components/download-csv';
import Layout from '../components/layout';
import { Select } from '../lib/common';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const Agent1099s = () => {
  const classes = useStyles();
  const [year, setYear] = useState((new Date()).getFullYear());
  const [years, setYears] = useState([]);

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'year',
      headerName: 'Year',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        return <span>{new Date(params.row.year).getFullYear()}</span>;
      },
    },
    {
      field: 'num',
      headerName: 'Agent #',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        const to = `/users/${params.row.id}/overview`;
        return <Link style={{ color: colors.darkAccent }} to={to}>{params.row.num}</Link>;
      },
    },
    {
      field: 'firstname',
      headerName: 'First Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      align: 'left',
      width: 150,
    },
    {
      field: 'ssn',
      headerName: 'SSN',
      align: 'left',
      width: 150,
    },
    {
      field: 'ein',
      headerName: 'EIN',
      align: 'left',
      width: 150,
    },
    {
      field: 'address',
      headerName: 'Address',
      align: 'left',
      width: 200,
    },
    {
      field: 'city',
      headerName: 'City',
      align: 'left',
      width: 150,
    },
    {
      field: 'state',
      headerName: 'State',
      align: 'left',
      width: 100,
    },
    {
      field: 'postalcode',
      headerName: 'Postal Code',
      align: 'left',
      width: 100,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        return `$${Number(params.row.amount).toFixed(2)}`;
      },
    },
  ];

  const url = `/payments/all/1099/${year}`;

  useEffect(async () => {
    const currentYear = (new Date()).getFullYear();
    const fullYears = new Array(20);
    for (let i = 0; i < fullYears.length; i++) {
      fullYears[i] = currentYear - i;
    }
    setYears(fullYears);
  }, []);

  const { data, loading } = useSWR(url);
  if (data && data.length) {
    data.forEach((d, idx) => {
      d.id = idx;
      delete d.agent;
      d.amount = Number(d.amount);
    });
  }

  return (
    <Layout headerTitle="Agent 1099s">
      <div className={classes.container}>
        <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} mb={1} sx={{ width: '75%' }}>
          <Box gridColumn="span 3">
            <Select
              select={year}
              label="Year"
              value={year}
              name="year"
              onChange={(evt) => {
                setYear(evt.target.value);
              }}
            >
              {years.map((y) => {
                return (<MenuItem value={y} key={y}>{y}</MenuItem>);
              })}
            </Select>
          </Box>
          {data && data.length ? (
            <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
              <DownloadButton rows={data} name={`agent1099_${year}`} style={{ color: colors.darkAccent }}/>
            </Box>
          ) : ''}
        </Box>
        <Paper>
          {!loading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={data || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default Agent1099s;
