import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import makeStyles from '@mui/styles/makeStyles';

import { DataGrid } from '@mui/x-data-grid';
import { apiFetch } from '../lib/fetch';

import Layout from '../components/layout';
import { colors } from '../lib/styles';
import DownloadButton from '../components/download-csv';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  {
    field: 'from_num',
    headerName: 'Agent #',
    align: 'right',
    width: 80,
  },
  {
    field: 'from_firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'from_lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'tier',
    headerName: 'Pillar',
    align: 'right',
    width: 75,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 130,
    renderCell: (params) => {
      return <span>{Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'max_amount',
    headerName: 'Max Amount (qualified)',
    align: 'right',
    width: 200,
    renderCell: (params) => {
      return <span>{Number(params.row.max_amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'qualify',
    headerName: 'Qualified?',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      const style = {};
      if (!params.row.qualify) {
        style.color = 'red';
      }
      return <span style={style}>{params.row.qualify ? 'true' : 'false'}</span>;
    },
  },
  {
    field: 'statement_date',
    headerName: 'Month',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <span>{params.row.statement_date ? params.row.statement_date.substring(0, 7) : ''}</span>;
    },
  },
  {
    field: 'id',
    headerName: 'Wealth Share',
    align: 'right',
    width: 120,
    renderCell: (params) => {
      return (
        <Link
          to={`/users/${params.row.from_agent}/stronghold-payments`}
          style={{ color: colors.darkAccent }}
        >
          Details
        </Link>
      );
    },
  },
];

const StrongholdPayments = () => {
  const classes = useStyles();
  const params = useParams();
  const { overrideId } = params;
  const [err] = useState(null);
  const [override, setOverride] = useState(null);
  const [details, setDetails] = useState([]);
  useEffect(async () => {
    if (overrideId) {
      const op = await apiFetch(`/override_payments/${overrideId}`);
      const od = await apiFetch(`/override_details?to_agent=${op.agent}&statement_date=${op.statement_date}`);
      setOverride(op);
      setDetails(od);
    }
  }, []);

  let maxOverride = 0;
  let detailSum = 0;

  details.forEach((d) => {
    d.amount = Number(d.amount);
    detailSum += d.amount;
    d.max_amount = Number(d.max_amount);
    maxOverride += d.max_amount;
  });

  detailSum = Math.floor(detailSum * 100) / 100;

  const filename = !override ? `override_details_${overrideId}`
    : `override_details_${override.firstname}_${override.lastname}_${overrideId}`;

  return (
    <Layout headerTitle="Override Details">
      <div className={classes.mainBody}>
        <div>{err?.toString()}</div>
        <div>
          {override ? (
            <>
              <Typography variant="h5">
                {`${override.firstname} ${override.lastname} #${override.num}`}
              </Typography>
              <Typography variant="h5">
                {`Qualified Pillar: ${override.qual_tier}`}
              </Typography>
              <Typography variant="h6">
                {`Override Amount: $${Number(override.amount).toFixed(2)}`}
              </Typography>
              <Typography variant="h6">
                {`Detail Sum: $${Number(detailSum).toFixed(2)}`}
              </Typography>
              <Typography variant="h6">
                {`Max Amount (fully qualified): $${Number(maxOverride).toFixed(2)}`}
              </Typography>
              <Typography variant="h6">
                {`Missed Money: $${Number(maxOverride - Number(override.amount)).toFixed(2)}`}
              </Typography>
            </>
          ) : ''}
        </div>
        <Paper>
          {details && details.length ? (<DownloadButton rows={details} name={filename}/>) : ''}
          {override ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={details || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default StrongholdPayments;
