import React, { useEffect, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import { omit } from 'lodash';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { apiFetch } from '../lib/fetch';
import { colors, Button } from '../lib/styles';
import DeleteButton from './delete-button';
import { MaskInput } from '../lib/common';
import { formatPhoneNumber } from '../lib/util';
import config from '../config';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
});

function ContactForm({
  contact_id,
  setMode,
  company_id,
  addContact,
  clearContactId,
}) {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [contact, setContact] = useState({
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    company_id,
    active: true,
    job_title: '',
  });
  const [contactImage, setContactImage] = useState(null);

  const {
    firstname,
    lastname,
    email,
    phone,
    active,
    job_title,
  } = contact;

  useEffect(() => {
    async function fetchContact() {
      const data = await apiFetch(`/company_contacts/${contact_id}`);
      const [img] = await apiFetch(`/company_contact_images?contact_id=${contact_id}`);
      setContact(omit(data, 'order_val'));
      setContactImage(img);
    }
    if (contact_id) {
      fetchContact();
    }
  }, [contact_id]);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleSave = async () => {
    try {
      if (contact_id) {
        await apiFetch.put(`/company_contacts/${contact_id}`, omit(contact, [
          'id',
          'order_val',
          'created_at',
          'updated_at',
          'company_name',
          'company_order_val',
          'company_active',
          'company_url',
        ]));
        clearContactId();
      } else {
        const newContact = await apiFetch.post('/company_contacts', contact);
        addContact(newContact);
      }
      setMode('all');
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <Typography variant="h6">Edit Contact</Typography>
      {error && <Typography color="error">{error}</Typography>}
      <FormControl
        sx={{ flexDirection: 'column' }}
        component="fieldset"
        variant="standard"
      >
        <div>
          <TextField
            id="firstname"
            name="firstname"
            label="First Name"
            value={firstname}
            onChange={handleTextChange}
          />
          <TextField
            id="lastname"
            name="lastname"
            label="Last Name"
            value={lastname}
            onChange={handleTextChange}
          />
          <TextField
            id="job_title"
            name="job_title"
            label="Job Title"
            value={job_title}
            onChange={handleTextChange}
          />
        </div>

        <div>
          <TextField
            id="email"
            name="email"
            label="Email"
            value={email}
            onChange={handleTextChange}
          />
          <MaskInput
            label="Cell Phone"
            name="phone"
            value={phone}
            onChange={(e) => {
              setContact({ ...contact, phone: e.value });
            }}
            style={{ width: 200 }}
            mask_type="tel"
            errorMsg="Phone number is required"
          />
        </div>
        <div>
          <FormControlLabel
            sx={{ color: colors.darkAccent }}
            control={(
              <Switch
                defaultChecked
                checked={active}
                name="active"
                onChange={(e) => setContact({ ...contact, active: e.target.checked })}
              />
          )}
            label="Active"
            labelPlacement="start"
          />
        </div>
      </FormControl>
      <div>
        <Button
          variant="contained"
          color="primary"
          className={classes.submitBtn}
          onClick={handleSave}
          disabled={!email || !firstname || !lastname || !phone}
          style={{ margin: '5px' }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.submitBtn}
          onClick={() => {
            if (contact_id) {
              clearContactId();
            }
            setMode('all');
          }}
          style={{ margin: '5px' }}
        >
          Cancel
        </Button>
      </div>
      {contact_id && (
        <div
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6">Contact Image</Typography>
          {contactImage && (
            <div>
              <img
                src={`${config.API_URL}/company_contact_images/${contactImage.id}/download`}
                alt="contact"
              />
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    apiFetch(`/company_contact_images/${contactImage.id}`, {
                      method: 'DELETE',
                    });
                    setContactImage(null);
                  }}
                  style={{ marginTop: 10 }}
                >
                  Delete Image
                </Button>
              </div>
            </div>
          )}
          {!contactImage && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                const input = document.createElement('input');
                input.type = 'file';
                input.accept = 'image/*';
                input.onchange = async (e) => {
                  const file = e.target.files[0];
                  const formData = new FormData();
                  formData.append('contact_id', contact_id);
                  formData.append('file', file);
                  const img = await apiFetch('/company_contact_images/files', {
                    method: 'POST',
                    body: formData,
                  });
                  setContactImage(img);
                };
                input.click();
              }}
            >
              Add Image
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

const CompanyContacts = (props) => {
  const classes = useStyles();
  const {
    company_id,
  } = props;
  const [companyContacts, setCompanyContacts] = useState([]);
  const [mode, setMode] = useState('all');
  const [contactId, setContactId] = useState(null);

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'firstname',
      headerName: 'First Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'email',
      headerName: 'Email',
      align: 'left',
      width: 150,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      align: 'left',
      width: 150,
      renderCell: (params) => {
        return <span>{formatPhoneNumber(params.row.phone)}</span>;
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        return (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setContactId(params.row.id);
              setMode('edit');
            }}
          >
            Edit
          </Button>
        );
      },
    },
    {
      field: 'delete',
      headerName: ' ',
      align: 'right',
      width: 75,
      renderCell: (params) => {
        return (
          <DeleteButton
            variant="contained"
            color="primary"
            handleDelete={() => {
              apiFetch(`/company_contacts/${params.row.id}`, {
                method: 'DELETE',
              });
              setCompanyContacts(companyContacts.filter((c) => c.id !== params.row.id));
            }}
          >
            Delete
          </DeleteButton>
        );
      },
    },
  ];

  useEffect(() => {
    async function fetchCompanyContacts() {
      const data = await apiFetch(`/company_contacts?company_id=${company_id}`, {
        method: 'GET',
      });
      setCompanyContacts(data);
    }
    fetchCompanyContacts();
  }, [company_id]);

  const addContact = (contact) => {
    const updatedContacts = [...companyContacts, contact];
    setCompanyContacts(updatedContacts);
  };

  return (
    <div className={classes.container}>
      <Typography variant="h6">Company Contacts</Typography>
      {mode === 'all' && (
        <div>
          <Paper>
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={companyContacts || []} />
            </div>
          </Paper>
          <div style={{ width: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitBtn}
              style={{ float: 'right', margin: '5px' }}
              onClick={() => setMode('add')}
            >
              Add New Contact
            </Button>
          </div>
        </div>
      )}
      {mode === 'add' && (
        <ContactForm
          company_id={company_id}
          setMode={setMode}
          addContact={addContact}
        />
      )}
      {(mode === 'edit' && contactId) && (
        <ContactForm
          contact_id={contactId}
          company_id={company_id}
          setMode={setMode}
          clearContactId={() => setContactId(null)}
        />
      )}
    </div>
  );
};

export default CompanyContacts;
