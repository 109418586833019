import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { omit, orderBy } from 'lodash';

import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import { colors } from '../lib/styles';
import { apiFetch } from '../lib/fetch';

const DraggableTable = ({
  rows, columns, path, toggles,
}) => {
  const [sortedRows, setSortedRows] = useState(orderBy(rows, ['order_val']));

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newRows = [...sortedRows];
    const [movedItem] = newRows.splice(result.source.index, 1);
    newRows.splice(result.destination.index, 0, movedItem);

    setSortedRows(newRows);

    newRows.forEach((row, idx) => {
      const newRow = omit(row, ['edit', 'id', 'created_at', 'updated_at']);

      apiFetch(`/${path}/${row.id}`, {
        method: 'PUT',
        body: {
          ...newRow,
          order_val: idx + 1,
        },
      });
    });
  };

  const handleToggleChange = (event, index) => {
    const { name, checked } = event.target;
    const newRows = [...sortedRows];

    const updatedItem = newRows[index];
    updatedItem[name] = checked;

    setSortedRows(newRows);

    const newRow = omit(updatedItem, ['edit', 'id', 'created_at', 'updated_at']);

    apiFetch(`/${path}/${updatedItem.id}`, {
      method: 'PUT',
      body: {
        ...newRow,
      },
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <TableContainer
            component={Paper}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ width: 25 }}
                  />
                  {columns.map((column) => (
                    <TableCell
                      key={column.field}
                      sx={{ color: colors.darkAccent, fontWeight: 'bold' }}
                    >
                      {column.headerName}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRows.map((item, index) => (
                  <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                    {(provided) => (
                      <TableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <TableCell sx={{ width: 25 }}>
                          <DragIndicatorIcon sx={{ color: colors.darkAccent }} />
                        </TableCell>
                        {
                          columns.map((column) => (
                            <TableCell
                              key={column.field}
                              sx={{ color: colors.darkAccent }}
                            >
                              {
                                toggles && toggles.includes(column.field)
                                  ? (
                                    <Switch
                                      checked={item[column.field]}
                                      name={column.field}
                                      onChange={(e) => handleToggleChange(e, index)}
                                    />
                                  )
                                  : (
                                    <>{item[column.field]}</>
                                  )
                              }
                            </TableCell>
                          ))
                        }
                      </TableRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableTable;
