import TextField from '@mui/material/TextField';
import { withStyles } from '@mui/styles';
import { colors } from '../lib/styles';

const StyledSelect = withStyles(() => ({
  color: colors.darkAccent,
  '& .MuiFormLabel-root': {
    color: colors.darkAccent,
  },
  '& .MuiSvgIcon-root': {
    color: colors.darkAccent,
  },
  '& .MuiOutlinedInput.notchedOutline': {
    color: colors.darkAccent,
    '& > fieldset': {
      borderColor: colors.darkAccent,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: colors.darkAccent,
  },
}))(TextField);

export default StyledSelect;
