/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';

import { apiFetch } from '../lib/fetch';
import { colors, Button } from '../lib/styles';
import config from '../config';
import DownloadButton from '../components/download-csv';
import useUser from '../lib/useUser';
import Layout from '../components/layout';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
  section: {
    backgroundColor: colors.black,
    margin: '15px',
    padding: '5px',
    color: 'white',
    borderRadius: '5px',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
});

const bsfColumns = [
  { field: 'id', hide: true },
  {
    field: 'product_type_name',
    headerName: 'Product',
    align: 'left',
    width: 150,
  },
  {
    field: 'policy_number',
    headerName: 'Policy#',
    align: 'left',
    width: 170,
  },
  {
    field: 'carrier_code',
    headerName: 'Carrier',
    align: 'left',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'Writing Agent',
    align: 'left',
    width: 180,
    renderCell: (params) => {
      const { row } = params;
      return `${row.firstname} ${row.lastname}`;
    },
  },
  {
    field: 'joint',
    headerName: 'Joint',
    align: 'right',
    width: 90,
    renderCell: (params) => {
      if (params.row.joint) {
        return 'Y';
      }
      return 'N';
    },
  },
  {
    field: 'personal',
    headerName: 'Personal',
    align: 'right',
    width: 90,
    renderCell: (params) => {
      if (params.row.personal) {
        return 'Y';
      }
      return 'N';
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'left',
    width: 200,
    renderCell: (params) => {
      if (params.row.status === 'OPEN') {
        return <span>APPROVED</span>;
      }
      return <span>{params.row.status}</span>;
    },
  },
  {
    field: 'details',
    headerName: 'Detail',
    align: 'left',
    width: 200,
    renderCell: (params) => {
      return <Link style={{ color: colors.darkAccent }} to={`/bsfs/${params.row.id}`}>Details</Link>;
    },
  },
];

const householdColumns = [
  { field: 'id', hide: true },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'formattedDob',
    headerName: 'DOB',
    align: 'right',
    width: 100,
  },
  {
    field: 'formattedAge',
    headerName: 'Age',
    align: 'right',
    width: 75,
  },
  {
    field: 'details',
    headerName: 'Detail',
    align: 'left',
    width: 200,
    renderCell: (params) => {
      return (
        <Link
          style={{ color: colors.darkAccent }}
          to={`/clients/${params.row.id}/overview`}
        >
          Details
        </Link>
      );
    },
  },
];

const ClientOverview = () => {
  const [client, setClient] = useState(null);
  const [bsfs, setBsfs] = useState([]);
  const location = useParams();
  const { clientId } = location;
  const auth = useUser();

  useEffect(async () => {
    const clientRecord = await apiFetch(`/clients/${clientId}/details`);
    if (!auth.isServiceRep) {
      const bsfsRecords = await apiFetch(`/bsf?orderBy=submitted_at&limit=5000&client_id=${clientId}`);
      setBsfs(bsfsRecords);
    }
    setClient(clientRecord);
  }, []);

  let agent;
  let householdClients = [];
  if (client) {
    agent = client.agentRecord;
    householdClients = client.household.clients;
    householdClients.forEach((c) => {
      c.formattedDob = c.dob ? moment(c.dob).format('MM-DD-YYYY') : '';
      c.age_years = moment().diff(c.dob, 'years', false);
      c.age_months = moment().diff(c.dob, 'months', false) % 12;
      c.formattedAge = `${c.age_years}Y ${c.age_months}M`;
    });
  }

  const classes = useStyles();

  return (
    <Layout headerTitle="Client Overview">
      {(client) ? (
        <div>
          <div>
            <Typography variant="h3">
              <div>
                Client: {client.firstname} {client.lastname}
                {client.email} {client.dob ? moment(client.dob).format('MM-DD-YYYY') : ''} - {client.num}
              </div>
            </Typography>
            <Typography variant="h5">
              <div>
                Address: {client.address} {client.city} {client.state} {client.postalcode}
              </div>
            </Typography>
          </div>
          <div>
            <Typography variant="h5">
              <div>
                Agent: {agent.firstname} {agent.lastname} #{agent.num} {agent.email}
                <Button gold sx={{ margin: '15px' }}>
                  <Link className={classes.linkBtn} to={`/users/${agent.id}/edit`}>Edit</Link>
                </Button>
                <Button
                  gold
                  sx={{ margin: '15px' }}
                  onClick={() => {
                    let url = `${config.API_URL}/users/p/${agent.id}`;
                    url += `?path=/household/${client.household_id}/client/${client.id}`;
                    document.location.href = url;
                  }}
                >
                  Switch
                </Button>
              </div>
            </Typography>
          </div>
          <div className={classes.section}>
            <Typography variant="h5">
              BSFs
              {bsfs && bsfs.length ? (<DownloadButton rows={bsfs} name={`client_bsfs_${clientId}`}/>) : ''}
            </Typography>
            <Paper>
              {bsfs && bsfs.length ? (
                <div className={classes.gridContainer}>
                  <DataGrid columns={bsfColumns} rows={bsfs || []} />
                </div>
              ) : ''}
            </Paper>
          </div>
          <div className={classes.section}>
            <Typography variant="h5">
              Household
              <DownloadButton rows={householdClients} name={`household_${clientId}`}/>
            </Typography>
            <Paper>
              <div className={classes.gridContainer}>
                <DataGrid columns={householdColumns} rows={householdClients || []} />
              </div>
            </Paper>
          </div>
        </div>
      ) : (
        <div className={classes.loadingCircle}>
          <CircularProgress size={100} />
        </div>
      )}
    </Layout>
  );
};

export default ClientOverview;
