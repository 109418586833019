import React from 'react';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import { apiFetch } from '../lib/fetch';
import { colors, Button } from '../lib/styles';

const ClientTaxFilingStatusTypesForm = (props) => {
  const {
    client_tax_filing_status_type,
    isNew,
    handleTextChange,
    handleToggleChange,
  } = props;

  const { name, active } = client_tax_filing_status_type;

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      id,
      name,
      active,
    } = client_tax_filing_status_type;

    const data = {
      name,
      active,
    };

    if (isNew) {
      apiFetch('/client_tax_filing_status_types', {
        method: 'POST',
        body: data,
      });
    } else {
      apiFetch(`/client_tax_filing_status_types/${id}`, {
        method: 'PUT',
        body: data,
      });
    }

    history.goBack();
  };

  return (
    <Box
      component="form"
      sx={{
        display: 'grid',
        width: '80%',
        margin: 'auto',
        paddingTop: '30px',
        '& .MuiTextField-root': { m: 2 },
      }}
      onSubmit={handleSubmit}
    >
      <FormControl
        sx={{ flexDirection: 'column' }}
        component="fieldset"
        variant="standard"
      >
        <TextField
          id="name"
          name="name"
          label="Tax Filing Status Type Name"
          value={name}
          onChange={handleTextChange}
        />
        <Box sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <FormControlLabel
              sx={{ color: colors.darkAccent }}
              control={(
                <Switch
                  defaultChecked
                  checked={active}
                  name="active"
                  onChange={handleToggleChange}
                />
            )}
              label="Active Client Tax Filing Status Type"
              labelPlacement="start"
            />
          </div>
          <Button gold type="submit" title="Save" />
        </Box>
      </FormControl>
    </Box>
  );
};

export default ClientTaxFilingStatusTypesForm;
