/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
// Configure Material-UI
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { promiseMiddleware } from 'cooldux';
import logger from 'redux-logger';
import { SWRConfig } from 'swr';

import { apiFetch } from './lib/fetch';
import theme from './theme';
import reducers from './state';

// import Home from './pages/home';
// import App from './pages/app';
import Users from './pages/users';
import Signups from './pages/signups';
import ECA from './pages/eca';
import Chargebacks from './pages/chargebacks';
import ChargebackSummary from './pages/chargeback_summary';
import BSFs from './pages/bsfs';
import Clients from './pages/clients';
import BSF from './pages/bsf';
import BSFsMonth from './pages/bsfs_month';
import BSFsWeek from './pages/bsfs_week';
import BSFsQuarter from './pages/bsfs_quarter';
import BSFsYear from './pages/bsfs_year';
import Payments from './pages/payments';
import PaymentsMonth from './pages/payments_month';
import PaymentsWeek from './pages/payments_week';
import Validate from './pages/validate';
import Forgot from './pages/forgot';
import Login from './pages/login';
import UpdatePassword from './pages/updatepassword';
import Network from './pages/network';
import StrongholdPayments from './pages/stronghold_payments';
import StrongholdPaymentsMonth from './pages/stronghold_payments_month';
import StrongholdPaymentsWeek from './pages/stronghold_payments_week';
import OverridePayments from './pages/override_payments';
import OverridePayment from './pages/override_payment';
import OverrideDetails from './pages/override_details';
import User from './pages/user';
import UserOverview from './pages/user_overview';
import RecruitsWeek from './pages/recruits_week';
import RecruitsMonth from './pages/recruits_month';
import RecruitsQuarter from './pages/recruits_quarter';
import RecruitsYear from './pages/recruits_year';
import RecruitDetails from './pages/recruit-details';
import Payment from './pages/payment';
import Config from './pages/config';
import Carriers from './pages/carriers';
import Carrier from './pages/carrier';
import Sources from './pages/sources';
import Source from './pages/source';
import CapitalTypes from './pages/capital_types';
import CapitalType from './pages/capital_type';
import PartnerCompany from './pages/partner_company';
import PartnerCompanies from './pages/partner_companies';
import ProductTypes from './pages/product_types';
import ProductType from './pages/product_type';
import Feedback from './pages/feedback';
import Agent1099s from './pages/agent_1099';
import StrongholdDocuments from './pages/stronghold_documents';
import AdminDocuments from './pages/admin_documents';
import SHPortionPayments from './pages/stronghold_portion_payments';

import ClientOverview from './pages/client_overview';
import ClientAssetTypes from './pages/client_asset_types';
import ClientAssetType from './pages/client_asset_type';
import ClientHoldingTypes from './pages/client_holding_types';
import ClientHoldingType from './pages/client_holding_type';
import ClientDebtTypes from './pages/client_debt_types';
import ClientDebtType from './pages/client_debt_type';
import ClientIncomeSources from './pages/client_income_sources';
import ClientIncomeSource from './pages/client_income_source';
import Tag from './pages/tag';
import Tags from './pages/tags';

import ClientRealEstateTypes from './pages/client_real_estate_types';
import ClientRealEstateType from './pages/client_real_estate_type';
import ClientEmployerPlanTypes from './pages/client_employer_plan_types';
import ClientEmployerPlanType from './pages/client_employer_plan_type';

import ClientFundTypes from './pages/client_fund_types';
import ClientFundType from './pages/client_fund_type';
import ClientAnnuityTypes from './pages/client_annuity_types';
import ClientAnnuityType from './pages/client_annuity_type';
import ClientInsuranceTypes from './pages/client_insurance_types';
import ClientInsuranceType from './pages/client_insurance_type';
import ClientTermInsuranceTypes from './pages/client_term_insurance_types';
import ClientTermInsuranceType from './pages/client_term_insurance_type';
import ClientInstitutions from './pages/client_institutions';
import ClientInstitution from './pages/client_institution';
import ClientEmploymentStatusTypes from './pages/client_employment_status_types';
import ClientEmploymentStatusType from './pages/client_employment_status_type';
import ClientEmploymentOccupationTypes from './pages/client_employment_occupation_types';
import ClientEmploymentOccupationType from './pages/client_employment_occupation_type';
import ClientTaxFilingStatusTypes from './pages/client_tax_filing_status_types';
import ClientTaxFilingStatusType from './pages/client_tax_filing_status_type';
import ClientTrustType from './pages/client_trust_type';
import ClientTrustTypes from './pages/client_trust_types';
import ClientTrustDistinctionType from './pages/client_trust_distinction_type';
import ClientTrustDistinctionTypes from './pages/client_trust_distinction_types';

import BeneficiaryRelationshipTypes from './pages/relationship_types';
import BeneficiaryRelationshipType from './pages/relationship_type';

import StripeLog from './pages/stripe_log';
import PaycenterLog from './pages/paycenter_log';
import DocusignLog from './pages/docusign_log';

import Genders from './pages/genders';
import Gender from './pages/gender';

import TrustedAdvisors from './pages/trusted_advisors';
import TrustedAdvisor from './pages/trusted_advisor';

import Custodians from './pages/custodians';
import Custodian from './pages/custodian';
import UploadReceipt from './pages/upload-receipt';
import SignupsLog from './pages/signups-log';

import MissedMoneyYear from './pages/missed_money_year';
import MissedMoneyYearPillar from './pages/missed_money_year_pillar';
import MissedMoneyMonth from './pages/missed_money_month';
import MissedMoneyMonthPillar from './pages/missed_money_month_pillar';
import FullEmailLog from './pages/full-email-log';
import OverrideDetailsTo from './pages/override_details_to';
import OverrideDetailsFrom from './pages/override_details_from';
import AgentWealthsharePerf from './pages/agent_wealthshare_progress';
import ImageViewer from './pages/image-viewer';
import Dashboard from './pages/admin_dashboard';

import ShareRequests from './pages/share_requests';

import ProtectedRoute from './components/protected-route';

import './index.css';

const middlewares = [thunkMiddleware, promiseMiddleware];

if (process.env.NODE_ENV === 'development') {
  middlewares.push(logger);
}

const store = createStore(reducers, applyMiddleware(...middlewares));

render(
  <Provider store={store}>
    <SWRConfig
      value={{
        refreshInterval: 0,
        fetcher: apiFetch,
      }}
    >
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <ProtectedRoute exact path="/" component={Dashboard} />
            <ProtectedRoute path="/app" component={Dashboard} />
            <ProtectedRoute path="/dashboard" component={Dashboard} />
            <ProtectedRoute exact path="/users/new" component={User} />
            <ProtectedRoute exact path="/feedback" component={Feedback} />
            <ProtectedRoute exact path="/config" component={Config} />
            <ProtectedRoute exact path="/config/:tabname" component={Config} />
            <ProtectedRoute exact path="/users/:userId/edit" component={User} />
            <ProtectedRoute exact path="/users/:userId/overview" component={UserOverview} />
            <ProtectedRoute exact path="/recruits/week" component={RecruitsWeek} />
            <ProtectedRoute exact path="/recruits/month" component={RecruitsMonth} />
            <ProtectedRoute exact path="/recruits/quarter" component={RecruitsQuarter} />
            <ProtectedRoute exact path="/recruits/year" component={RecruitsYear} />
            <ProtectedRoute exact path="/recruits/:userId/:truncType/:date" component={RecruitDetails} />
            <ProtectedRoute exact path="/users" component={Users} />
            <ProtectedRoute exact path="/email_log" component={FullEmailLog} />
            <ProtectedRoute exact path="/email_log/:email" component={FullEmailLog} />
            <ProtectedRoute exact path="/signups" component={Signups} />
            <ProtectedRoute exact path="/bsfs-week" component={BSFsWeek} />
            <ProtectedRoute exact path="/bsfs/:bsfId" component={BSF} />
            <ProtectedRoute exact path="/bsfs-month" component={BSFsMonth} />
            <ProtectedRoute exact path="/bsfs-week" component={BSFsWeek} />
            <ProtectedRoute exact path="/bsfs-quarter" component={BSFsQuarter} />
            <ProtectedRoute exact path="/bsfs-year" component={BSFsYear} />
            <ProtectedRoute exact path="/bsfs" component={BSFs} />
            <ProtectedRoute exact path="/users/:userId/bsfs" component={BSFs} />
            <ProtectedRoute exact path="/users/:userId/clients" component={Clients} />
            <ProtectedRoute exact path="/clients" component={Clients} />
            <ProtectedRoute exact path="/carriers/new" component={Carrier} />
            <ProtectedRoute exact path="/carriers/:carrierId/edit" component={Carrier} />
            <ProtectedRoute exact path="/carriers" component={Carriers} />
            <ProtectedRoute exact path="/eca" component={ECA} />
            <ProtectedRoute exact path="/chargeback_summary" component={ChargebackSummary} />
            <ProtectedRoute exact path="/users/:userId/chargebacks" component={Chargebacks} />
            <ProtectedRoute exact path="/network/:userId" component={Network} />
            <ProtectedRoute exact path="/payments/new" component={Payment} />
            <ProtectedRoute exact path="/payments/:paymentId/edit" component={Payment} />
            <ProtectedRoute exact path="/payments" component={Payments} />
            <ProtectedRoute exact path="/stripe" component={StripeLog} />
            <ProtectedRoute exact path="/paycenter" component={PaycenterLog} />
            <ProtectedRoute exact path="/docusign" component={DocusignLog} />
            <ProtectedRoute exact path="/files" component={StrongholdDocuments} />
            <ProtectedRoute exact path="/admin_files" component={AdminDocuments} />
            <ProtectedRoute exact path="/users/:userId/payments" component={Payments} />
            <ProtectedRoute path="/payments-month" component={PaymentsMonth} />
            <ProtectedRoute path="/payments-week" component={PaymentsWeek} />
            <ProtectedRoute path="/sources/new" component={Source} />
            <ProtectedRoute path="/sources/:sourceId/edit" component={Source} />
            <ProtectedRoute path="/sources" component={Sources} />
            <ProtectedRoute path="/capital_types/new" component={CapitalType} />
            <ProtectedRoute path="/capital_types/:capital_typeId/edit" component={CapitalType} />
            <ProtectedRoute path="/capital_types" component={CapitalTypes} />
            <ProtectedRoute path="/partner_companies/new" component={PartnerCompany} />
            <ProtectedRoute path="/partner_companies/:partnerCompanyId/edit" component={PartnerCompany} />
            <ProtectedRoute path="/partner_companies" component={PartnerCompanies} />
            <ProtectedRoute path="/product_types/new" component={ProductType} />
            <ProtectedRoute path="/product_types/:product_typeId/edit" component={ProductType} />
            <ProtectedRoute path="/product_types" component={ProductTypes} />
            <ProtectedRoute path="/agent_1099" component={Agent1099s} />

            <ProtectedRoute exact path="/clients/:clientId/overview" component={ClientOverview} />

            <ProtectedRoute path="/client_asset_types/new" component={ClientAssetType} />
            <ProtectedRoute path="/client_asset_types/:client_asset_typeId/edit" component={ClientAssetType} />
            <ProtectedRoute path="/client_asset_types" component={ClientAssetTypes} />

            <ProtectedRoute path="/client_holding_types/new" component={ClientHoldingType} />
            <ProtectedRoute path="/client_holding_types/:client_holding_typeId/edit" component={ClientHoldingType} />
            <ProtectedRoute path="/client_holding_types" component={ClientHoldingTypes} />

            <ProtectedRoute path="/client_debt_types/new" component={ClientDebtType} />
            <ProtectedRoute path="/client_debt_types/:client_debt_typeId/edit" component={ClientDebtType} />
            <ProtectedRoute path="/client_debt_types" component={ClientDebtTypes} />

            <ProtectedRoute path="/tags/new" component={Tag} />
            <ProtectedRoute path="/tags/:tagId/edit" component={Tag} />
            <ProtectedRoute path="/tags" component={Tags} />

            <ProtectedRoute path="/client_income_sources/new" component={ClientIncomeSource} />
            <ProtectedRoute path="/client_income_sources/:client_income_sourceId/edit" component={ClientIncomeSource} />
            <ProtectedRoute path="/client_income_sources" component={ClientIncomeSources} />

            <ProtectedRoute path="/client_annuity_types/new" component={ClientAnnuityType} />
            <ProtectedRoute path="/client_annuity_types/:client_annuity_typeId/edit" component={ClientAnnuityType} />
            <ProtectedRoute path="/client_annuity_types" component={ClientAnnuityTypes} />

            <ProtectedRoute path="/client_insurance_types/new" component={ClientInsuranceType} />
            <ProtectedRoute
              path="/client_insurance_types/:client_insurance_typeId/edit"
              component={ClientInsuranceType}
            />
            <ProtectedRoute path="/client_insurance_types" component={ClientInsuranceTypes} />

            <ProtectedRoute path="/client_term_insurance_types/new" component={ClientTermInsuranceType} />
            <ProtectedRoute
              path="/client_term_insurance_types/:client_term_insurance_typeId/edit"
              component={ClientTermInsuranceType}
            />
            <ProtectedRoute path="/client_term_insurance_types" component={ClientTermInsuranceTypes} />

            <ProtectedRoute exact path="/client_real_estate_types" component={ClientRealEstateTypes} />
            <ProtectedRoute exact path="/client_real_estate_types/new" component={ClientRealEstateType} />
            <ProtectedRoute
              exact
              path="/client_real_estate_types/:client_real_estate_typeId/edit"
              component={ClientRealEstateType}
            />

            <ProtectedRoute exact path="/client_employer_plan_types" component={ClientEmployerPlanTypes} />
            <ProtectedRoute exact path="/client_employer_plan_types/new" component={ClientEmployerPlanType} />
            <ProtectedRoute
              exact
              path="/client_employer_plan_types/:client_employer_plan_typeId/edit"
              component={ClientEmployerPlanType}
            />

            <ProtectedRoute exact path="/client_employment_status_types" component={ClientEmploymentStatusTypes} />
            <ProtectedRoute exact path="/client_employment_status_types/new" component={ClientEmploymentStatusType} />
            <ProtectedRoute
              exact
              path="/client_employment_status_types/:client_employment_status_typeId/edit"
              component={ClientEmploymentStatusType}
            />

            <ProtectedRoute
              exact
              path="/client_employment_occupation_types"
              component={ClientEmploymentOccupationTypes}
            />
            <ProtectedRoute
              exact
              path="/client_employment_occupation_types/new"
              component={ClientEmploymentOccupationType}
            />
            <ProtectedRoute
              exact
              path="/client_employment_occupation_types/:client_employment_occupation_typeId/edit"
              component={ClientEmploymentOccupationType}
            />

            <ProtectedRoute exact path="/client_tax_filing_status_types" component={ClientTaxFilingStatusTypes} />
            <ProtectedRoute exact path="/client_tax_filing_status_types/new" component={ClientTaxFilingStatusType} />
            <ProtectedRoute
              exact
              path="/client_tax_filing_status_types/:client_tax_filing_status_typeId/edit"
              component={ClientTaxFilingStatusType}
            />

            <ProtectedRoute path="/client_fund_types/new" component={ClientFundType} />
            <ProtectedRoute path="/client_fund_types/:client_fund_typeId/edit" component={ClientFundType} />
            <ProtectedRoute path="/client_fund_types" component={ClientFundTypes} />

            <ProtectedRoute path="/client_institutions/new" component={ClientInstitution} />
            <ProtectedRoute path="/client_institutions/:client_institutionId/edit" component={ClientInstitution} />
            <ProtectedRoute path="/client_institutions" component={ClientInstitutions} />

            <ProtectedRoute path="/genders/new" component={Gender} />
            <ProtectedRoute path="/genders/:genderId/edit" component={Gender} />
            <ProtectedRoute path="/genders" component={Genders} />

            <ProtectedRoute path="/trusted_advisors/new" component={TrustedAdvisor} />
            <ProtectedRoute path="/trusted_advisors/:trustedAdvisorId/edit" component={TrustedAdvisor} />
            <ProtectedRoute path="/trusted_advisors" component={TrustedAdvisors} />

            <ProtectedRoute exact path="/relationship_types" component={BeneficiaryRelationshipTypes} />
            <ProtectedRoute exact path="/relationship_types/new" component={BeneficiaryRelationshipType} />
            <ProtectedRoute
              exact
              path="/relationship_types/:relationship_typeId/edit"
              component={BeneficiaryRelationshipType}
            />

            <ProtectedRoute path="/client_trust_types/new" component={ClientTrustType} />
            <ProtectedRoute path="/client_trust_types/:client_trust_typeId/edit" component={ClientTrustType} />
            <ProtectedRoute path="/client_trust_types" component={ClientTrustTypes} />

            <ProtectedRoute path="/client_trust_distinction_types/new" component={ClientTrustDistinctionType} />
            <ProtectedRoute
              path="/client_trust_distinction_types/:client_trust_distinction_type_id/edit"
              component={ClientTrustDistinctionType}
            />
            <ProtectedRoute path="/client_trust_distinction_types" component={ClientTrustDistinctionTypes} />

            <ProtectedRoute path="/signups_log/:email" component={SignupsLog} />

            <ProtectedRoute path="/missed_money_year" component={MissedMoneyYear} />
            <ProtectedRoute path="/missed_money_year_pillar" component={MissedMoneyYearPillar} />
            <ProtectedRoute path="/missed_money_month" component={MissedMoneyMonth} />
            <ProtectedRoute path="/missed_money_month_pillar" component={MissedMoneyMonthPillar} />

            <ProtectedRoute path="/custodians/new" component={Custodian} />
            <ProtectedRoute path="/custodians/:custodianId/edit" component={Custodian} />
            <ProtectedRoute path="/custodians" component={Custodians} />
            <ProtectedRoute exact path="/stronghold-payments" component={StrongholdPayments} />
            <ProtectedRoute exact path="/users/:userId/stronghold-payments" component={StrongholdPayments} />
            <ProtectedRoute path="/stronghold-payments-month" component={StrongholdPaymentsMonth} />
            <ProtectedRoute path="/stronghold-payments-week" component={StrongholdPaymentsWeek} />
            <ProtectedRoute exact path="/stronghold-commission-payments" component={SHPortionPayments} />
            <ProtectedRoute path="/override_details/:overrideId" component={OverrideDetails} />
            <ProtectedRoute exact path="/override-payments" component={OverridePayments} />
            <ProtectedRoute exact path="/override-payments/:paymentId/edit" component={OverridePayment} />
            <ProtectedRoute exact path="/users/:userId/overrides" component={OverridePayments} />
            <ProtectedRoute exact path="/users/:agent_id/override_details_to" component={OverrideDetailsTo} />
            <ProtectedRoute exact path="/users/:agent_id/override_details_from" component={OverrideDetailsFrom} />
            <ProtectedRoute exact path="/upload/receipt" component={UploadReceipt} />
            <ProtectedRoute exact path="/agent_wealthshare_progress" component={AgentWealthsharePerf} />
            <ProtectedRoute exact path="/share_requests" component={ShareRequests} />
            <ProtectedRoute exact path="/users/:agentId/share_requests" component={ShareRequests} />
            <ProtectedRoute exact path="/image-viewer/:type/:id" component={ImageViewer} />
            <ProtectedRoute exact path="/image-viewer/:type/:agentId/:id" component={ImageViewer} />
            <Route path="/login" component={Login} />
            <Route path="/validate/:token" component={Validate} />
            <Route path="/reset/:token" component={UpdatePassword} />
            <Route path="/forgot" component={Forgot} />
          </Switch>
        </Router>
      </ThemeProvider>
    </SWRConfig>
  </Provider>, document.getElementById('root'),
);
