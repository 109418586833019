/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';

import Layout from '../components/layout';
import { apiFetch } from '../lib/fetch';
import { FolderItem, AddFolderButton } from '../components/file-tree';
import { getSocket } from '../lib/websocket';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    padding: '5px',
    flexDirection: 'column',
  },
  fileBox: {
    width: '100%',
    margin: '5px',
    border: '1px solid black',
    borderRadius: '3px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyGridContainer: {
    width: '100%',
    display: 'flex',
    height: 50,
    padding: 10,
  },
  submitBtn: {
    margin: '15px',
  },
  fileHeader: {
    display: 'flex',
    fontSize: '1.1rem',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

const DocList = () => {
  const classes = useStyles();
  // const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);

  const allFileMap = {};

  const rootFile = {
    name: 'root',
    id: 0,
    nodeId: 'n0',
    children: [],
    folder: true,
    rootFolder: true,
    files: [],
  };

  allFileMap.n0 = rootFile;

  files.forEach((f) => {
    f.nodeId = 'n' + f.id;
    if (f.folder) {
      f.children = [];
      f.files = [];
    }
    allFileMap[f.nodeId] = f;
  });

  files.forEach((f) => {
    // const parent = folderMap['n' + f.parent_id];
    const parent = allFileMap['n' + (f.parent_id || 0)];
    if (f.folder && parent && parent.folder) {
      parent.children.push(f);
    } else if (parent && !f.folder) {
      parent.files.push(f);
    }
  });

  async function saveFolder(name, parent_id) {
    try {
      const path = '/stronghold_files/folders';
      const toSave = {
        name,
      };
      if (parent_id) {
        toSave.parent_id = parent_id;
      }
      const newFile = await apiFetch.post(path, toSave);
      const filesClone = files.slice(0);
      filesClone.push(newFile);
      setFiles(filesClone);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  async function delFile(fileId) {
    try {
      const path = `/stronghold_files/f/${fileId}`;
      // const path = `/users/${user.id}/households/${household.id}/files/${fileId}`;
      await apiFetch.del(path);
      const filesClone = [];
      files.forEach((f) => {
        if (f.id !== fileId) {
          filesClone.push(f);
        }
      });
      setFiles(filesClone);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  async function renameFolder(fileId, newName) {
    try {
      const path = `/stronghold_files/${fileId}`;
      // const path = `/users/${user.id}/households/${household.id}/files/${fileId}`;
      await apiFetch.put(path, { name: newName });
      const filesClone = [];
      files.forEach((f) => {
        if (f.id === fileId) {
          f.name = newName;
        }
        filesClone.push(f);
      });
      setFiles(filesClone);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
    }
  }

  useEffect(async () => {
    const path = '/stronghold_files?orderBy=name&sortOrder=asc';
    getSocket();
    const savedFiles = await apiFetch(path);
    setFiles(savedFiles);
  }, []);

  return (
    <div className={classes.fileBox}>
      <div>
        <div
          style={{ width: '100%' }}
        >
          {rootFile.children.map((f) => (
            <FolderItem
              depth={1}
              key={f.id}
              record={f}
              nodeId={f.nodeId}
              warnAdd={true}
              handleDelete={(id) => {
                delFile(id);
              }}
              renameFunction={(fileId, name) => {
                renameFolder(fileId, name);
              }}
              basePath="stronghold_files"
              handleFileAdded={(newFile) => {
                const filesClone = files.slice(0);
                filesClone.push(newFile);
                setFiles(filesClone);
              }}
              saveFolder={(name, parent_id) => {
                saveFolder(name, parent_id);
              }}
              renameFolder={(fileId, name) => {
                renameFolder(fileId, name);
              }}
              deleteFolder={(fileId) => {
                delFile(fileId);
              }}
            />
          ))}
        </div>
      </div>
      <div>
        <AddFolderButton
          label="Add root folder"
          callback={(folderName) => {
            if (folderName) {
              saveFolder(folderName, null);
            }
          }}
        />
      </div>
    </div>
  );
};

const StrongholdFiles = () => {
  const classes = useStyles();

  return (
    <Layout headerTitle="Business Resources">
      <Paper sx={{ margin: '10px' }}>
        <div className={classes.gridContainer}>
          <DocList
            label="Business Resources"
          />
        </div>
      </Paper>
    </Layout>
  );
};

export default StrongholdFiles;
