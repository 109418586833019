import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useHistory } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';

import PartnerCompanyForm from '../components/partner_company-form';

import { apiFetch } from '../lib/fetch';
import { Button } from '../lib/styles';
import Layout from '../components/layout';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const PartnerCompany = () => {
  const [isNew, setIsNew] = useState(false);
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const { partnerCompanyId } = params;
  const [partner_company, setPartnerCompany] = useState({
    name: '',
    active: true,
    url: '',
  });

  function findPartnerCompany(id) {
    return apiFetch(`/partner_companies/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    setIsNew(location.pathname === '/partner_companies/new');
    if (partnerCompanyId) {
      const partner_company = await findPartnerCompany(partnerCompanyId);
      setPartnerCompany(partner_company);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setPartnerCompany({ ...partner_company, [name]: value });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setPartnerCompany({ ...partner_company, [name]: checked });
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="Company Form">
      <Button
        gold
        style={{ margin: '10px' }}
        onClick={() => {
          history.push('/config/Companies');
        }}
      >
        &lt; Back to Companies list
      </Button>
      {
        location.pathname === '/partner_companies/new' || partner_company ? (
          < PartnerCompanyForm
            partner_company={partner_company}
            isNew={isNew}
            handleTextChange={handleTextChange}
            handleToggleChange={handleToggleChange}
          />
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress size={100} />
          </div>
        )
      }
    </Layout>
  );
};

export default PartnerCompany;
