import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import useSWR from 'swr';

import { colors } from '../lib/styles';
import ConfigTypesLayout from '../components/config-types-layout';

const Sources = () => {
  const columns = [
    {
      field: 'name',
      headerName: 'Source',
      align: 'left',
    },
    {
      field: 'active',
      headerName: 'Active',
      align: 'right',
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
    },
  ];

  const { data: sources, isValidating } = useSWR('/sources');

  let rows = [];
  if (sources) {
    rows = sources.map((row) => {
      return {
        id: row.id,
        active: capitalize(String(row.active)),
        name: row.name,
        order_val: row.order_val,
        edit: <Link to={`/sources/${row.id}/edit`} style={{ color: colors.darkAccent }}>Edit</Link>,
      };
    });
  }

  return (
    <ConfigTypesLayout
      rows={rows}
      isValidating={isValidating}
      columns={columns}
      title="Source"
      path="sources"
    />
  );
};

export default Sources;
