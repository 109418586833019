import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import useSWR from 'swr';

import ConfigTypesLayout from '../components/config-types-layout';

import { colors } from '../lib/styles';

const RelationshipTypes = () => {
  const columns = [
    {
      field: 'name',
      headerName: 'Relationship Type',
      align: 'left',
      width: 250,
    },
    {
      field: 'active',
      headerName: 'Active',
      align: 'right',
      width: 100,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
      width: 100,
    },
  ];

  const { data, isValidating } = useSWR('/beneficiary_relationship_types');
  let rows = [];
  if (data) {
    rows = data.map((row) => {
      return {
        id: row.id,
        active: capitalize(String(row.active)),
        name: row.name,
        order_val: row.order_val,
        edit: (
          <Link
            to={`/relationship_types/${row.id}/edit`}
            style={{ color: colors.darkAccent }}
          >
            Edit
          </Link>
        ),
      };
    });
  }

  return (
    <ConfigTypesLayout
      rows={rows}
      isValidating={isValidating}
      columns={columns}
      title="Relationship Type"
      path="relationship_types"
    />
  );
};

export default RelationshipTypes;
