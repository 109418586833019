import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { DataGrid } from '@mui/x-data-grid';
import useSWR from 'swr';
import { colors } from '../lib/styles';
import { formatCurrency } from '../lib/util';
import DownloadButton from './download-csv';

const useStyles = makeStyles({
  tintedBackground: {
    backgroundColor: colors.blackOpacity,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'agent_percentage',
    headerName: 'Agent %',
    align: 'right',
    width: 140,
    renderCell: (params) => {
      return <span>{Number(params.row.agent_percentage).toFixed(2)}</span>;
    },
  },
  {
    field: 'total_agent_cut',
    headerName: 'Total Agent Payout',
    align: 'right',
    width: 140,
    renderCell: (params) => {
      return <span>{formatCurrency(params.row.total_agent_cut)}</span>;
    },
  },
  {
    field: 'total_all_payments',
    headerName: 'Totall All Payments',
    align: 'right',
    width: 140,
    renderCell: (params) => {
      return <span>{formatCurrency(params.row.total_all_payments)}</span>;
    },
  },
  {
    field: 'created',
    headerName: 'Date',
    align: 'right',
    width: 160,
    renderCell: (params) => {
      return <span>{params.row.created.substring(0, 10)}</span>;
    },
  },
];

function PaymentsTable({ data, display, timeType }) {
  const classes = useStyles();
  return (
    <div className={classes.mainBody} style={{ display }}>
      <DownloadButton rows={data} name={`payout_stats_${timeType}`}/>
      <Paper className={classes.tintedBackground}>
        <div className={classes.gridContainer}>
          <DataGrid columns={columns} rows={data || []} />
        </div>
      </Paper>
    </div>
  );
}

const PaymentStats = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const { data: stats } = useSWR('/admin/stats');
  let months = [];
  let quarters = [];
  let years = [];
  if (stats) {
    months = stats.total_override_payments_month.map((topm, id) => {
      const stat = {
        id,
        created: topm.created,
        total_all_payments: Number(topm.total_amount),
        total_standard_payments: 0,
        total_override_payments: Number(topm.total_amount),
        total_agent_cut: 0,
        agent_percentage: 100,
      };
      stats.total_payments_month.forEach((tpm) => {
        if (tpm.created === stat.created) {
          stat.total_all_payments += Number(tpm.total_amount);
          stat.total_standard_payments = Number(tpm.total_amount);
          stat.total_agent_cut = Number(tpm.total_amount);
        }
      });
      stats.total_override_payments_month_agent.forEach((tpm) => {
        if (tpm.created === stat.created) {
          stat.total_agent_cut = stat.total_all_payments - Number(tpm.total_amount);
          stat.stronghold_amount = Number(tpm.total_amount);
          stat.agent_percentage = (stat.total_agent_cut / stat.total_all_payments) * 100;
        }
      });
      return stat;
    });

    quarters = stats.total_override_payments_quarter.map((topq, id) => {
      const stat = {
        id,
        created: topq.created,
        total_all_payments: Number(topq.total_amount),
        total_standard_payments: 0,
        total_override_payments: Number(topq.total_amount),
        total_agent_cut: 0,
        agent_percentage: 100,
      };
      stats.total_payments_quarter.forEach((tpq) => {
        if (tpq.created === stat.created) {
          stat.total_all_payments += Number(tpq.total_amount);
          stat.total_standard_payments = Number(tpq.total_amount);
          stat.total_agent_cut = Number(tpq.total_amount);
        }
      });
      stats.total_override_payments_quarter_agent.forEach((tpq) => {
        if (tpq.created === stat.created) {
          stat.total_agent_cut = stat.total_all_payments - Number(tpq.total_amount);
          stat.stronghold_amount = Number(tpq.total_amount);
          stat.agent_percentage = (stat.total_agent_cut / stat.total_all_payments) * 100;
        }
      });
      return stat;
    });

    years = stats.total_override_payments_year.map((topy, id) => {
      const stat = {
        id,
        created: topy.created,
        total_all_payments: Number(topy.total_amount),
        total_standard_payments: 0,
        total_override_payments: Number(topy.total_amount),
        total_agent_cut: 0,
        agent_percentage: 100,
      };
      stats.total_payments_year.forEach((tpy) => {
        if (tpy.created === stat.created) {
          stat.total_all_payments += Number(tpy.total_amount);
          stat.total_standard_payments = Number(tpy.total_amount);
          stat.total_agent_cut = Number(tpy.total_amount);
        }
      });
      stats.total_override_payments_year_agent.forEach((tpy) => {
        if (tpy.created === stat.created) {
          stat.total_agent_cut = stat.total_all_payments - Number(tpy.total_amount);
          stat.stronghold_amount = Number(tpy.total_amount);
          stat.agent_percentage = (stat.total_agent_cut / stat.total_all_payments) * 100;
        }
      });
      return stat;
    });
  }

  return (
    <div>
      <Tabs
        value={tabIndex}
        onChange={(e, v) => {
          setTabIndex(v);
        }}
        centered
      >
        <Tab label="Year"/>
        <Tab label="Quarter"/>
        <Tab label="Month"/>
      </Tabs>
      <PaymentsTable
        data={years}
        display={tabIndex === 0 ? '' : 'none'}
        timeType="year"
      />
      <PaymentsTable
        data={quarters}
        display={tabIndex === 1 ? '' : 'none'}
        timeType="quarter"
      />
      <PaymentsTable
        data={months}
        display={tabIndex === 2 ? '' : 'none'}
        timeType="month"
      />
    </div>
  );
};

export default PaymentStats;
