import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Link, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';

import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import DownloadButton from '../components/download-csv';
import Layout from '../components/layout';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'left',
    width: 150,
  },
  {
    field: 'message',
    headerName: 'Message',
    align: 'left',
    width: 350,
  },
  {
    field: 'path',
    headerName: 'Path',
    align: 'left',
    width: 200,
  },
  {
    field: 'details',
    headerName: 'Agent',
    align: 'left',
    width: 200,
    renderCell: (params) => {
      return <Link style={{ color: colors.darkAccent }} to={`/users/${params.row.agent}/overview`}>Agent</Link>;
    },
  },
  {
    field: 'created_at',
    headerName: 'Submitted',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
];

const Clients = () => {
  const classes = useStyles();
  const params = useParams();
  const { userId } = params;
  const [user, setUser] = useState(null);
  const [err] = useState(null);

  let url = '/feedback?orderBy=created_at&limit=5000';
  if (userId) {
    url = `/feedback?orderBy=created_at&limit=5000&agent=${userId}`;
  }

  useEffect(async () => {
    if (userId) {
      const res = await apiFetch(`/users/${userId}`);
      setUser(res);
    }
  }, []);

  const { data: bsf, loading: bsfLoading } = useSWR(url);

  return (
    <Layout headerTitle={`Feedback${user ? ` for ${user.firstname} ${user.lastname} #${user.num}` : ''}`}>
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        {bsf && bsf.length ? (<DownloadButton rows={bsf} name="feedback"/>) : ''}
        <Paper>
          {!bsfLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={bsf || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default Clients;
