import React, { useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import MuiTabPanel from '@mui/lab/TabPanel';

import { Button, colors } from '../lib/styles';
import { GENERAL_CONFIG, CLIENTS_CONFIG } from '../lib/config';
import Layout from '../components/layout';

const useStyles = makeStyles({
  subheader: {
    color: colors.black,
    textDecoration: 'underline',
    fontSize: '16px',
    width: 180,
  },
});

const TabPanel = ({ value, children }) => (
  <MuiTabPanel value={value} sx={{ width: '95%', mx: 'auto' }}>
    {children}
  </MuiTabPanel>
);

const Config = () => {
  const classes = useStyles();
  const params = useParams();
  const { tabname } = params;
  const [value, setValue] = useState(tabname || GENERAL_CONFIG.map((tab) => tab.name).sort()[0]);
  const [generalOpen, setGeneralOpen] = useState(true);
  const [clientsOpen, setClientsOpen] = useState(true);

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout headerTitle="Config">
      <Box sx={{
        width: '100%',
        height: 'calc(100vh - 112px)',
        typography: 'body1',
        flexGrow: 1,
        color: colors.black,
        display: 'flex',
      }}
      >
        <TabContext value={value} style={{ height: '100%' }} >
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList
              orientation="vertical"
              variant="scrollable"
              onChange={handleChange}
              aria-label="Config tab options"
              sx={{
                height: '100%',
              }}
            >
              <Button
                type="button"
                className={classes.subheader}
                endIcon={generalOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={() => setGeneralOpen(!generalOpen)}
                style={{
                  backgroundColor: 'transparent',
                  '& .MuiButton-root:hover': { backgroundColor: 'transparent' },
                  color: colors.highlightDarker,
                  fontSize: '16px',
                }}
                disableElevation
              >
                <u>General</u>
              </Button>
              {
              generalOpen && GENERAL_CONFIG.map((tab) => tab.name).sort().map((tab) => (
                <Tab
                  key={tab}
                  label={tab}
                  value={tab}
                  sx={{
                    alignItems: 'flex-start',
                    textAlign: 'start',
                    color: colors.black,
                    '&.Mui-selected': {
                      color: colors.primaryHighlight,
                    },
                  }}
                  wrapped
                />
              ))
            }
              <Button
                type="button"
                className={classes.subheader}
                endIcon={clientsOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                onClick={() => setClientsOpen(!clientsOpen)}
                style={{
                  backgroundColor: 'transparent',
                  '& .MuiButton-root:hover': { backgroundColor: 'transparent' },
                  color: colors.highlightDarker,
                  fontSize: '16px',
                }}
                disableElevation
              >
                <u>Clients</u>
              </Button>
              {
              clientsOpen && CLIENTS_CONFIG.map((tab) => tab.name).sort().map((tab) => (
                <Tab
                  key={tab}
                  label={tab}
                  value={tab}
                  sx={{
                    alignItems: 'flex-start',
                    textAlign: 'start',
                    color: colors.black,
                    '&.Mui-selected': {
                      color: colors.primaryHighlight,
                    },
                  }}
                  wrapped
                />
              ))
            }
            </TabList>
          </Box>
          {
            [...GENERAL_CONFIG, ...CLIENTS_CONFIG].map((tab) => (
              <TabPanel value={tab.name}>
                {tab.component}
              </TabPanel>
            ))
          }
        </TabContext>
      </Box>
    </Layout>
  );
};

export default Config;
