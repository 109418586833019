import React, { useState } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

import DownloadButton from '../components/download-csv';

import { colors } from '../lib/styles';

const useStyles = makeStyles({
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px auto',
    minWidth: '300px',
    width: '80%',
  },
  tintedBackground: {
    backgroundColor: colors.blackOpacity,
  },
  orgs: {
    margin: '10px auto',
    minWidth: '350px',
    width: '100%',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  w200: {
    width: '200px',
    margin: '10px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'left',
    width: 150,
  },
  {
    field: 'count',
    headerName: 'Count',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      const link = `/recruits/${params.row.userId}/quarter/${params.row.created.substring(0, 10)}`;
      return (
        <Link style={{ color: colors.primaryText }} to={link}>
          {params.row.count}
        </Link>
      );
    },
  },
  {
    field: 'network',
    headerName: 'Network',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return (
        <Link style={{ color: colors.primaryText }} to={`/network/${params.row.userId}`}>
          Network
        </Link>
      );
    },
  },
  {
    field: 'created',
    headerName: 'Quarter',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created ? params.row.created.substring(0, 10) : ''}</span>;
    },
  },
];

const Recruits = () => {
  const classes = useStyles();
  const [err] = useState(null);

  const url = '/recruits/quarter?orderBy=created&limit=5000';

  const { data: rows, loading } = useSWR(url);
  if (rows) {
    rows.forEach((r, id) => {
      r.userId = r.id;
      r.id = id;
    });
  }

  return (
    <div style={classes.container}>
      <div style={classes.mainBody}>
        <Typography variant="h4">Recruits by Quarter</Typography>
        <div>{err?.toString()}</div>
        {rows && rows.length ? (<DownloadButton rows={rows} name="recruits-quarterly"/>) : ''}
        <Paper className={classes.tintedBackground}>
          {!loading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={rows || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </div>
  );
};

export default Recruits;
