import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import useSWR from 'swr';

import { colors } from '../lib/styles';
import ConfigTypesLayout from '../components/config-types-layout';

const ClientInstitutionTypes = () => {
  const columns = [
    { field: 'id', hide: true },
    {
      field: 'name',
      headerName: 'Client Institution Type',
      align: 'left',
      width: 250,
    },
    {
      field: 'active',
      headerName: 'Active',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        return <span>{capitalize(params.value)}</span>;
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        return (
          <Link to={`/client_institutions/${params.row.id}/edit`} style={{ color: colors.darkAccent }}>Edit</Link>
        );
      },
    },
  ];

  const { data: eca, isValidating } = useSWR('/client_institutions');

  return (
    <ConfigTypesLayout
      data={eca}
      isValidating={isValidating}
      columns={columns}
      title="Client Institution Type"
      path="client_institutions"
    />
  );
};

export default ClientInstitutionTypes;
