import React, { useState, useEffect } from 'react';
import useSWR from 'swr';
import { Link, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';

import { apiFetch } from '../lib/fetch';
import { colors } from '../lib/styles';
import DownloadButton from '../components/download-csv';
import ClientSearch from '../components/client-search';
import config from '../config';
import Layout from '../components/layout';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  {
    field: 'household_name',
    headerName: 'Household',
    align: 'left',
    width: 150,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'age',
    headerName: 'Age',
    align: 'left',
    width: 60,
    renderCell: (params) => {
      return params.row.dob ? moment().diff(params.row.dob, 'years', false) : '';
    },
  },
  {
    field: 'dob',
    headerName: 'DOB',
    align: 'left',
    width: 120,
    renderCell: (params) => {
      return params.row.dob ? params.row.dob.substring(0, 10) : '';
    },
  },
  {
    field: 'ssn',
    headerName: 'SSN',
    align: 'left',
    width: 100,
  },
  // {
  //   field: 'id',
  //   headerName: 'ID',
  //   align: 'left',
  //   width: 300,
  // },
  {
    field: 'num',
    headerName: 'Number',
    align: 'right',
    width: 100,
  },
  {
    field: 'details',
    headerName: 'Detail',
    align: 'left',
    width: 90,
    renderCell: (params) => {
      return <Link style={{ color: colors.darkAccent }} to={`/clients/${params.row.id}/overview`}>Details</Link>;
    },
  },
  {
    field: 'agent_firstname',
    headerName: 'Agent F. name',
    align: 'left',
    width: 150,
  },
  {
    field: 'agent_lastname',
    headerName: 'Agent L. name',
    align: 'left',
    width: 150,
  },
  {
    field: 'switch',
    headerName: 'Switch to agent',
    align: 'left',
    renderCell: (params) => {
      const client = params.row;
      let url = `${config.API_URL}/users/p/${client.agent}`;
      url += `?path=/household/${client.household_id}/client/${client.id}`;
      return (
        <a
          href={url}
          style={{ color: colors.darkAccent }}
        >
          Switch
        </a>
      );
    },
  },
];

const Clients = () => {
  const classes = useStyles();
  const params = useParams();
  const { userId } = params;
  const [user, setUser] = useState(null);
  const [err] = useState(null);
  const [is_deleted, setIsDeleted] = useState('all');

  let url = '/clients?orderBy=created_at&limit=5000';
  if (userId) {
    url = `/users/${userId}/clients?orderBy=created_at&limit=5000`;
  }
  if (is_deleted !== 'all') {
    url += `&is_deleted=${is_deleted}`;
  }

  useEffect(async () => {
    if (userId) {
      const res = await apiFetch(`/users/${userId}`);
      setUser(res);
    }
  }, []);

  const { data: bsf, loading: bsfLoading } = useSWR(url);

  return (
    <Layout headerTitle={`Clients ${user ? `for ${user.firstname} ${user.lastname} #${user.num}` : ''}`}>
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} mb={1} sx={{ width: '75%' }}>
          { user
            ? ''
            : (
              <Box my={2} gridColumn="span 12">
                <ClientSearch
                  defaultValue=""
                  onChange={(c) => {
                    if (c && c.id) {
                      document.location.href = `/clients/${c.id}/overview`;
                    }
                  }}
                />
              </Box>
            )}
          {bsf && bsf.length ? (
            <Box gridColumn="span 3" sx={{ display: 'flex' }}>
              <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
                <DownloadButton rows={bsf} name="clients" style={{ color: colors.darkAccent }}/>
              </Box>
            </Box>
          ) : ''}
        </Box>
        <Box gridColumn="span 3">
          <Select
            label="Status"
            select="all"
            value={is_deleted}
            name="is_deleted"
            onChange={(evt) => {
              setIsDeleted(evt.target.value);
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="false">Active</MenuItem>
            <MenuItem value="true">Deleted</MenuItem>
          </Select>
        </Box>
        <Paper>
          {!bsfLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={bsf || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default Clients;
