import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';

import PaymentForm from '../components/override-payment-form';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const Payment = () => {
  const params = useParams();
  const location = useLocation();

  const { paymentId } = params;
  const [payment, setPayment] = useState({
    amount: '',
    reference: '',
    status: '',
  });

  function findPayment(id) {
    return apiFetch(`/override_payments/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    if (paymentId) {
      const payment = await findPayment(paymentId);
      setPayment(payment);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setPayment({ ...payment, [name]: value });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setPayment({ ...payment, [name]: checked });
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="Override Payment">
      <div className={classes.container}>
        {
          location.pathname === '/payments/new' || payment ? (
            < PaymentForm
              payment={payment}
              handleTextChange={handleTextChange}
              handleToggleChange={handleToggleChange}
            />
          ) : (
            <div className={classes.loadingCircle}>
              <CircularProgress size={100} />
            </div>
          )
        }
      </div>
    </Layout>
  );
};

export default Payment;
