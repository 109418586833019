import React from 'react';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { apiFetch } from '../lib/fetch';
import { Button } from '../lib/styles';

const PaymentForm = (props) => {
  const {
    payment,
    handleTextChange,
  } = props;

  const {
    firstname, lastname, amount, reference, status, agent,
  } = payment;

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      status,
      reference,
    } = payment;

    const data = {
      status,
      reference,
      agent,
    };

    apiFetch(`/override_payments/${payment.id}`, {
      method: 'PUT',
      body: data,
    });

    history.goBack();
  };

  const statusOptions = ['OPEN', 'PAID'];

  return (
    <Box
      component="form"
      sx={{
        display: 'grid',
        width: '80%',
        margin: 'auto',
        paddingTop: '30px',
        '& .MuiTextField-root': { m: 2 },
      }}
      onSubmit={handleSubmit}
    >
      <FormControl
        sx={{ flexDirection: 'row', display: 'flex' }}
        component="fieldset"
        variant="standard"
      >
        <FormControl>
          <TextField
            select
            label="Status"
            name="status"
            value={status}
            onChange={handleTextChange}
          >
            {statusOptions.map((option) => {
              return (<MenuItem value={option} key={option}>{option}</MenuItem>);
            })}
          </TextField>
        </FormControl>
        <TextField
          id="reference"
          name="reference"
          label="Reference"
          value={reference || ''}
          onChange={handleTextChange}
        />
        <TextField
          disabled
          id="amount"
          name="amount"
          label="Amount"
          value={`$ ${amount}`}
          style={{ marginLeft: 'auto' }}
        />
      </FormControl>
      <FormControl
        sx={{ flexDirection: 'row' }}
        component="fieldset"
        variant="standard"
      >
        <TextField
          disabled
          label="First Name"
          value={firstname || ''}
          sx={{ flexGrow: 1 }}
        />
        <TextField
          disabled
          label="Last Name"
          value={lastname || ''}
          sx={{ flexGrow: 1 }}
        />
      </FormControl>
      <FormControl
        component="fieldset"
        variant="standard"
      >
        <Box sx={{ m: 2, display: 'flex', justifyContent: 'flex-end' }}>
          <Button gold type="submit" title="Save" />
        </Box>
      </FormControl>
    </Box>
  );
};

export default PaymentForm;
