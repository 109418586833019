import React from 'react';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';
import moment from 'moment';
import { colors } from '../lib/styles';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
});

const AgentNotifications = (props) => {
  const classes = useStyles();
  const { data } = props;

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'type',
      headerName: 'type',
      align: 'left',
      width: 250,
    },
    {
      field: 'created_at',
      headerName: 'date',
      align: 'right',
      width: 150,
      renderCell: (params) => {
        const { created_at } = params.row;
        const dateStr = moment(created_at).format('MM/DD/YYYY');
        return dateStr;
      },
    },
  ];

  return (
    <div style={classes.mainBody}>
      <Paper className={classes.tintedBackground}>
        <div className={classes.gridContainer}>
          <DataGrid columns={columns} rows={data || []} />
        </div>
      </Paper>
    </div>
  );
};

export default AgentNotifications;
