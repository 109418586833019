import React, { useState } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

import DownloadButton from '../components/download-csv';

import { colors } from '../lib/styles';

const useStyles = makeStyles({
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px auto',
    minWidth: '300px',
    width: '80%',
  },
  tintedBackground: {
    backgroundColor: colors.blackOpacity,
  },
  orgs: {
    margin: '10px auto',
    minWidth: '350px',
    width: '100%',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  w200: {
    width: '200px',
    margin: '10px',
  },
});

const Recruits = () => {
  const classes = useStyles();
  const [err] = useState(null);
  const { userId, date, truncType } = useParams();
  let url = '/recruits/detail?orderBy=created_at&limit=5000&is_deleted=false';
  url += `&parent_id=${userId}&trunc_type=${truncType}&date_str=${date}`;
  const { data: rows, loading } = useSWR(url);
  const { data: user } = useSWR(`/users/${userId}`);

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'firstname',
      headerName: 'First Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'num',
      headerName: 'Agent #',
      align: 'left',
      width: 150,
    },
    {
      field: 'approved',
      headerName: 'Approved',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (params.row.approved) {
          return 'Y';
        }
        return 'N';
      },
    },
    {
      field: 'created',
      headerName: truncType,
      align: 'right',
      width: 150,
      renderCell: (params) => {
        return <span>{params.row[truncType] ? params.row[truncType].substring(0, 10) : ''}</span>;
      },
    },
    {
      field: 'created_at',
      headerName: 'Created',
      align: 'right',
      width: 150,
      renderCell: (params) => {
        return <span>{params.row.created_at ? params.row.created_at.substring(0, 16) : ''}</span>;
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        return <Link style={{ color: colors.primaryText }} to={`/users/${params.row.id}/edit`}>Edit</Link>;
      },
    },
  ];

  return (
    <div style={classes.container}>
      <div style={classes.mainBody}>
        <Typography variant="h4">Recruits by {truncType}</Typography>
        <Typography variant="h4">For {user ? `${user.firstname} ${user.lastname} #${user.num}` : ''}</Typography>
        <div>{err?.toString()}</div>
        {rows && rows.length ? (<DownloadButton rows={rows} name={`recruit-details-${truncType}ly`}/>) : ''}
        <Paper className={classes.tintedBackground}>
          {!loading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={rows || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </div>
  );
};

export default Recruits;
