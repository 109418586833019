import React, { useState } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import makeStyles from '@mui/styles/makeStyles';

import { Button } from '../lib/styles';
import DraggableTable from './draggable-table';

const useStyles = makeStyles({
  container: {
    height: '100%',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: '100%',
  },
});

const ConfigTypesLayout = ({
  rows, isValidating, columns, title, path, toggles,
}) => {
  const classes = useStyles();
  const [err] = useState(null);

  return (
    <div className={classes.container}>
      <Typography variant="h4">
        {title}s
        <Button gold addIcon sx={{ margin: '15px' }} href={`/${path}/new`} title={`Add ${title}`}/>
      </Typography>
      <div>{err?.toString()}</div>
      <Paper>
        {!isValidating ? (
          <div className={classes.gridContainer}>
            <DraggableTable rows={rows} columns={columns} path={path} toggles={toggles} />
          </div>
        ) : <CircularProgress/>}
      </Paper>
    </div>
  );
};

export default ConfigTypesLayout;
