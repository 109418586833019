import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import Typography from '@mui/material/Typography';
import {
  Tab,
  Tabs,
} from '@mui/material';
import { colors } from '../lib/styles';
import MissedMoneyYear from './missed_money_year';
import MissedMoneyYearPillar from './missed_money_year_pillar';
import MissedMoneyMonth from './missed_money_month';
import MissedMoneyMonthPillar from './missed_money_month_pillar';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
});

const MissedMoney = (props) => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);
  const { data } = props;
  const {
    mmYear,
    mmYearPillar,
    mmMonth,
    mmMonthPillar,
  } = data;

  return (
    <div style={classes.mainBody}>
      <Paper className={classes.tintedBackground}>
        <Tabs
          value={tabIndex}
          onChange={(e, v) => {
            setTabIndex(v);
          }}
          centered
        >
          <Tab label="Year" className={classes.tab} />
          <Tab label="Year & Pillar" className={classes.tab} />
          <Tab label="Month" className={classes.tab} />
          <Tab label="Month & Pillar" className={classes.tab} />
        </Tabs>
      </Paper>
      <div
        className={classes.section}
        style={{ display: tabIndex === 0 ? '' : 'none' }}
      >
        <Typography variant="h5">Missed Money by Year</Typography>
        <MissedMoneyYear data={mmYear} />
      </div>
      <div
        className={classes.section}
        style={{ display: tabIndex === 1 ? '' : 'none' }}
      >
        <Typography variant="h5">Missed Money by Year and Pillar</Typography>
        <MissedMoneyYearPillar data={mmYearPillar} />
      </div>
      <div
        className={classes.section}
        style={{ display: tabIndex === 2 ? '' : 'none' }}
      >
        <Typography variant="h5">Missed Money by Month</Typography>
        <MissedMoneyMonth data={mmMonth} />
      </div>
      <div
        className={classes.section}
        style={{ display: tabIndex === 3 ? '' : 'none' }}
      >
        <Typography variant="h5">Missed Money by Month and Pillar</Typography>
        <MissedMoneyMonthPillar data={mmMonthPillar} />
      </div>
    </div>
  );
};

export default MissedMoney;
