import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';

import ClientInsuranceTypeForm from '../components/client_insurance_type-form';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const ClientInsuranceType = () => {
  const [isNew, setIsNew] = useState(false);
  const params = useParams();
  const location = useLocation();

  const { client_insurance_typeId } = params;
  const [client_insurance_type, setClientInsuranceType] = useState({
    name: '',
    active: true,
  });

  function findClientInsuranceType(id) {
    return apiFetch(`/client_insurance_types/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    setIsNew(location.pathname === '/client_insurance_types/new');
    if (client_insurance_typeId) {
      const client_insurance_type = await findClientInsuranceType(client_insurance_typeId);
      setClientInsuranceType(client_insurance_type);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setClientInsuranceType({ ...client_insurance_type, [name]: value });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setClientInsuranceType({ ...client_insurance_type, [name]: checked });
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="Client Life Policy Type Form">
      {
        location.pathname === '/client_insurance_types/new' || client_insurance_type ? (
          < ClientInsuranceTypeForm
            client_insurance_type={client_insurance_type}
            isNew={isNew}
            handleTextChange={handleTextChange}
            handleToggleChange={handleToggleChange}
          />
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress size={100} />
          </div>
        )
      }
    </Layout>
  );
};

export default ClientInsuranceType;
