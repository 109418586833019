import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { pick } from 'lodash';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from 'moment';

import UserSearch from './user-search';

import { apiFetch } from '../lib/fetch';

import { colors, Button } from '../lib/styles';

import { passwordReset } from '../state/user';
import states from '../lib/states';

const styles = {
  radioLabel: {
    color: colors.darkAccent,
  },
  radioBox: {
    border: '1px solid white',
    borderRadius: '5px',
    width: '100%',
    padding: '8px',
    margin: '5px',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px',
  },
};

const UserForm = (props) => {
  const {
    user,
    parent,
    handleSponsor,
    handleChange,
    handleChangeStartDate,
    handleChangeTerminationDate,
    handleChangeDOB,
    hasTag,
    changeTag,
    isNew,
  } = props;

  user.routing_num = user.routing_num || '';
  user.account_num = user.account_num || '';
  const {
    firstname,
    lastname,
    email,
    is_deleted,
    start_date,
    account_num,
    routing_num,
    approved,
    bio,
    bio_url,
    address,
    city,
    state,
    phone,
    postalcode,
    created_at,
    ein,
    ssn,
    dob,
    w9_signed,
    ca_signed,
    ep,
    pc,
    scope,
    orig_email,
    termination_date,
  } = user;
  let formattedCreated = null;
  if (created_at) {
    formattedCreated = moment(created_at).format('MMM Do YYYY, h:mm:ss a');
  }
  const history = useHistory();
  const [error, setError] = useState(null);
  const [isAdmin, setIsAdmin] = useState(scope && scope.includes('ADMIN'));
  const [isServiceRep, setIsServiceRep] = useState(scope && scope.includes('SERVICE_REP'));
  const [allTags, setAllTags] = useState([]);

  useEffect(async () => {
    const res = await apiFetch('/tags?orderBy=order_val&active=true');
    setAllTags(res);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isNew) {
      const data = {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
        parent_id: user.parent_id,
        account_num: user.account_num,
        routing_num: user.routing_num,
        start_date: user.start_date,
        bio: user.bio,
        bio_url: user.bio_url,
        address: user.address,
        city: user.city,
        state: user.state,
        phone: user.phone,
        postalcode: user.postalcode,
        ein: user.ein,
        ssn: user.ssn,
        dob: user.dob,
        w9_signed: user.w9_signed,
        ca_signed: user.ca_signed,
        ep: user.ep,
        pc: user.pc,
      };

      try {
        const { id } = await apiFetch('/users', {
          method: 'POST',
          body: data,
        });

        if (isAdmin) {
          const body = {
            user_id: id,
            group_id: 'ADMIN',
          };

          apiFetch('/memberships', {
            method: 'POST',
            body,
          });
        } else if (isServiceRep) {
          const body = {
            user_id: id,
            group_id: 'SERVICE_REP',
          };

          apiFetch('/memberships', {
            method: 'POST',
            body,
          });
        }
      } catch (e) {
        setError(e.message);
        return;
      }
      passwordReset(user.email);
    } else {
      const body = pick(user, [
        'firstname',
        'lastname',
        'routing_num',
        'account_num',
        'email',
        'approved',
        'is_deleted',
        'parent_id',
        'start_date',
        'bio',
        'bio_url',
        'address',
        'city',
        'state',
        'phone',
        'postalcode',
        'ein',
        'ssn',
        'dob',
        'ca_signed',
        'w9_signed',
        'ep',
        'pc',
        'termination_date',
        'tags',
      ]);

      const existing = await apiFetch(`/users/${user.id}`);

      if (!existing.is_deleted && user.is_deleted) {
        const subs = await apiFetch(`/users/${user.id}/billing/subscriptions`);
        let activeSub = false;
        if (subs.length) {
          subs.forEach((sub) => {
            if (sub.status === 'active') {
              activeSub = true;
            }
          });
        }
        if (activeSub) {
          // eslint-disable-next-line no-alert
          if (window.confirm('Do you want to cancel subscriptions for this user?')) {
            for (const sub of subs) {
              await apiFetch.del(`/users/${user.id}/billing/subscriptions/${sub.id}`);
            }
            body.pc = false;
          }
        }
      }

      await apiFetch(`/users/${user.id}`, {
        method: 'PUT',
        body,
      });

      if (scope.includes('ADMIN') && !isAdmin) {
        const [membership] = await apiFetch(`/memberships?user_id=${user.id}&group_id=ADMIN`);
        apiFetch(`/memberships/${membership.id}`, { method: 'DELETE' });
      } else if (!scope.includes('ADMIN') && isAdmin) {
        apiFetch('/memberships', {
          method: 'POST',
          body: {
            user_id: user.id,
            group_id: 'ADMIN',
          },
        });
      }

      if (scope.includes('SERVICE_REP') && !isServiceRep) {
        const [membership] = await apiFetch(`/memberships?user_id=${user.id}&group_id=SERVICE_REP`);
        apiFetch(`/memberships/${membership.id}`, { method: 'DELETE' });
      } else if (!scope.includes('SERVICE_REP') && isServiceRep) {
        apiFetch('/memberships', {
          method: 'POST',
          body: {
            user_id: user.id,
            group_id: 'SERVICE_REP',
          },
        });
      }
    }

    history.goBack();
  };

  return (
    <Box
      component="form"
      sx={{
        marginTop: 2,
        display: 'grid',
        width: '100%',
        '& fieldset': {
          '& > div': {
            m: 2,
          },
        },
      }}
      onSubmit={handleSubmit}
    >
      {error ? <Typography style={{ color: colors.errorRed, margin: 10 }} variant="body2">{error}</Typography> : ''}
      <FormControl
        sx={{ flexDirection: 'row' }}
        component="fieldset"
        variant="standard"
      >
        <TextField
          id="firstname"
          name="firstname"
          label="First Name"
          value={firstname}
          onChange={handleChange}
          sx={{ flexGrow: 1 }}
        />
        <TextField
          id="lastname"
          name="lastname"
          label="Last Name"
          value={lastname}
          onChange={handleChange}
          sx={{ flexGrow: 1 }}
        />
      </FormControl>
      <FormControl
        component="fieldset"
        variant="standard"
      >
        <TextField
          required
          id="email"
          name="email"
          label="Email"
          value={email}
          onChange={handleChange}
        />
        {orig_email ? (
          <span style={{ color: 'white', margin: '15px' }}>
            Original Email: {orig_email}
          </span>
        ) : ''}
      </FormControl>
      <FormControl
        component="fieldset"
        variant="standard"
      >
        <TextField
          id="routing_num"
          name="routing_num"
          label="Routing Number"
          value={routing_num}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl
        component="fieldset"
        variant="standard"
      >
        <TextField
          id="account_num"
          name="account_num"
          label="Account Number"
          value={account_num}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl
        component="fieldset"
        variant="standard"
      >
        <UserSearch
          required
          ignore={[user?.auth?.id]}
          value={parent ? `${parent.firstname} ${parent.lastname} ${parent.email}` : ''}
          onChange={handleSponsor}
          sx={{ m: 3 }}
        />
      </FormControl>
      <FormControl
        component="fieldset"
        variant="standard"
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          {formattedCreated ? (
            <span style={{ margin: '15px' }}>
              Record Created: {formattedCreated}
            </span>
          ) : ''}
          <DatePicker
            openTo="year"
            views={['year', 'month', 'day']}
            label="Start Date"
            defaultValue=""
            value={start_date}
            name="start_date"
            onChange={handleChangeStartDate}
            sx={{ flexGrow: 1 }}
            maxDate={new Date()}
            renderInput={(params) => <TextField required style={{ width: '200px' }} {...params} />}
          />
          <DatePicker
            openTo="year"
            views={['year', 'month', 'day']}
            label="Termination Date"
            defaultValue=""
            value={termination_date}
            name="termination_date"
            onChange={handleChangeTerminationDate}
            sx={{ flexGrow: 1 }}
            maxDate={new Date()}
            renderInput={(params) => <TextField style={{ width: '200px' }} {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl
        component="fieldset"
        variant="standard"
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            openTo="year"
            views={['year', 'month', 'day']}
            label="DOB"
            defaultValue=""
            value={dob}
            name="dob"
            onChange={handleChangeDOB}
            sx={{ flexGrow: 1 }}
            maxDate={new Date()}
            renderInput={(params) => <TextField style={{ width: '200px' }} {...params} />}
          />
        </LocalizationProvider>
      </FormControl>
      <FormControl
        component="fieldset"
        variant="standard"
      >
        <TextField
          id="address"
          name="address"
          label="Address"
          value={address}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl
        sx={{ flexDirection: 'row' }}
        component="fieldset"
        variant="standard"
      >
        <TextField
          id="city"
          name="city"
          label="City"
          value={city}
          onChange={handleChange}
          sx={{ flexGrow: 1 }}
        />
        <TextField
          labelId="state"
          label="State"
          value={state}
          name="state"
          onChange={handleChange}
          select
          sx={{ flexGrow: 1 }}
        >
          {states.map((state) => <MenuItem value={state[0]} key={state[0]}>{state[1]}</MenuItem>)}
        </TextField>
        <TextField
          id="postalcode"
          name="postalcode"
          label="Zip"
          value={postalcode}
          onChange={handleChange}
          sx={{ flexGrow: 1 }}
        />
      </FormControl>
      <FormControl
        sx={{ flexDirection: 'row' }}
        component="fieldset"
        variant="standard"
      >
        <TextField
          id="ssn"
          name="ssn"
          label="SSN"
          value={ssn}
          onChange={handleChange}
          sx={{ flexGrow: 1 }}
        />
        <TextField
          id="ein"
          name="ein"
          label="EIN"
          value={ein}
          onChange={handleChange}
          sx={{ flexGrow: 1 }}
        />
      </FormControl>
      <FormControl
        sx={{ flexDirection: 'row' }}
        component="fieldset"
        variant="standard"
      >
        <TextField
          id="phone"
          name="phone"
          label="Phone"
          value={phone}
          onChange={handleChange}
          sx={{ flexGrow: 1 }}
        />
        <TextField
          id="bio"
          name="bio"
          label="Bio"
          multiline
          rows="3"
          value={bio}
          onChange={handleChange}
          sx={{ flexGrow: 1 }}
        />
      </FormControl>

      <FormControl
        sx={{ flexDirection: 'row' }}
        component="fieldset"
        variant="standard"
      >
        <TextField
          id="bio_url"
          name="bio_url"
          label="Bio URL"
          value={bio_url}
          onChange={handleChange}
          sx={{ flexGrow: 1 }}
        />
      </FormControl>

      <div style={styles.radioGroup}>
        <div style={styles.radioBox}>
          <Typography style={{ color: colors.darkAccent }} variant="body1">Active</Typography>
          <RadioGroup
            aria-label="is_deleted"
            name="is_deleted"
            value={!is_deleted}
            onChange={handleChange}
            disabled={isNew}
            row
          >
            <FormControlLabel value={true} control={<Radio />} label={<span style={styles.radioLabel}>Yes</span>} />
            <FormControlLabel value={false} control={<Radio />} label={<span style={styles.radioLabel}>No</span>} />
          </RadioGroup>
        </div>
        <div style={styles.radioBox}>
          <Typography style={{ color: colors.darkAccent }} variant="body1">CA Signed</Typography>
          <RadioGroup
            aria-label="ca_signed"
            name="ca_signed"
            value={ca_signed}
            onChange={handleChange}
            row
          >
            <FormControlLabel value={true} control={<Radio />} label={<span style={styles.radioLabel}>Yes</span>} />
            <FormControlLabel value={false} control={<Radio />} label={<span style={styles.radioLabel}>No</span>} />
          </RadioGroup>
        </div>
      </div>
      <div style={styles.radioGroup}>
        <div style={styles.radioBox}>
          <Typography style={{ color: colors.darkAccent }} variant="body1">W9 complete</Typography>
          <RadioGroup
            aria-label="w9_signed"
            name="w9_signed"
            value={w9_signed}
            onChange={handleChange}
            row
          >
            <FormControlLabel value={true} control={<Radio />} label={<span style={styles.radioLabel}>Yes</span>} />
            <FormControlLabel value={false} control={<Radio />} label={<span style={styles.radioLabel}>No</span>} />
          </RadioGroup>
        </div>
        <div style={styles.radioBox}>
          <Typography style={{ color: colors.darkAccent }} variant="body1">Enrollment Paid</Typography>
          <RadioGroup
            aria-label="ep"
            name="ep"
            value={ep}
            onChange={handleChange}
            row
          >
            <FormControlLabel value={true} control={<Radio />} label={<span style={styles.radioLabel}>Yes</span>} />
            <FormControlLabel value={false} control={<Radio />} label={<span style={styles.radioLabel}>No</span>} />
          </RadioGroup>
        </div>
      </div>
      <div style={styles.radioGroup}>
        <div style={styles.radioBox}>
          <Typography style={{ color: colors.darkAccent }} variant="body1">Subscription Current</Typography>
          <RadioGroup
            aria-label="pc"
            name="pc"
            value={pc}
            onChange={handleChange}
            row
          >
            <FormControlLabel value={true} control={<Radio />} label={<span style={styles.radioLabel}>Yes</span>} />
            <FormControlLabel value={false} control={<Radio />} label={<span style={styles.radioLabel}>No</span>} />
          </RadioGroup>
        </div>
        <div style={styles.radioBox}>
          <Typography style={{ color: colors.darkAccent }} variant="body1">Approved</Typography>
          <RadioGroup
            aria-label="approved"
            name="approved"
            value={approved}
            onChange={handleChange}
            row
          >
            <FormControlLabel value={true} control={<Radio />} label={<span style={styles.radioLabel}>Yes</span>} />
            <FormControlLabel value={false} control={<Radio />} label={<span style={styles.radioLabel}>No</span>} />
          </RadioGroup>
        </div>
      </div>
      <div style={styles.radioGroup}>
        <div style={styles.radioBox}>
          <Typography style={{ color: colors.darkAccent }} variant="body1">Admin</Typography>
          <RadioGroup
            aria-label="admin"
            name="isAdmin"
            value={isAdmin}
            onChange={() => setIsAdmin(!isAdmin)}
            row
          >
            <FormControlLabel value={true} control={<Radio />} label={<span style={styles.radioLabel}>Yes</span>} />
            <FormControlLabel value={false} control={<Radio />} label={<span style={styles.radioLabel}>No</span>} />
          </RadioGroup>
        </div>
        <div style={styles.radioBox}>
          <Typography style={{ color: colors.darkAccent }} variant="body1">Service Rep</Typography>
          <RadioGroup
            aria-label="serviceRep"
            name="isServiceRep"
            value={isServiceRep}
            onChange={() => setIsServiceRep(!isServiceRep)}
            row
          >
            <FormControlLabel value={true} control={<Radio />} label={<span style={styles.radioLabel}>Yes</span>} />
            <FormControlLabel value={false} control={<Radio />} label={<span style={styles.radioLabel}>No</span>} />
          </RadioGroup>
        </div>
      </div>
      <Divider variant="middle" />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column' }}>
        <Typography variant="h5" sx={{ color: colors.darkAccent, margin: '10px' }}>Tags</Typography>
        {allTags.map((tag) => (
          <div>
            <FormControlLabel
              sx={{ color: colors.darkAccent }}
              control={(
                <Switch
                  defaultChecked
                  checked={hasTag(tag.id)}
                  name="active"
                  onChange={() => {
                    changeTag(tag.id, !hasTag(tag.id));
                  }}
                />
            )}
              label={tag.name}
              labelPlacement="start"
            />
          </div>
        ))}
      </Box>
      <Divider variant="middle" />
      <Button
        gold
        sx={{
          m: 2,
          width: 'fit-content',
          justifySelf: 'flex-end',
        }}
        type="submit"
        title="Save"
        disabled={!email || !firstname || !lastname}
      />
    </Box>
  );
};

export default UserForm;
