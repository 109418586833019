import React, { useState } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';

import StyledSelect from '../components/styled-select';
import DownloadButton from '../components/download-csv';

import { colors } from '../lib/styles';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px auto',
    minWidth: '300px',
    width: '80%',
  },
  orgs: {
    margin: '10px auto',
    minWidth: '350px',
    width: '100%',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  w200: {
    width: '200px',
    margin: '10px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'left',
    width: 150,
  },
  {
    field: 'count',
    headerName: 'Count',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return (
        <Link style={{ color: colors.darkAccent }} to={`/users/${params.row.agent}/bsfs`}>
          {params.row.count}
        </Link>
      );
    },
  },
  {
    field: 'first_distribution',
    headerName: 'First Dist.',
    align: 'right',
    width: 140,
    renderCell: (params) => {
      return <span>{Number(params.row.first_distribution).toFixed(2)}</span>;
    },
  },
  {
    field: 'life_target',
    headerName: 'Target (life)',
    align: 'right',
    width: 140,
    renderCell: (params) => {
      return <span>{Number(params.row.life_target).toFixed(2)}</span>;
    },
  },
  {
    field: 'life_annual',
    headerName: 'Prem. (annuity)',
    align: 'right',
    width: 140,
    renderCell: (params) => {
      return <span>{Number(params.row.life_annual).toFixed(2)}</span>;
    },
  },
  {
    field: 'cbasis',
    headerName: 'Contract Basis',
    align: 'right',
    width: 160,
    renderCell: (params) => {
      return <span>{Number(params.row.cbasis).toFixed(2)}</span>;
    },
  },
  {
    field: 'personal',
    headerName: 'Personal',
    align: 'right',
    width: 90,
    renderCell: (params) => {
      if (params.row.personal) {
        return 'Y';
      }
      return 'N';
    },
  },
  {
    field: 'created',
    headerName: 'Week',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created ? params.row.created.substring(0, 10) : ''}</span>;
    },
  },
];

const BSFs = () => {
  const classes = useStyles();
  const [err] = useState(null);
  const [personal, setPersonal] = useState('false');

  let url = '/bsf/week?orderBy=created&limit=5000';
  if (personal !== 'all') {
    url = `${url}&personal=${personal}`;
  }

  const { data: bsf, loading: bsfLoading } = useSWR(url);
  const rows = (bsf || []).map((p, id) => {
    return {
      ...p,
      id,
      aum: Number(p.aum),
      annuity: Number(p.annuity),
      first_distribution: Number(p.first_distribution),
      life_target: Number(p.life_target),
      life_annual: Number(p.life_annual),
      count: Number(p.count),
      cbasis: Number(p.cbasis),
    };
  });

  return (
    <div style={classes.container}>
      <div className={classes.container}>
        <Typography variant="h4">BSFs by Week</Typography>
        <div>{err?.toString()}</div>
        <FormControl required className={classes.w200} >
          <StyledSelect
            select="all"
            label="Policy type"
            value={personal}
            name="personal"
            onChange={(evt) => {
              setPersonal(evt.target.value);
            }}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="true">Personal</MenuItem>
            <MenuItem value="false">Non-Personal</MenuItem>
          </StyledSelect>
        </FormControl>
        {rows && rows.length ? (<DownloadButton rows={rows} name="bsfs-weekly"/>) : ''}
        <Paper>
          {!bsfLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={rows} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </div>
  );
};

export default BSFs;
