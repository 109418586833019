import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';

import ProductTypeForm from '../components/product_type-form';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const ProductType = () => {
  const [isNew, setIsNew] = useState(false);
  const params = useParams();
  const location = useLocation();

  const { product_typeId } = params;
  const [product_type, setProductType] = useState({
    name: '',
    active: true,
  });

  function findProductType(id) {
    return apiFetch(`/product_types/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    setIsNew(location.pathname === '/product_types/new');
    if (product_typeId) {
      const product_type = await findProductType(product_typeId);
      setProductType(product_type);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setProductType({ ...product_type, [name]: value });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setProductType({ ...product_type, [name]: checked });
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="BSF Product Type Form">
      <div>
        {
      location.pathname === '/product_types/new' || product_type ? (
        < ProductTypeForm
          product_type={product_type}
          isNew={isNew}
          handleTextChange={handleTextChange}
          handleToggleChange={handleToggleChange}
        />
      ) : (
        <div className={classes.loadingCircle}>
          <CircularProgress size={100} />
        </div>
      )
}

      </div>
    </Layout>
  );
};

export default ProductType;
