import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { reset } from 'cooldux';

import {
  Box, Toolbar, AppBar,
} from '@mui/material';

import { logout } from '../state/user';
import { isAdmin } from '../lib/util';
import { colors, Button } from '../lib/styles';

const styles = {
  header: {
    backgroundColor: colors.black,
    padding: '5px',
  },
  headerText: {
    color: colors.highlightBright,
    fontWeight: 100,
    paddingLeft: '10px',
  },
  icon: {
    color: colors.white,
  },
  logo: {
    height: '80px',
    padding: '5px 5px 5px 10px',
    marginRight: '10px',
  },
  menuItem: {
    margin: '5px',
    backgroundColor: 'white',
    padding: '3px',
  },
  menuButton: {
    '&:hover': {
      color: colors.darkAccent,
    },
  },
};

function HeaderButton(props) {
  const { pathname } = useLocation();
  const { href, label } = props;

  return (
    <Button
      href={href}
      variant="inherit"
      sx={{
        fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
        fontSize: 12,
        background: href === pathname ? colors.gold : 'primary',
        color: href === pathname ? colors.black : colors.darkAccent,
        ':hover': {
          background: href === pathname ? colors.goldHighlight : colors.primaryAccent,
          color: href === pathname ? colors.black : colors.darkAccent,
        },
      }}
    >
      {label}
    </Button>
  );
}

class Header extends Component {
  constructor(props) {
    super(props);
    this.handleLogout = this.handleLogout.bind(this);
  }

  handleLogout() {
    this.props.logout()
      .then(() => {
        this.props.reset();
      });
  }

  render() {
    const { user } = this.props;
    let btn = (
      <Button
        gold
        href="/app"
        variant="inherit"
        color="primary"
        sx={{
          marginRight: '10px',
        }}
      >
        Login
      </Button>
    );

    if (user.auth) {
      btn = (
        <Button
          gold
          variant="inherit"
          color="primary"
          href="/"
          onClick={this.handleLogout}
          sx={{
            marginRight: '10px',
          }}
        >
          Logout
        </Button>
      );
    } else if (user.verifyPending || user.authPending) {
      btn = '';
    }
    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar style={styles.header}>
            <Button variant="inherit" color="primary" href="/">
              <img alt="Home" style={styles.logo} src="/images/stronghold_logo.png" />
            </Button>
            <Box sx={{ flexGrow: 1 }}>
              <HeaderButton href="/users" label="Users"/>
              <HeaderButton href="/clients" label="Clients"/>
              {user.auth && isAdmin(user.auth) ? (
                <>
                  <HeaderButton href="/feedback" label="Feedback"/>
                  <HeaderButton href="/bsfs" label="BSFs"/>
                  <HeaderButton href="/eca" label="ECA"/>
                  <HeaderButton href="/payments" label="Agent Payments"/>
                  <HeaderButton href="/stronghold-payments" label="Wealth Share Collection"/>
                  <HeaderButton href="/override-payments" label="Wealth Share Payout"/>
                  <HeaderButton href="/chargeback_summary" label="Chargebacks"/>
                  <HeaderButton href="/stripe" label="Stripe"/>
                  <HeaderButton href="/files" label="Business Resources"/>
                  <HeaderButton href="/email_log" label="Emails"/>
                  <HeaderButton href="/config" label="Config"/>
                </>
              ) : ''}
            </Box>
            {btn}
          </Toolbar>
        </AppBar>
      </Box>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, { logout, reset })(Header);
