import React from 'react';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

import makeStyles from '@mui/styles/makeStyles';
import DownloadButton from '../components/download-csv';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'to_email',
    headerName: 'To',
    align: 'left',
    width: 200,
  },
  {
    field: 'subject',
    headerName: 'Subject',
    align: 'left',
    width: 250,
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'left',
    width: 150,
  },
  {
    field: 'Date',
    headerName: 'Created',
    align: 'left',
    width: 200,
    renderCell: (params) => {
      const { last_event_time } = params.row;
      return <span>{last_event_time ? moment(last_event_time).format('MM-DD-YYYY h:mm:ss a') : ''}</span>;
    },
  },
];

const Signups = () => {
  const classes = useStyles();
  const params = useParams();
  const url = `/users/${encodeURI(params.email)}/email_log`;
  const { data, isValidating } = useSWR(url, { refreshInterval: 600000000 });

  const messages = data?.messages || [];
  messages.forEach((m, id) => {
    m.id = id;
  });

  return (
    <div style={classes.container}>
      <div className={classes.container}>
        <Typography variant="h4">
          <div>Email Log</div>
        </Typography>
        {messages && messages.length ? (<DownloadButton rows={messages} name="messages"/>) : ''}
        <Paper>
          {!isValidating ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={messages} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </div>
  );
};

export default Signups;
