/* eslint-disable max-len */
import React from 'react';
import { Button } from './common';
import {
  GoogleIcon, GoogleIconWhite, AppleIconWhite, MicrosoftIconWhite, MicrosoftIcon, AppleIcon,
} from './icons';
import { colors } from './styles';
import { apiFetch } from './fetch';

const btn = {
  fontFamily: 'Roboto, Helvetica, sans-serif',
  textTransform: 'none',
  textAlign: 'start',
  justifyContent: 'flex-start',
  paddingLeft: 50,
  marginTop: 10,
  width: 300,
  fontSize: 18,
};

export const startOauth = async (mode) => {
  const { providers } = await apiFetch(`/users/oauth_start?mode=${mode}`);
  return providers;
};

export const OAuthButton = (props) => (
  <Button
    {...props}
    style={{
      ...btn,
      ...props.style,
    }}
    onClick={props.onClick || (() => {
      document.location.href = props.authUrl;
    })}
  >Sign In With {props.name}
  </Button>
);

export const AppleButton = (props) => <OAuthButton name="Apple" {...props} startIcon={AppleIconWhite} style={{ ...props.style, backgroundColor: colors.black }}/>;

export const AppleAltButton = (props) => <OAuthButton name="Apple" {...props} startIcon={AppleIcon} style={{ ...props.style, backgroundColor: colors.white, color: colors.black }}/>;

export const GoogleButton = (props) => <OAuthButton name="Google" {...props} startIcon={GoogleIconWhite} style={{ ...props.style, backgroundColor: colors.googleRed, color: colors.white }}/>;

export const GoogleAltButton = (props) => <OAuthButton name="Google" {...props} startIcon={GoogleIcon} style={{ ...props.style, backgroundColor: colors.white, color: colors.black }}/>;

export const MicrosoftButton = (props) => <OAuthButton name="Microsoft" {...props} startIcon={MicrosoftIconWhite} style={{ ...props.style, backgroundColor: colors.microsoftBlue }}/>;

export const MicrosoftAltButton = (props) => <OAuthButton name="Microsoft" {...props} startIcon={MicrosoftIcon} style={{ ...props.style, backgroundColor: colors.white, color: colors.black }}/>;
