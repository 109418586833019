import React, { useState } from 'react';

import useSWR from 'swr';

import Paper from '@mui/material/Paper';
import { useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';

import { colors } from '../lib/styles';
import DownloadButton from '../components/download-csv';
import { getNotes } from '../lib/stripe';
import Layout from '../components/layout';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
  w200: {
    width: '200px',
    margin: '10px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 120,
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'type',
    headerName: 'Type',
    align: 'left',
    width: 250,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{Number(params.row.amount / 100).toFixed(2)}</span>;
    },
  },
  {
    field: 'notes',
    headerName: 'Notes',
    align: 'left',
    width: 250,
    renderCell: (params) => {
      return getNotes(params.row.data);
    },
  },
  {
    field: 'created_at',
    headerName: 'Created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
    },
  },
];

const PaycenterLog = () => {
  const classes = useStyles();
  const [err] = useState(null);
  const params = useParams();
  const { userId } = params;
  let url = '/stripe_log?orderBy=created_at&limit=5000';
  if (userId) {
    url = `${url}&agent=${userId}`;
  }
  const { data: payments, loading: paymentsLoading } = useSWR(url);

  return (
    <Layout headerTitle="Paycenter Log">
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        {payments && payments.length ? (<DownloadButton rows={payments} name="paycenter_log"/>) : ''}
        <Paper>
          {!paymentsLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={payments || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default PaycenterLog;
