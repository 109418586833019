import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  TextField, Card, CardHeader,
} from '@mui/material';
import { get } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { updatePassword } from '../state/user';
import { colors, formButton, Button } from '../lib/styles';

const baseStyles = {
  container: {
    backgroundAttachment: 'scroll',
    backgroundRepeat: 'repeat',
    height: '100%',
    minHeight: '900px',
    padding: '10px',
  },
  loginBox: {
    margin: '0 auto 100px',
    textAlign: 'center',
    width: '500px',
  },
  errorText: {
    color: colors.secondaryAccent,
    height: '18px',
    paddingTop: '18px',
  },
  form: {
    margin: 'auto',
    padding: '35px 50px 50px',
  },
};

const UpdatePassword = (props) => {
  const formik = useFormik({
    initialValues: {
      confirmPassword: '',
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .max(10000, 'Must be 10000 characters or less')
        .min(8, 'Must be 8 characters or more')
        .required('Required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], 'Passwords must match')
        .required('Required'),
    }),
    onSubmit: (values) => {
      const { confirmPassword, password } = values;
      props.updatePassword(props.match.params.token, password, confirmPassword)
        .then((res) => {
          props.history.push('/app');
          return res;
        });
    },
  });

  const { user } = props;

  if (!user.updatePassword) {
    const propErrors = {};
    let errorMsg = '';
    if (user.updatePasswordError && user.updatePasswordError.details) {
      user.updatePasswordError.details.forEach((detail) => {
        propErrors[detail.path] = detail.message;
      });
    } else if (user.updatePasswordError) {
      // TODO fetch can get better errors
      // eslint-disable-next-line
      errorMsg = get(user.updatePasswordError, 'content.message') || get(user.updatePasswordError, 'content.error') || user.updatePasswordError.message;
    }

    return (
      <div style={baseStyles.container} >
        <Card style={baseStyles.loginBox}>
          <CardHeader title="Update your password." />
          <div style={baseStyles.errorText}>{errorMsg}</div>
          <form onSubmit={formik.handleSubmit} style={baseStyles.form}>
            <TextField
              className="login-field"
              label="Password"
              fullWidth={true}
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              value={formik.values.password}
              onChange={formik.handleChange}
              name="password"
              type="password"
            />
            <TextField
              className="login-field"
              label="Confirm Password"
              fullWidth={true}
              error={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
              helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              name="confirmPassword"
              type="password"
            />
            <Button
              style={formButton}
              type="submit"
              title="Update Password"
            />
          </form>
        </Card>
      </div>
    );
  }

  return (
    <div style={baseStyles.container} >
      <Card style={baseStyles.loginBox}>
        <span>Your password has been reset.</span>
        <Link to="/app">
          <Button title="Login"/>
        </Link>
      </Card>
    </div>
  );
};

function mapStateToProps(state) {
  const { user } = state;

  return { user };
}

export default connect(mapStateToProps, { updatePassword })(UpdatePassword);
