import { useSelector } from 'react-redux';

const useUser = () => {
  const user = useSelector((state) => state.user);
  const auth = user?.auth;
  if (auth) {
    if (auth.scope.includes('SERVICE_REP')) {
      auth.isServiceRep = true;
    }
  }
  return auth;
};

export default useUser;
