import moment from 'moment';

export function getNotes(log) {
  const obj = log.data?.object;
  if (!obj) {
    return '';
  }
  if (log.type === 'customer.subscription.updated') {
    if (obj.cancel_at) {
      const dateStr = moment.unix(obj.cancel_at).format('MM/DD/YYYY');
      return `Cancel At ${dateStr}`;
    }
  }
  return '';
}
