import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';

import ClientAnnuityTypeForm from '../components/client_annuity_type-form';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const ClientAnnuityType = () => {
  const [isNew, setIsNew] = useState(false);
  const params = useParams();
  const location = useLocation();

  const { client_annuity_typeId } = params;
  const [client_annuity_type, setClientAnnuityType] = useState({
    name: '',
    active: true,
  });

  function findClientAnnuityType(id) {
    return apiFetch(`/client_annuity_types/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    setIsNew(location.pathname === '/client_annuity_types/new');
    if (client_annuity_typeId) {
      const client_annuity_type = await findClientAnnuityType(client_annuity_typeId);
      setClientAnnuityType(client_annuity_type);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setClientAnnuityType({ ...client_annuity_type, [name]: value });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setClientAnnuityType({ ...client_annuity_type, [name]: checked });
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="Client Annuity Type Form">
      {
        location.pathname === '/client_annuity_types/new' || client_annuity_type ? (
          < ClientAnnuityTypeForm
            client_annuity_type={client_annuity_type}
            isNew={isNew}
            handleTextChange={handleTextChange}
            handleToggleChange={handleToggleChange}
          />
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress size={100} />
          </div>
        )
      }
    </Layout>
  );
};

export default ClientAnnuityType;
