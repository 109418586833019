import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import useSWR from 'swr';

import { colors } from '../lib/styles';
import ConfigTypesLayout from '../components/config-types-layout';

const Custodians = () => {
  const columns = [
    {
      field: 'name',
      headerName: 'Custodian',
      align: 'left',
    },
    {
      field: 'code',
      headerName: 'Custodian Code',
      align: 'left',
    },
    {
      field: 'active',
      headerName: 'Active',
      align: 'right',
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
    },
  ];

  const { data: custodians, isValidating } = useSWR('/custodians');

  let rows = [];
  if (custodians) {
    rows = custodians.map((row) => {
      return {
        id: row.id,
        active: capitalize(String(row.active)),
        name: row.name,
        code: row.code,
        order_val: row.order_val,
        edit: <Link to={`/custodians/${row.id}/edit`} style={{ color: colors.darkAccent }}>Edit</Link>,
      };
    });
  }

  return (
    <ConfigTypesLayout
      rows={rows}
      isValidating={isValidating}
      columns={columns}
      title="Custodian"
      path="custodians"
      order="name"
    />
  );
};

export default Custodians;
