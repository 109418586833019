import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';

import SourceForm from '../components/source-form';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const Source = () => {
  const [isNew, setIsNew] = useState(false);
  const params = useParams();
  const location = useLocation();

  const { sourceId } = params;
  const [source, setSource] = useState({
    name: '',
    active: true,
  });

  function findSource(id) {
    return apiFetch(`/sources/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    setIsNew(location.pathname === '/sources/new');
    if (sourceId) {
      const source = await findSource(sourceId);
      setSource(source);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setSource({ ...source, [name]: value });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setSource({ ...source, [name]: checked });
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="Source Form">
      {
      location.pathname === '/sources/new' || source ? (
        < SourceForm
          source={source}
          isNew={isNew}
          handleTextChange={handleTextChange}
          handleToggleChange={handleToggleChange}
        />
      ) : (
        <div className={classes.loadingCircle}>
          <CircularProgress size={100} />
        </div>
      )
    }
    </Layout>
  );
};

export default Source;
