import React, { useEffect, useState } from 'react';
import { capitalize, startCase, camelCase } from 'lodash';
import moment from 'moment';
import useSWR from 'swr';
import Paper from '@mui/material/Paper';
import { useHistory, Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';

import { colors, Button } from '../lib/styles';
import DownloadButton from '../components/download-csv';
import UserSearch from '../components/user-search';
import config from '../config';
import { isAdmin } from '../lib/util';
import useUser from '../lib/useUser';
import Layout from '../components/layout';
import { Select } from '../lib/common';
import { apiFetch } from '../lib/fetch';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const Users = () => {
  const [url, setUrl] = useState('/users/all?limit=5000&orderBy=num&sortOrder=asc');
  const { data: users, loading: isValidating } = useSWR(url);
  const { data: allTags } = useSWR('/tags?orderBy=order_val&active=true');

  const auth = useUser();
  const history = useHistory();
  const classes = useStyles();
  const [err] = useState(null);
  const [is_deleted, setIsDeleted] = useState('false');
  const [approved, setApproved] = useState('true');
  const [ep, setEp] = useState('all');
  const [pc, setPc] = useState('all');
  const [filter, setFilter] = useState('all');
  const [sentMap, setSentMap] = useState({});
  const [cols, setCols] = useState([]);
  const [csvName, setCsvName] = useState('users');
  const [includesTag, setIncludesTag] = useState('true');
  const [selectedTag, setSelectedTag] = useState(null);

  let cleanUsers = filter === 'all' ? users?.map((u) => ({
    ...u,
    bsf_count: Number(u.bsf_count || 0),
    pay_count: Number(u.pay_count || 0),
    overpay_count: Number(u.overpay_count || 0),
    client_count: Number(u.client_count || 0),
    chargeback_count: Number(u.chargeback_count || 0),
    pay_total: Number(u.pay_total || 0),
    overpay_total: Number(u.overpay_total || 0),
    shpay_total: Number(u.shpay_total || 0),
    chargeback_total: Number(u.chargeback_total || 0),
    password: '',
  })) : users;

  if ((filter === 'all') && (selectedTag !== null)) {
    cleanUsers = cleanUsers.filter((u) => {
      const hasTag = (u.tags || []).includes(selectedTag);
      return includesTag === 'true' ? hasTag : !hasTag;
    });
  }

  const admin = isAdmin(auth);

  const params = {
    is_deleted,
    approved,
    ep,
    pc,
  };

  let columns = [
    { field: 'id', hide: true },
    {
      field: 'num',
      headerName: 'Number',
      align: 'right',
      width: 100,
    },
    {
      field: 'firstname',
      headerName: 'First Name',
      align: 'left',
      width: 200,
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      align: 'left',
      width: 200,
    },
  ];

  if (approved === 'false') {
    columns.push({
      field: 'approved_pct',
      headerName: '% completed',
      align: 'right',
      width: 110,
      renderCell: (params) => {
        const { row } = params;
        let steps = 0;
        if (row.ca_signed) {
          steps += 1;
        }
        if (row.w9_signed) {
          steps += 1;
        }
        if (row.ep) {
          steps += 1;
        }
        const pct = Math.round((steps / 3) * 100);
        return `${pct}%`;
      },
    });
    columns.push({
      field: 'created_at',
      headerName: 'created',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        return moment(params.row.created_at).format('YYYY-MM-DD');
      },
    });
  }

  columns = columns.concat([
    {
      field: 'dob',
      headerName: 'DOB',
      align: 'right',
      width: 120,
      renderCell: (params) => {
        if (params.row.dob) {
          return moment(params.row.dob).format('YYYY-MM-DD');
        }
        return '';
      },
    },
    {
      field: 'switch',
      headerName: 'Switch',
      width: '75',
      renderCell: (params) => {
        if (isAdmin(params.row)) {
          return '';
        }
        return (
          <a
            href={`${config.API_URL}/users/p/${params.row.id}`}
            style={{ color: colors.darkAccent }}
          >
            Switch
          </a>
        );
      },
    },
    {
      field: 'network',
      headerName: 'Network',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        if (!admin) {
          return '';
        }
        return <Link style={{ color: colors.darkAccent }} to={`/network/${params.row.id}`}>Network</Link>;
      },
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        if (!admin) {
          return '';
        }
        return <Link style={{ color: colors.darkAccent }} to={`/users/${params.row.id}/edit`}>Edit</Link>;
      },
    },
    {
      field: 'overview',
      headerName: 'Overview',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        if (!admin) {
          return '';
        }
        return <Link style={{ color: colors.darkAccent }} to={`/users/${params.row.id}/overview`}>Overview</Link>;
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      align: 'left',
      width: 200,
    },
    {
      field: 'bsf_count',
      headerName: 'BSFs',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (!admin) {
          return '';
        }
        return (
          <Link
            style={{ color: colors.darkAccent }}
            to={`/users/${params.row.id}/bsfs`}
          >
            {params.row.bsf_count}
          </Link>
        );
      },
    },
    {
      field: 'client_count',
      headerName: 'Clients',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (!admin) {
          return '';
        }
        return (
          <Link
            style={{ color: colors.darkAccent }}
            to={`/users/${params.row.id}/clients`}
          >
            {params.row.client_count}
          </Link>
        );
      },
    },
    {
      field: 'pay_total',
      headerName: 'Payments',
      align: 'right',
      width: 120,
      renderCell: (params) => {
        if (!admin) {
          return '';
        }
        return (
          <Link
            style={{ color: colors.darkAccent }}
            to={`/users/${params.row.id}/payments`}
          >
            ${Number(params.row.pay_total).toFixed(2)}
          </Link>
        );
      },
    },
    {
      field: 'overpay_total',
      headerName: 'Overrides',
      align: 'right',
      width: 120,
      renderCell: (params) => {
        if (!admin) {
          return '';
        }
        return (
          <Link
            style={{ color: colors.darkAccent }}
            to={`/users/${params.row.id}/overrides`}
          >
            ${Number(params.row.overpay_total).toFixed(2)}
          </Link>
        );
      },
    },
    {
      field: 'shpay_total',
      headerName: 'WS Collection',
      align: 'right',
      width: 125,
      renderCell: (params) => {
        if (!admin) {
          return '';
        }
        return (
          <Link
            style={{ color: colors.darkAccent }}
            to={`/users/${params.row.id}/stronghold-payments`}
          >
            ${Number(params.row.shpay_total).toFixed(2)}
          </Link>
        );
      },
    },
    {
      field: 'chargeback_total',
      headerName: 'Chargebacks',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        if (!admin) {
          return '';
        }
        return (
          <Link
            style={{ color: colors.darkAccent }}
            to={`/users/${params.row.id}/chargebacks`}
          >
            ${Number(params.row.chargeback_total).toFixed(2)}
          </Link>
        );
      },
    },
    {
      field: 'log_created',
      headerName: 'Accessed',
      align: 'right',
      width: 120,
      renderCell: (params) => {
        if (params.row.log_created) {
          return moment(params.row.log_created).format('YYYY-MM-DD HH:mm');
        }
        return '';
      },
    },
    {
      field: 'account_num',
      headerName: 'Acc. Num',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (params.row.account_num) {
          return 'Y';
        }
        return 'N';
      },
    },
    {
      field: 'routing_num',
      headerName: 'Routing',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (params.row.routing_num) {
          return 'Y';
        }
        return 'N';
      },
    },
    {
      field: 'ca_signed',
      headerName: 'CA signed',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (params.row.ca_signed) {
          return 'Y';
        }
        return 'N';
      },
    },
    {
      field: 'w9_signed',
      headerName: 'w9',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (params.row.w9_signed) {
          return 'Y';
        }
        return 'N';
      },
    },
    {
      field: 'ep',
      headerName: 'Enr. Paid',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (params.row.ep) {
          return 'Y';
        }
        return 'N';
      },
    },
    {
      field: 'pc',
      headerName: 'Monthly Paid',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (params.row.pc) {
          return 'Y';
        }
        return 'N';
      },
    },
    {
      field: 'approved',
      headerName: 'Approved',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (params.row.approved) {
          return 'Y';
        }
        return 'N';
      },
    },
  ]);

  const filteredColumns = (filter) => [
    { field: 'id', hide: true },
    {
      field: 'firstname',
      headerName: 'First Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'num',
      headerName: 'Agent #',
      align: 'left',
      width: 150,
    },
    {
      field: 'count',
      headerName: 'Count',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        const link = `/recruits/${params.row.userId}/${filter}/${params.row.created.substring(0, 10)}`;
        return (
          <Link style={{ color: colors.darkAccent }} to={link}>
            {params.row.count}
          </Link>
        );
      },
    },
    {
      field: 'network',
      headerName: 'Network',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        return (
          <Link style={{ color: colors.darkAccent }} to={`/network/${params.row.userId}`}>
            Network
          </Link>
        );
      },
    },
    {
      field: 'created',
      headerName: capitalize(filter),
      align: 'right',
      width: 150,
      renderCell: (params) => {
        return <span>{params.row.created ? params.row.created.substring(0, 10) : ''}</span>;
      },
    },
  ];

  const signupColumns = [
    { field: 'id', hide: true },
    {
      field: 'firstname',
      headerName: 'First',
      align: 'left',
      width: 150,
    },
    {
      field: 'lastname',
      headerName: 'Last',
      align: 'left',
      width: 200,
    },
    {
      field: 'email',
      headerName: 'Email',
      align: 'left',
      width: 250,
    },
    {
      field: 'sponsor_num',
      headerName: 'Sponsor #',
      align: 'left',
      width: 150,
    },
    {
      field: 'sponsor_firstname',
      headerName: 'Sponsor First',
      align: 'left',
      width: 150,
    },
    {
      field: 'sponsor_lastname',
      headerName: 'Sponsor Last',
      align: 'left',
      width: 150,
    },
    {
      field: 'created_at',
      headerName: 'Created',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
      },
    },
    {
      field: 'updated_at',
      headerName: 'Re-Send',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        if (!params.row.user_id) {
          return (
            <Button
              gold
              sx={{ margin: '15px' }}
              onClick={async () => {
                await apiFetch(`/pub_validations/${params.row.id}/resend`, { method: 'PUT' });
                setSentMap({ ...sentMap, [params.row.id]: true });
              }}
              disabled={!!sentMap[params.row.id]}
            >
              Re-Send
            </Button>
          );
        }
        return '';
      },
    },
    {
      field: 'user_id',
      headerName: 'Agent',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        if (params.row.user_id) {
          return <Link style={{ color: colors.darkAccent }} to={`/users/${params.row.user_id}/edit`}>Edit</Link>;
        }
        return '';
      },
    },
    {
      field: 'log',
      headerName: 'Email Log',
      align: 'left',
      width: 400,
      renderCell: (params) => {
        return <Link style={{ color: colors.darkAccent }} to={`/email_log/${params.row.email}`}>Details</Link>;
      },
    },
  ];

  useEffect(() => {
    switch (filter) {
      case 'all':
        setUrl('/users/all?limit=5000&orderBy=num&sortOrder=asc');
        setCols(columns);
        break;

      case 'signups':
        setUrl('/signups?orderBy=created_at&limit=5000');
        setCols(signupColumns);
        setCsvName('signups');
        break;

      case 'week':
      case 'month':
      case 'quarter':
      case 'year':
        setUrl(`/recruits/${filter}?orderBy=created&limit=5000`);
        setCols(filteredColumns(filter));
        setCsvName(`recruits-${filter}ly`);
        if (users) {
          users.forEach((user, id) => {
            user.userId = user.id;
            user.id = id;
          });
        }
        break;

      default:
        break;
    }
  }, [filter]);

  useEffect(() => {
    if (filter === 'all') {
      let update = '';
      for (const param in params) {
        if (params[param] !== 'all') {
          update += `&${param}=${params[param]}`;
        }
      }
      setUrl(`/users/all?limit=5000&orderBy=num&sortOrder=asc${update}`);
    }
  }, [filter, is_deleted, approved, ep, pc]);

  if (isValidating || !users || !columns) return <CircularProgress/>;

  return (
    <Layout
      headerTitle={
        `Users${
          filter === 'all'
            ? ''
            : ` - ${startCase(camelCase(filter === 'signups' ? 'Signups' : `Recruits by ${filter}`))}`
        }`
      }
    >
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        <Box display="grid" gridTemplateColumns="repeat(15, 1fr)" gap={2} mb={1} sx={{ width: '75%' }}>
          <Box gridColumn="span 3">
            <Select
              value={filter}
              name="filter"
              onChange={(evt) => {
                setFilter(evt.target.value);
              }}
            >
              <MenuItem value="all">All Users</MenuItem>
              <MenuItem value="signups">Signups</MenuItem>
              <MenuItem value="week">Recruits by week</MenuItem>
              <MenuItem value="month">Recruits by month</MenuItem>
              <MenuItem value="quarter">Recruits by quarter</MenuItem>
              <MenuItem value="year">Recruits by year</MenuItem>
            </Select>
          </Box>
          <Box gridColumn="span 12" />
          <Box my={2} gridColumn="span 12">
            <UserSearch
              label="search"
              onChange={(user) => {
                if (user) {
                  history.push(`/users/${user.id}/overview`);
                }
              }}
            />
          </Box>
          <Box gridColumn="span 3" sx={{ display: 'flex' }}>
            {cleanUsers && cleanUsers.length ? (
              <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
                <DownloadButton rows={cleanUsers} name={csvName} style={{ color: colors.darkAccent }}/>
              </Box>
            ) : ''}
          </Box>
          {
            filter === 'all' && (
              <>
                <Box gridColumn="span 3">
                  <Select
                    label="Active"
                    value={is_deleted}
                    name="active"
                    onChange={(evt) => {
                      setIsDeleted(evt.target.value);
                    }}
                  >
                    <MenuItem value="false">Active</MenuItem>
                    <MenuItem value="true">Deleted</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                  </Select>
                </Box>
                <Box gridColumn="span 3">
                  <Select
                    label="Approved"
                    value={approved}
                    name="approved"
                    onChange={(evt) => {
                      setApproved(evt.target.value);
                    }}
                  >
                    <MenuItem value="true">Approved</MenuItem>
                    <MenuItem value="false">Unapproved</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                  </Select>
                </Box>
                <Box gridColumn="span 3">
                  <Select
                    label="Enrollment Paid"
                    value={ep}
                    name="ep"
                    onChange={(evt) => {
                      setEp(evt.target.value);
                    }}
                  >
                    <MenuItem value="true">Paid</MenuItem>
                    <MenuItem value="false">Not Paid</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                  </Select>
                </Box>
                <Box gridColumn="span 3">
                  <Select
                    label="Monthly Current"
                    value={pc}
                    name="pc"
                    onChange={(evt) => {
                      setPc(evt.target.value);
                    }}
                  >
                    <MenuItem value="true">Current</MenuItem>
                    <MenuItem value="false">Not Paid</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                  </Select>
                </Box>
                {allTags && (
                  <div style={{ display: 'flex' }}>
                    <Box gridColumn="span 3" style={{ minWidth: '100px', marginRight: '5px' }}>
                      <Select
                        label="has tag?"
                        value={includesTag}
                        name="includesTag"
                        onChange={(evt) => {
                          setIncludesTag(evt.target.value);
                        }}
                      >
                        <MenuItem value="true">has tag</MenuItem>
                        <MenuItem value="false">does NOT have tag</MenuItem>
                      </Select>
                    </Box>
                    <Box gridColumn="span 3" style={{ minWidth: '100px' }}>
                      <Select
                        label="tag name"
                        value={selectedTag}
                        name="selectedTag"
                        onChange={(evt) => {
                          setSelectedTag(evt.target.value);
                        }}
                      >
                        <MenuItem value={null}>-</MenuItem>
                        {allTags.map((tag) => (
                          <MenuItem key={tag.id} value={tag.id}>{tag.name}</MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </div>
                )}
              </>
            )
          }
        </Box>
        <Paper>
          {!isValidating ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={cols} rows={cleanUsers} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
        <Button sx={{ margin: '10px' }} gold addIcon onClick={() => { history.push('/users/new'); }}>
          Add User
        </Button>
        <Button sx={{ margin: '10px' }} gold onClick={() => { history.push('/agent_wealthshare_progress'); }}>
          Pillar Leaderboard
        </Button>
      </div>
    </Layout>
  );
};

export default Users;
