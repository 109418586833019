import React from 'react';
import { Link } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import makeStyles from '@mui/styles/makeStyles';
import { DataGrid } from '@mui/x-data-grid';

import { colors } from '../lib/styles';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 350,
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
});

const Directs = (props) => {
  const classes = useStyles();
  const { data } = props;

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'num',
      headerName: 'Agent #',
      align: 'left',
      width: 150,
    },
    {
      field: 'firstname',
      headerName: 'First Name',
      align: 'left',
      width: 200,
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      align: 'left',
      width: 200,
    },
    {
      field: 'fbs',
      headerName: '6 mos. Business',
      align: 'right',
      width: 150,
      renderCell: (params) => {
        const { fbs } = params.row;
        return (
          <span style={{ color: fbs >= 5000 ? 'green' : 'red' }}>
            {Number(fbs).toFixed(2)}
          </span>
        );
      },
    },
    {
      field: 'edit',
      headerName: 'Details',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        return <Link to={`/users/${params.row.id}/overview`} style={{ color: colors.darkAccent }}>details</Link>;
      },
    },
  ];

  return (
    <div className={classes.container}>
      <Paper>
        <div className={classes.gridContainer}>
          <DataGrid columns={columns} rows={data || []} />
        </div>
      </Paper>
    </div>
  );
};

export default Directs;
