import React, { useMemo, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import { verify } from '../state/user';

const AuthWrapper = connect(() => ({}), { verify })(({ children, verify, ...otherProps }) => {
  const [{ loggedIn, loggingIn }, setLoginState] = useState({ loggedIn: false, loggingIn: true });
  useMemo(async () => {
    setLoginState({ loggedIn, loggingIn: true });
    try {
      await verify();
      setLoginState({ loggedIn: true, loggingIn: false });
    } catch (e) {
      setLoginState({ loggedIn: false, loggingIn: false });
    }
    // eslint-disable-next-line
  }, [otherProps.Component]);
  if (!loggedIn && loggingIn) {
    return null;
  }

  if (!loggedIn && !loggingIn) {
    return <Redirect to={{ pathname: '/login' }} />;
  }

  return React.Children.map(children, (child) => {
    return React.cloneElement(child, { ...otherProps });
  });
});

const authWrapped = (Component) => {
  return (props) => (
    <AuthWrapper {...props}>
      <Component />
    </AuthWrapper>
  );
};

const ProtectedRoute = (props) => {
  const component = authWrapped(props.component);
  return (<Route {...props} component={component} />);
};

export default ProtectedRoute;
