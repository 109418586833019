import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import useSWR from 'swr';

import { colors } from '../lib/styles';
import ConfigTypesLayout from '../components/config-types-layout';

const Tags = () => {
  const columns = [
    {
      field: 'name',
      headerName: 'Tag',
      align: 'left',
      width: 250,
    },
    {
      field: 'active',
      headerName: 'Active',
      align: 'right',
      width: 100,
    },
    {
      field: 'agent_visible',
      headerName: 'Agent Visible',
      align: 'right',
      width: 100,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
      width: 100,
    },
  ];

  const { data: tags, isValidating } = useSWR('/tags');

  let rows = [];
  if (tags) {
    rows = tags.map((row) => {
      return {
        id: row.id,
        active: capitalize(String(row.active)),
        agent_visible: capitalize(String(row.agent_visible)),
        name: row.name,
        order_val: row.order_val,
        edit: <Link to={`/tags/${row.id}/edit`} style={{ color: colors.darkAccent }}>Edit</Link>,
      };
    });
  }

  return (
    <ConfigTypesLayout
      rows={rows}
      isValidating={isValidating}
      columns={columns}
      title="Tag"
      path="tags"
    />
  );
};

export default Tags;
