import React from 'react';
import { useLocation, useHistory } from 'react-router';
import { reset } from 'cooldux';
import { useDispatch, useSelector } from 'react-redux';
import { BsPinAngle, BsPinAngleFill } from 'react-icons/bs';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import BarChartIcon from '@mui/icons-material/BarChart';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContactsIcon from '@mui/icons-material/Contacts';
// import DashboardIcon from '@mui/icons-material/Home';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import PaidIcon from '@mui/icons-material/Paid';
import PaymentsIcon from '@mui/icons-material/Payments';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import SettingsIcon from '@mui/icons-material/Settings';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EmailIcon from '@mui/icons-material/Email';
import SavingsIcon from '@mui/icons-material/Savings';
import ChatIcon from '@mui/icons-material/ChatBubbleOutline';
import ArticleIcon from '@mui/icons-material/Article';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

import Header from './header';

import { colors } from '../lib/styles';
import { Button } from '../lib/common';

import { logout } from '../state/user';
import useUser from '../lib/useUser';
import {
  compressNav,
  expandNav,
  pinNav,
  unpinNav,
} from '../state/navigation';
import config from '../config';

const drawerWidth = 230;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const styles = {
  icon: {
    color: colors.white,
  },
  logo: {
    height: '80px',
    marginRight: '10px',
  },
  menuItem: {
    margin: '5px',
    backgroundColor: 'white',
    padding: '3px',
  },
  pubLink: {
    textDecoration: 'none',
    color: 'white',
    margin: '10px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  pubSubLink: {
    textDecoration: 'none',
    color: 'white',
    margin: '8px',
    marginLeft: '30px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
};

const tabs = [
  {
    path: '/users',
    label: 'Users',
    icon: <PeopleAltIcon />,
  },
  {
    label: 'Clients',
    path: '/clients',
    icon: <ContactPageIcon />,
  },
  {
    path: '/feedback',
    label: 'Feedback',
    icon: <ChatIcon />,
  },
  {
    path: '/bsfs',
    label: 'BSFs',
    icon: <HistoryEduIcon />,
  },
  {
    path: '/eca',
    label: 'ECAs',
    icon: <ArticleIcon />,
  },
  {
    path: '/payments',
    label: 'Agent Payments',
    icon: <PaymentsIcon />,
  },
  {
    path: '/stronghold-payments',
    label: 'Wealth Share Collection',
    icon: <SavingsIcon />,
  },
  {
    path: '/override-payments',
    label: 'Wealth Share Payments',
    icon: <PaidIcon />,
  },
  {
    path: '/stronghold-commission-payments',
    label: 'SH Commission Payments',
    icon: <PaidIcon />,
  },
  {
    path: '/chargeback_summary',
    label: 'Chargeback Summary',
    icon: <ContactsIcon />,
  },
  {
    path: '/stripe',
    label: 'Stripe Log',
    icon: <CreditCardIcon />,
  },
  {
    path: '/email_log',
    label: 'Email Log',
    icon: <EmailIcon />,
  },
  {
    path: '/files',
    label: 'Business Resources',
    icon: <BarChartIcon />,
  },
  {
    path: '/admin_files',
    label: 'Admin Documents',
    icon: <FolderSpecialIcon />,
  },
  {
    path: '/config',
    label: 'Config',
    icon: <SettingsIcon />,
  },
];

export default function Layout({ children, showFull = true, headerTitle = '' }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { navbarExpanded, navbarPinned } = useSelector((state) => state.navigation);
  const user = useUser();

  const handlePinNav = () => {
    dispatch(pinNav());
    dispatch(expandNav());
  };

  const handleUnpinNav = () => {
    dispatch(unpinNav());
  };

  const handleMouseLeave = () => {
    if (navbarPinned) return;
    dispatch(compressNav());
  };

  const handleCloseNav = () => {
    dispatch(unpinNav());
    dispatch(compressNav());
  };

  const handleOpenNav = () => {
    dispatch(expandNav());
  };

  const handleLogout = () => {
    logout()
      .then(() => {
        reset();
      })
      .then(() => {
        window.location.href = '/login';
      });
  };

  const handleChangeTab = (tab) => {
    return (tab.label === 'Training')
      ? window.open(tab.path, '_blank')
      : history.push(tab.path);
  };

  const innerComp = children;

  return (
    <>
      {
      showFull && user ? (
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
            <Toolbar
              disableGutters
              sx={{
                backgroundColor: colors.black,
                minWidth: '100%',
                textAlign: 'center',
                padding: '0px',
                height: '90px',
              }}
            >
              <Button
                variant="inherit"
                href="/dashboard"
                sx={{ flexShrink: 1, justifyContent: 'flex-start' }}
              >
                <img alt="Home" style={styles.logo} src="/images/stronghold_logo.png" />
              </Button>
              <Box sx={{ flexGrow: 3, display: 'flex', justifyContent: 'center' }}>
                {
                  user.proxy ? (
                    <div>
                      <p style={{
                        fontSize: 32,
                        fontWeight: 'lighter',
                        padding: 0,
                        margin: 15,
                        marginBottom: 0,
                        fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
                      }}
                      >
                        {headerTitle}
                      </p>
                      <p style={{ marginTop: 0, fontWeight: 'bolder' }}>
                        Viewing as {user.firstname} {user.lastname} (
                        <a
                          style={{ color: 'white' }}
                          href={`${config.API_URL}/users/rp`}
                        >
                          return
                        </a>)
                      </p>
                    </div>
                  ) : (
                    <p style={{
                      fontSize: 32,
                      fontWeight: 'lighter',
                      padding: 0,
                      margin: 15,
                      fontFamily: 'Libre Baskerville, Times New Roman, Times, serif',
                    }}
                    >
                      {headerTitle}
                    </p>
                  )
                }
              </Box>
              <Box sx={{
                flexShrink: 1, display: 'inline-flex', alignItems: 'center', float: 'flex-end',
              }}
              >
                <Button
                  gold
                  variant="inherit"
                  color="primary"
                  href="/"
                  onClick={handleLogout}
                  sx={{
                    marginRight: '10px',
                  }}
                >
                  Logout
                </Button>
              </Box>

            </Toolbar>
          </AppBar>
          <Drawer
            open={navbarExpanded}
            onMouseLeave={handleMouseLeave}
            variant="permanent"
            sx={{
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box', backgroundColor: colors.highlightDark, paddingTop: '20px',
              },
            }}
          >
            <Toolbar />
            <List style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <ListItem
                disablePadding
                sx={{
                  display: 'block',
                  m: 'auto',
                  paddingBottom: '5px',
                }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: 'initial',
                    px: 2.5,
                  }}
                  onMouseEnter={handleOpenNav}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: navbarExpanded ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    { navbarExpanded ? <ChevronLeftIcon onClick={handleCloseNav} /> : <ChevronRightIcon /> }
                  </ListItemIcon>
                  <ListItemText
                    primaryTypographyProps={{ fontSize: 14 }}
                    sx={{
                      opacity: navbarExpanded ? 1 : 0,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {
                      navbarPinned
                        ? <BsPinAngleFill size={18} onClick={handleUnpinNav} />
                        : <BsPinAngle size={18} onClick={handlePinNav} />
                      }
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <Divider color={colors.white} />
              {tabs.map((tab) => {
                return (
                  <ListItem
                    key={tab.path}
                    disablePadding
                  >
                    <ListItemButton
                      onClick={() => handleChangeTab(tab)}
                      sx={{
                        backgroundColor: tab.path === pathname ? colors.highlightDarker : null,
                        color: tab.path === pathname ? colors.white : null,
                        minHeight: 48,
                        justifyContent: navbarExpanded ? 'initial' : 'center',
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          color: tab.path === pathname ? colors.white : null,
                          minWidth: 0,
                          mr: navbarExpanded ? 3 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {tab.icon}
                      </ListItemIcon>
                      <ListItemText
                        primary={tab.label}
                        primaryTypographyProps={{ fontSize: 14 }}
                        sx={{ opacity: navbarExpanded ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
              <div style={{ margin: 'auto', flexGrow: 1 }} />
            </List>
          </Drawer>
          <Box
            component="main"
            sx={{
              flex: '1 0 auto',
              backgroundColor: colors.lightGrey,
              minHeight: '100vh',
              paddingTop: '30px',
              width: `calc(100% - ${drawerWidth}px)`,
            }}
          >
            <Toolbar />
            <Box>
              {innerComp}
            </Box>
          </Box>
        </Box>
      ) : (
        <>
          <Header />
          {innerComp}
        </>
      )
     }
    </>
  );
}
