import React from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { colors } from '../lib/styles';
import ConfigTypesLayout from '../components/config-types-layout';

const ClientAssetTypes = () => {
  const { data: assetTypes, isValidating } = useSWR('/client_asset_types');

  const columns = [
    {
      field: 'name',
      headerName: 'Client Asset Type',
      align: 'left',
      width: 250,
    },
    {
      field: 'taxable',
      headerName: 'Taxable',
      align: 'left',
      width: 100,
    },
    {
      field: 'tax_deferred',
      headerName: 'Tax Deferred',
      align: 'left',
      width: 100,
    },
    {
      field: 'tax_free',
      headerName: 'Tax Free',
      align: 'left',
      width: 100,
    },
    {
      field: 'employer_plan',
      headerName: 'Employer Plan',
      align: 'left',
      width: 120,
    },
    {
      field: 'insurance',
      headerName: 'Insurance',
      align: 'left',
      width: 100,
    },
    {
      field: 'active',
      headerName: 'Active',
      align: 'right',
      width: 100,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
      width: 100,
    },
  ];

  const sortedAssetTypes = assetTypes && assetTypes
    .sort((a, b) => a.order_val - b.order_val)
    .filter((row) => row !== null && row !== undefined)
    .map((row) => {
      if (!row) return;

      return {
        ...row,
        edit: (
          <Link
            to={`/client_asset_types/${row.id}/edit`}
            style={{ color: colors.darkAccent }}
          >
            Edit
          </Link>
        ),
      };
    });

  return (
    <ConfigTypesLayout
      rows={sortedAssetTypes}
      isValidating={isValidating}
      columns={columns}
      title="Client Asset Type"
      path="client_asset_types"
      toggles={['taxable', 'tax_deferred', 'tax_free', 'employer_plan', 'insurance', 'active']}
    />
  );
};

export default ClientAssetTypes;
