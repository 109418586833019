import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';

import ClientAssetTypeForm from '../components/client_asset_type-form';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const ClientAssetType = () => {
  const [isNew, setIsNew] = useState(false);
  const params = useParams();
  const location = useLocation();

  const { client_asset_typeId } = params;
  const [client_asset_type, setClientAssetType] = useState({
    name: '',
    active: true,
    taxable: true,
    tax_deferred: true,
    tax_free: true,
    employer_plan: false,
    insurance: false,
  });

  function findClientAssetType(id) {
    return apiFetch(`/client_asset_types/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    setIsNew(location.pathname === '/client_asset_types/new');
    if (client_asset_typeId) {
      const client_asset_type = await findClientAssetType(client_asset_typeId);
      setClientAssetType(client_asset_type);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setClientAssetType({ ...client_asset_type, [name]: value });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setClientAssetType({ ...client_asset_type, [name]: checked });
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="Client Asset Type Form">
      {
        location.pathname === '/client_asset_types/new' || client_asset_type ? (
          < ClientAssetTypeForm
            client_asset_type={client_asset_type}
            isNew={isNew}
            handleTextChange={handleTextChange}
            handleToggleChange={handleToggleChange}
          />
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress size={100} />
          </div>
        )
      }
    </Layout>
  );
};

export default ClientAssetType;
