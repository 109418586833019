import React, { useState } from 'react';
import useSWR from 'swr';
import { Link } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

import makeStyles from '@mui/styles/makeStyles';
import { colors, Button } from '../lib/styles';
import DownloadButton from '../components/download-csv';
import { apiFetch } from '../lib/fetch';

const useStyles = makeStyles({
  tintedBackground: {
    backgroundColor: colors.blackOpacity,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const Signups = () => {
  const classes = useStyles();
  const [sentMap, setSentMap] = useState({});
  const url = '/signups?orderBy=created_at&limit=5000';
  const { data: signups, loading: signupsLoading } = useSWR(url);

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'firstname',
      headerName: 'First',
      align: 'left',
      width: 150,
    },
    {
      field: 'lastname',
      headerName: 'Last',
      align: 'left',
      width: 200,
    },
    {
      field: 'email',
      headerName: 'Email',
      align: 'left',
      width: 250,
    },
    {
      field: 'sponsor_num',
      headerName: 'Sponsor #',
      align: 'left',
      width: 150,
    },
    {
      field: 'sponsor_firstname',
      headerName: 'Sponsor First',
      align: 'left',
      width: 150,
    },
    {
      field: 'sponsor_lastname',
      headerName: 'Sponsor Last',
      align: 'left',
      width: 150,
    },
    {
      field: 'created_at',
      headerName: 'Created',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        return <span>{params.row.created_at ? params.row.created_at.substring(0, 10) : ''}</span>;
      },
    },
    {
      field: 'updated_at',
      headerName: 'Re-Send',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        if (!params.row.user_id) {
          return (
            <Button
              gold
              sx={{ margin: '15px' }}
              onClick={async () => {
                await apiFetch(`/pub_validations/${params.row.id}/resend`, { method: 'PUT' });
                setSentMap({ ...sentMap, [params.row.id]: true });
              }}
              disabled={!!sentMap[params.row.id]}
            >
              Re-Send
            </Button>
          );
        }
        return '';
      },
    },
    {
      field: 'user_id',
      headerName: 'Agent',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        if (params.row.user_id) {
          return <Link style={{ color: colors.primaryText }} to={`/users/${params.row.user_id}/edit`}>Edit</Link>;
        }
        return '';
      },
    },
    {
      field: 'log',
      headerName: 'Signup Email Log',
      align: 'left',
      width: 400,
      renderCell: (params) => {
        return <Link style={{ color: colors.primaryText }} to={`/signups_log/${params.row.email}`}>Details</Link>;
      },
    },
  ];

  return (
    <div style={classes.container}>
      <div style={classes.mainBody}>
        <Typography variant="h4">
          <div>Signups</div>
        </Typography>
        {signups && signups.length ? (<DownloadButton rows={signups} name="signups"/>) : ''}
        <Paper className={classes.tintedBackground}>
          {!signupsLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={signups || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </div>
  );
};

export default Signups;
