import React, { useState } from 'react';
import moment from 'moment';
import useSWR from 'swr';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid';

import DownloadButton from '../components/download-csv';
import { colors, Button } from '../lib/styles';

const useStyles = makeStyles({
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px auto',
    minWidth: '300px',
    width: '80%',
  },
  tintedBackground: {
    backgroundColor: colors.blackOpacity,
  },
  orgs: {
    margin: '10px auto',
    minWidth: '350px',
    width: '100%',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  w200: {
    width: '200px',
    margin: '10px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'left',
    width: 100,
  },
  {
    field: 'count',
    headerName: 'WS Collection',
    align: 'right',
    width: 100,
  },
  {
    field: 'total_amount',
    headerName: 'Earned',
    align: 'right',
    width: 140,
    renderCell: (params) => {
      return <span>{Number(params.row.total_amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'total_max_amount',
    headerName: 'Max available',
    align: 'right',
    width: 140,
    renderCell: (params) => {
      return <span>{Number(params.row.total_max_amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'missed',
    headerName: 'Missed',
    align: 'right',
    width: 140,
    renderCell: (params) => {
      return <span>{Number(params.row.missed).toFixed(2)}</span>;
    },
  },
  {
    field: 'missed_start',
    headerName: 'Year',
    align: 'right',
    width: 160,
    renderCell: (params) => {
      return <span>{moment(params.row.missed_start).add(12, 'hours').format('YYYY')}</span>;
    },
  },
];

const MissedMoney = () => {
  const classes = useStyles();
  const [err] = useState(null);

  const url = '/missed_money/year?limit=5000';

  const { data, loading: rowsLoading } = useSWR(url);
  const rows = (data || []).map((p, id) => {
    return {
      ...p,
      id,
      total_amount: Number(p.total_amount),
      total_max_amount: Number(p.total_max_amount),
      missed: Number(p.total_max_amount) - Number(p.total_amount),
    };
  });

  return (
    <div style={classes.container}>
      <div style={classes.mainBody}>
        <Typography variant="h4">Missed Money by Year</Typography>
        <div>{err?.toString()}</div>
        {rows && rows.length ? (<DownloadButton rows={rows} name="missed-money-yearly"/>) : ''}
        <Paper className={classes.tintedBackground}>
          {!rowsLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={rows} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
      <div>
        <Button gold sx={{ margin: '15px' }}>
          <Link className={classes.linkBtn} to="/missed_money_year">Missed - Yearly</Link>
        </Button>
        <Button gold sx={{ margin: '15px' }}>
          <Link className={classes.linkBtn} to="/missed_money_year_pillar">Missed - Yearly & Pillar</Link>
        </Button>
        <Button gold sx={{ margin: '15px' }}>
          <Link className={classes.linkBtn} to="/missed_money_month">Missed - Monthly</Link>
        </Button>
        <Button gold sx={{ margin: '15px' }}>
          <Link className={classes.linkBtn} to="/missed_money_month_pillar">Missed - Monthly & Pillar</Link>
        </Button>
      </div>
    </div>
  );
};

export default MissedMoney;
