import React, { useState } from 'react';
import { forEach, get } from 'lodash';
import { XMLParser } from 'fast-xml-parser';
// import useSWR from 'swr';

import Paper from '@mui/material/Paper';

import { DataGrid } from '@mui/x-data-grid';

import makeStyles from '@mui/styles/makeStyles';
import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';
import { Button } from '../lib/common';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  fileLabel: {
    background: '#1d428a',
    color: 'white',
    padding: '6px 16px',
    borderRadius: '4px',
    fontWeight: '500',
    lineHeight: '1.75',
    cursor: 'pointer',
    fontFamily: 'Libre Baskerville,Times New Roman,Times,serif',
    textTransform: 'uppercase',
    fontSize: '12px',
    boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    margin: '20px',
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'name',
    headerName: 'Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'typeName',
    headerName: 'Type',
    align: 'left',
    width: 150,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.amount ? Number(params.row.amount).toFixed(2) : ''}</span>;
    },
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'left',
    width: 150,
  },
];

const parser = new XMLParser();

const UploadXML = () => {
  const classes = useStyles();
  const [err] = useState(null);
  const [records, setRecords] = useState([]);

  const handleClearPreviousFile = (e) => {
    e.target.value = null;
  };

  const handleMarkPaid = async () => {
    const newRecs = [];
    for (const r of records) {
      try {
        if (r.type === '1') {
          const p = await apiFetch(`/payments/${r.dataId}`);
          if (p.status === 'PAID') {
            r.status = 'ALREADY PAID';
          } else {
            await apiFetch(`/payments/${r.dataId}`, {
              method: 'PUT',
              body: {
                status: 'PAID',
                bsf_id: p.bsf_id,
                eca_id: p.eca_id,
                agent: p.agent,
              },
            });
            r.status = 'PAID';
          }
        } else if (r.type === '3') {
          const p = await apiFetch(`/sh_portion_payments/${r.dataId}`);
          if (p.status === 'PAID') {
            r.status = 'ALREADY PAID';
          } else {
            await apiFetch(`/sh_portion_payments/${r.dataId}`, {
              method: 'PUT',
              body: {
                status: 'PAID',
              },
            });
            r.status = 'PAID';
          }
        } else {
          const p = await apiFetch(`/override_payments/${r.dataId}`);
          if (p.status === 'PAID') {
            r.status = 'ALREADY PAID';
          } else {
            await apiFetch(`/override_payments/${r.dataId}`, {
              method: 'PUT',
              body: {
                status: 'PAID',
                agent: p.agent,
              },
            });
            r.status = 'PAID';
          }
        }
      } catch (e) {
        r.status = e.toString();
      }
      newRecs.push(r);
    }
    setRecords(newRecs);
  };

  const handleAddFileContentsToState = (fileContents) => {
    // console.log('fc', fileContents);
    const jsobj = parser.parse(fileContents);
    let rawRecs = get(jsobj, 'CrystalReport.Group.Group.Group.Details');
    if (!Array.isArray(rawRecs)) {
      rawRecs = [rawRecs];
    }
    const recs = rawRecs.map((rr, idx) => {
      const rec = {
        name: rr.Section[0].Text[0].TextValue,
        amount: rr.Section[0].Text[2].TextValue,
        id: idx,
      };
      rec.amount = Number(String(rec.amount).replace(',', ''));
      rec.type = String(rr.Section[1].Field.Value).substring(0, 1);
      if (rec.type === '1') {
        rec.typeName = 'Standard Commission';
      } else if (rec.type === '3') {
        rec.typeName = 'SH Portion';
      } else {
        rec.typeName = 'Override';
      }
      rec.dataId = String(rr.Section[1].Field.Value).substring(1);
      return rec;
    });
    setRecords(recs);
  };

  const onChangeFile = (e) => {
    e.persist();
    const { files } = e.target;
    // Forces onChange to get called again if same file is resubmitted
    if (files && files.length) {
      // setAllValid(true);
      forEach(files, (f) => {
        // eslint-disable-next-line no-undef
        const reader = new FileReader();
        reader.onload = (readerEvent) => handleAddFileContentsToState(readerEvent.target.result);
        reader.readAsText(f);
      });
    }
  };

  // const { data: eca, loading: ecaLoading } = useSWR('/eca?orderBy=statement_date&limit=5000');

  return (
    <Layout headerTitle="XML Records">
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        <Paper>
          <div className={classes.gridContainer}>
            <DataGrid columns={columns} rows={records || []} />
          </div>
        </Paper>
        <div
          style={{ marginTop: '20px' }}
        >
          <label
            className={classes.fileLabel}
            htmlFor="xmlupload"
          >
            <input
              type="file"
              id="xmlupload"
              style={{ display: 'none' }}
              onClick={handleClearPreviousFile}
              onChange={onChangeFile}
            />
            Upload XML
          </label>
          {records && records.length ? (
            <Button
              color="primary"
              component="label"
              onClick={handleMarkPaid}
              style={{
                marginLeft: '15px',
              }}
            >
              Mark as paid
            </Button>
          ) : ''}
        </div>
      </div>
    </Layout>
  );
};

export default UploadXML;
