import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import useSWR from 'swr';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid } from '@mui/x-data-grid';

import makeStyles from '@mui/styles/makeStyles';

import DownloadButton from '../components/download-csv';
import Layout from '../components/layout';

import { apiFetch } from '../lib/fetch';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'household_agent',
    headerName: 'Household Agent',
    align: 'left',
    width: 300,
  },
  {
    field: 'share_agent',
    headerName: 'Share Agent',
    align: 'left',
    width: 300,
  },
  {
    field: 'invite',
    headerName: 'Method',
    align: 'left',
    width: 100,
    renderCell: (params) => {
      if (params.row.invite) {
        return 'invited';
      }
      return 'requested';
    },
  },
  {
    field: 'household_name',
    headerName: 'Household',
    align: 'right',
    width: 150,
  },
  {
    field: 'created_at',
    headerName: 'created',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return moment(params.row.created_at).format('YYYY-MM-DD');
    },
  },
];

const ShareRequests = () => {
  const classes = useStyles();
  const params = useParams();
  const { agentId } = params;
  const [err] = useState(null);
  const [agent, setAgent] = useState(null);
  let url = '/agent_household_share_requests';

  if (agentId) {
    url += `?agent_id=${agentId}`;
  }

  useEffect(async () => {
    if (agentId) {
      const res = await apiFetch(`/users/${agentId}/stats`);
      setAgent(res);
    }
  }, []);

  const { data, loading } = useSWR(url);

  if (data) {
    data.forEach((d) => {
      d.household_agent = `${d.owner_firstname} ${d.owner_lastname} #${d.owner_num}`;
      d.share_agent = `${d.share_firstname} ${d.share_lastname} #${d.share_num}`;
    });
  }

  const title = `Share Requests / Invites${agent ? ` for ${agent.firstname} ${agent.lastname} #${agent.num}` : ''}`;
  return (
    <Layout headerTitle={title}>
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        {data && data.length ? (<DownloadButton rows={data} name="share_requests"/>) : ''}
        <Paper>
          {!loading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={data || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default ShareRequests;
