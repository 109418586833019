import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import { Link, useParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

import { colors } from '../lib/styles';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const UserItem = (props) => {
  return (
    <TreeItem
      nodeId={props.id}
      label={<Typography variant="subtitle1">{`${props.firstname} ${props.lastname} #${props.num}`}</Typography>}
      sx={{ margin: '10px' }}
    >
      <div>
        <Typography variant="body1">
          <Link style={{ margin: '5px', color: colors.darkAccent }} to={`/users/${props.id}/edit`}>Edit</Link>
        </Typography>
        <Typography variant="body1">
          <Link style={{ margin: '5px', color: colors.darkAccent }} to={`/users/${props.id}`}>BSFs</Link>
        </Typography>
        <Typography variant="body1">
          <Link style={{ margin: '5px', color: colors.darkAccent }} to={`/network/${props.id}`}>Network</Link>
        </Typography>
      </div>
      {props.children.map((c) => {
        return <UserItem {...c} />;
      })}
    </TreeItem>
  );
};

const Network = () => {
  const classes = useStyles();
  const params = useParams();
  const { userId } = params;
  const [err] = useState(null);
  const [users, setUsers] = useState(null);
  useEffect(async () => {
    if (userId) {
      const userList = await apiFetch(`/users/${userId}/tree`);
      setUsers(userList);
    }
  }, []);

  let topUser;

  if (users) {
    const userMap = {};
    users.forEach((u) => {
      u.children = [];
      userMap[u.id] = u;
      if (u.id === userId) {
        topUser = u;
      }
    });
    users.forEach((u) => {
      if (u.parent_id && userMap[u.parent_id]) {
        userMap[u.parent_id].children.push(u);
      }
    });
  }

  return (
    <Layout headerTitle="Network">
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        <Paper>
          {topUser ? (
            <div className={classes.gridContainer}>
              <TreeView
                aria-label="file system navigator"
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                sx={{
                  flexGrow: 1,
                  overflowY: 'auto',
                  color: colors.darkAccent,
                }}
              >
                <UserItem {...topUser}/>
              </TreeView>
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default Network;
