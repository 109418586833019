import React, { useState, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { apiFetch } from '../lib/fetch';
import { Select } from '../lib/common';
import { colors, Button } from '../lib/styles';
import DownloadButton from '../components/download-csv';
import Layout from '../components/layout';

const DEFAULT_URL = '/bsf?orderBy=submitted_at&limit=5000';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px auto',
    minWidth: '300px',
    width: '80%',
  },
  orgs: {
    margin: '10px auto',
    minWidth: '350px',
    width: '100%',
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
  w200: {
    width: '200px',
    margin: '10px',
  },
  linkBtn: {
    textDecoration: 'none',
    color: colors.black,
  },
});

const cleanData = (data, filtered = false) => {
  if (filtered) {
    return data?.map((b, id) => ({
      ...b,
      id,
      aum: Number(b.aum),
      annuity: Number(b.annuity),
      first_distribution: Number(b.first_distribution),
      life_target: Number(b.life_target),
      life_annual: Number(b.life_annual),
      count: Number(b.count),
      cbasis: Number(b.cbasis),
    }));
  }
  return data.map((b, id) => ({
    ...b,
    id: b.id || id,
  }));
};

const BSFs = () => {
  const classes = useStyles();
  const { userId } = useParams();

  const [url, setUrl] = useState(DEFAULT_URL);
  const [user, setUser] = useState(null);
  const [err] = useState(null);
  const [type, setType] = useState('all');
  const [personal, setPersonal] = useState('all');
  const [status, setStatus] = useState('all');
  const [joint, setJoint] = useState('all');
  const [productTypeId, setProductTypeId] = useState('all');
  const [effective_entered, setEffectiveEntered] = useState('all');
  const [matchId, setMatchId] = useState(null);
  const [matchResults, setMatchResults] = useState(null);
  const [cols, setCols] = useState([]);
  const [csvName, setCsvName] = useState('bsfs');
  const [filter, setFilter] = useState('all');

  const { data: bsfs, loading: isValidating } = useSWR(url);
  const { data: productTypes } = useSWR('/product_types?orderBy=name&sortOrder=asc');

  const cleanBsfs = useMemo(() => cleanData(bsfs || [], filter !== 'all'), [bsfs, filter]);

  const columns = [
    {
      field: 'type',
      headerName: 'Type',
      align: 'left',
      width: 100,
    },
    {
      field: 'carrier_name',
      headerName: 'Carrier',
      align: 'left',
      width: 150,
    },
    {
      field: 'cfirstname',
      headerName: 'Client F. Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'clastname',
      headerName: 'Client L. Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'product_type_name',
      headerName: 'Product',
      align: 'left',
      width: 150,
    },
    {
      field: 'policy_number',
      headerName: 'Policy #',
      align: 'left',
      width: 170,
    },
    {
      field: 'firstname',
      headerName: 'Agent F. Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'lastname',
      headerName: 'Agent L. Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'joint',
      headerName: 'Joint',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (params.row.joint) {
          return 'Y';
        }
        return 'N';
      },
    },
    {
      field: 'personal',
      headerName: 'Personal',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (params.row.personal) {
          return 'Y';
        }
        return 'N';
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      align: 'left',
      width: 200,
      renderCell: (params) => {
        if (params.row.status === 'OPEN') {
          return <span>APPROVED</span>;
        }
        return <span>{params.row.status}</span>;
      },
    },
    {
      field: 'details',
      headerName: 'Detail',
      align: 'left',
      width: 200,
      renderCell: (params) => {
        return <Link style={{ color: colors.darkAccent }} to={`/bsfs/${params.row.id}`}>Details</Link>;
      },
    },
    {
      field: 'created_at',
      headerName: 'created',
      alight: 'right',
      width: 100,
      renderCell: (params) => {
        return moment(params.row.created_at).format('YYYY-MM-DD');
      },
    },
    {
      field: 'submitted_at',
      headerName: 'effective',
      alight: 'right',
      width: 100,
      renderCell: (params) => {
        if (params.row.submitted_at) {
          return moment(params.row.submitted_at).format('YYYY-MM-DD');
        }
        return '';
      },
    },
    {
      field: 'match',
      headerName: 'Match',
      align: 'left',
      width: 100,
      renderCell: (params) => {
        if (params.row.status === 'CLOSED') {
          return '';
        }

        return (
          <Button
            onClick={async () => {
              const { id } = params.row;
              setMatchId(id);
              setMatchResults(null);
              try {
                setMatchResults(await apiFetch(`/bsf/${id}/checkECA`, { body: {}, method: 'PUT' }));
              } catch (e) {
                setMatchResults({ msg: e.message });
              }
            }}
          >
            Match ECA
          </Button>
        );
      },
    },
  ];

  const filteredColumns = (filter) => [
    {
      field: 'firstname',
      headerName: 'First Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'lastname',
      headerName: 'Last Name',
      align: 'left',
      width: 150,
    },
    {
      field: 'num',
      headerName: 'Agent #',
      align: 'left',
      width: 150,
    },
    {
      field: 'count',
      headerName: 'Count',
      align: 'right',
      width: 100,
      renderCell: (params) => {
        return (
          <Link style={{ color: colors.darkAccent }} to={`/users/${params.row.agent}/bsfs`}>
            {params.row.count}
          </Link>
        );
      },
    },
    {
      field: 'first_distribution',
      headerName: 'First Dist.',
      align: 'right',
      width: 140,
      renderCell: (params) => {
        return <span>${Number(params.row.first_distribution).toFixed(2)}</span>;
      },
    },
    {
      field: 'life_target',
      headerName: 'Target (life)',
      align: 'right',
      width: 140,
      renderCell: (params) => {
        return <span>${Number(params.row.life_target).toFixed(2)}</span>;
      },
    },
    {
      field: 'life_annual',
      headerName: 'Prem. (annuity)',
      align: 'right',
      width: 140,
      renderCell: (params) => {
        return <span>${Number(params.row.life_annual).toFixed(2)}</span>;
      },
    },
    {
      field: 'cbasis',
      headerName: 'Contract Basis',
      align: 'right',
      width: 160,
      renderCell: (params) => {
        return <span>${Number(params.row.cbasis).toFixed(2)}</span>;
      },
    },
    {
      field: 'initial_funding_amount',
      headerName: 'Initial Funding',
      align: 'right',
      width: 160,
      renderCell: (params) => {
        return <span>${Number(params.row.initial_funding_amount).toFixed(2)}</span>;
      },
    },
    {
      field: 'personal',
      headerName: 'Personal',
      align: 'right',
      width: 90,
      renderCell: (params) => {
        if (params.row.personal) {
          return 'Y';
        }
        return 'N';
      },
    },
    {
      field: 'created',
      headerName: filter,
      align: 'right',
      width: 150,
      renderCell: (params) => {
        return <span>{params.row.created ? params.row.created.substring(0, 10) : ''}</span>;
      },
    },
  ];

  useEffect(() => {
    switch (filter) {
      case 'all':
        if (userId) {
          setUrl(`/users/${userId}${DEFAULT_URL}`);
        } else {
          setUrl(DEFAULT_URL);
        }
        setCols(columns);
        setCsvName('bsfs');
        break;
      case 'week':
      case 'month':
      case 'quarter':
      case 'year':
        if (userId) {
          setUrl(`/bsf/${filter}?orderBy=created&limit=5000&agent=${userId}`);
        } else {
          setUrl(`/bsf/${filter}?orderBy=created&limit=5000`);
        }
        setCols(filteredColumns(filter));
        setCsvName(`bsfs-${filter}ly`);
        break;
      default:
        break;
    }
  }, [filter]);

  useEffect(() => {
    const params = {
      personal, status, joint, type, productTypeId, effective_entered,
    };

    if (filter === 'all') {
      let update = '';
      for (const param in params) {
        if (params[param] !== 'all') {
          if (param === 'productTypeId') {
            update += `&product_type_id=${params[param]}`;
          } else {
            update += `&${param}=${params[param]}`;
          }
        }
      }
      if (userId) {
        setUrl(`/users/${userId}${DEFAULT_URL}${update}`);
      } else {
        setUrl(`${DEFAULT_URL}${update}`);
      }
    }
  }, [filter, personal, status, joint, type, productTypeId, effective_entered]);

  useEffect(async () => {
    if (userId) {
      const res = await apiFetch(`/users/${userId}`);
      setUser(res);
      setUrl(`/users/${userId}${DEFAULT_URL}`);
    }
  }, []);

  const loading = isValidating || !cleanBsfs || !columns;

  return (
    <Layout headerTitle={
      `${user ? `BSFs for ${user.firstname} ${user.lastname} #${user.num}` : 'Business Submission Forms'}`
    }
    >
      {
        loading ? (
          <Box sx={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: '900px',
          }}
          >
            <CircularProgress size={100} thickness={2} />
          </Box>
        ) : (
          <div className={classes.container}>
            <div>{err?.toString()}</div>
            <Box display="grid" gridTemplateColumns="repeat(21, 1fr)" gap={2} mb={1} mt={3} sx={{ width: '100%' }}>
              <Box gridColumn="span 3">
                <Select
                  value={filter}
                  name="filter"
                  onChange={(evt) => {
                    setFilter(evt.target.value);
                  }}
                >
                  <MenuItem value="all">All BSFs</MenuItem>
                  <MenuItem value="week">BSFs by week</MenuItem>
                  <MenuItem value="month">BSFs by month</MenuItem>
                  <MenuItem value="quarter">BSFs by quarter</MenuItem>
                  <MenuItem value="year">BSFs by year</MenuItem>
                </Select>
              </Box>
              <Box gridColumn="span 18" />
              {
                filter === 'all' ? (
                  <>
                    <Box gridColumn="span 3">
                      <Select
                        label="Type"
                        value={type}
                        name="type"
                        onChange={(evt) => {
                          setType(evt.target.value);
                        }}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="LIFE">Life</MenuItem>
                        <MenuItem value="ANNUITY">Annuity</MenuItem>
                        <MenuItem value="HEDGEHOG">Hedgehog</MenuItem>
                        <MenuItem value="LANDING_ROCK">Landing Rock</MenuItem>
                      </Select>
                    </Box>
                    <Box gridColumn="span 3">
                      <Select
                        label="Personal"
                        value={personal}
                        name="personal"
                        onChange={(evt) => {
                          setPersonal(evt.target.value);
                        }}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="true">Personal</MenuItem>
                        <MenuItem value="false">Non-Personal</MenuItem>
                      </Select>
                    </Box>
                    <Box gridColumn="span 3">
                      <Select
                        label="Status"
                        value={status}
                        name="status"
                        onChange={(evt) => {
                          setStatus(evt.target.value);
                        }}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="OPEN">Open</MenuItem>
                        <MenuItem value="CLOSED">Closed</MenuItem>
                        <MenuItem value="PENDING_AGENTS">Pending Agents</MenuItem>
                      </Select>
                    </Box>
                    <Box gridColumn="span 3">
                      <Select
                        label="Splits?"
                        value={joint}
                        name="joint"
                        onChange={(evt) => {
                          setJoint(evt.target.value);
                        }}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="true">Joint</MenuItem>
                        <MenuItem value="false">Single Agent</MenuItem>
                      </Select>
                    </Box>
                    <Box gridColumn="span 3">
                      <Select
                        label="Effective?"
                        value={effective_entered}
                        name="effective_entered"
                        onChange={(evt) => {
                          setEffectiveEntered(evt.target.value);
                        }}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="EMPTY">Empty</MenuItem>
                      </Select>
                    </Box>
                    {
                      productTypes ? (
                        <Box gridColumn="span 3">
                          <Select
                            label="Product"
                            value={productTypeId}
                            name="joint"
                            onChange={(evt) => {
                              setProductTypeId(evt.target.value);
                            }}
                          >
                            <MenuItem value="all">All</MenuItem>
                            {productTypes.map((pt) => {
                              return (<MenuItem value={pt.id} key={pt.id}>{pt.name}</MenuItem>);
                            })}
                          </Select>
                        </Box>
                      )
                        : ''
                    }
                  </>
                ) : (
                  <Box gridColumn="span 3">
                    <Select
                      label="Personal"
                      value={personal}
                      name="personal"
                      onChange={(evt) => {
                        setPersonal(evt.target.value);
                      }}
                    >
                      <MenuItem value="all">All</MenuItem>
                      <MenuItem value="true">Personal</MenuItem>
                      <MenuItem value="false">Non-Personal</MenuItem>
                    </Select>
                  </Box>
                )
              }
              <Box gridColumn="span 3" sx={{ display: 'flex' }}>
                {cleanBsfs && cleanBsfs.length ? (
                  <Box gridColumn="span 3" sx={{ display: 'flex', alignItems: 'center' }}>
                    <DownloadButton rows={cleanBsfs} name={csvName} style={{ color: colors.darkAccent }}/>
                  </Box>
                ) : ''}
              </Box>
            </Box>
            <Paper>
              <div className={classes.gridContainer}>
                <DataGrid columns={cols} rows={cleanBsfs || []} />
              </div>
            </Paper>
          </div>
        )
      }
      <Dialog open={!!matchId}>
        <div style={{ margin: '10px' }}>
          <DialogTitle style={{ color: 'black' }}>Match BSF/ECA</DialogTitle>
          {matchResults ? (
            <div>
              <div>{matchResults.msg ? matchResults.msg : ''}</div>
              <div>
                <Typography variant="h6" style={{ color: 'black' }}>ECA</Typography>
                <div>Policy: {matchResults.eca?.policy_number}</div>
                <div>Carrier: {matchResults.eca?.carrier}</div>
              </div>
              <div>
                <Typography variant="h6" style={{ color: 'black' }}>BSF</Typography>
                <div>Policy: {matchResults.bsf?.policy_number}</div>
                <div>Carrier: {matchResults.bsf?.carrier_code}</div>
              </div>
            </div>
          ) : ''}
          <div>
            <Button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                setMatchId(null);
                setMatchResults(null);
              }}
              style={{ margin: '10px' }}
            >
              Ok
            </Button>
          </div>
        </div>
      </Dialog>
    </Layout>
  );
};

export default BSFs;
