import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import useSWR from 'swr';

import { colors } from '../lib/styles';
import ConfigTypesLayout from '../components/config-types-layout';

const ClientTermInsuranceTypes = () => {
  const columns = [
    {
      field: 'name',
      headerName: 'Client Term Insurance Type',
      align: 'left',
      width: 250,
    },
    {
      field: 'active',
      headerName: 'Active',
      align: 'right',
      width: 100,
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
      width: 100,
    },
  ];

  const { data: insuranceTypes, isValidating } = useSWR('/client_institutions');
  let rows = [];
  if (insuranceTypes) {
    rows = insuranceTypes.map((row) => {
      return {
        id: row.id,
        active: capitalize(String(row.active)),
        name: row.name,
        order_val: row.order_val,
        edit: (
          <Link
            to={`/client_term_insurance_types/${row.id}/edit`}
            style={{ color: colors.darkAccent }}
          >
            Edit
          </Link>
        ),
      };
    });
  }

  return (
    <ConfigTypesLayout
      rows={rows}
      isValidating={isValidating}
      columns={columns}
      title="Client Insurance Type"
      path="client_term_insurance_types"
    />
  );
};

export default ClientTermInsuranceTypes;
