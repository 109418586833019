import React from 'react';
import { useHistory } from 'react-router';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

import { apiFetch } from '../lib/fetch';
import { colors, Button } from '../lib/styles';

const ClientHoldingTypeForm = (props) => {
  const {
    client_holding_type,
    isNew,
    handleTextChange,
    handleToggleChange,
  } = props;

  const {
    name,
    active,
    taxable,
    tax_deferred,
    tax_free,
    annuity,
  } = client_holding_type;

  function Toggle(props) {
    return (
      <div>
        <FormControlLabel
          sx={{ color: colors.darkAccent }}
          control={(
            <Switch
              defaultChecked
              checked={props.value}
              name={props.name}
              onChange={handleToggleChange}
            />
        )}
          label={props.label}
          labelPlacement="start"
        />
      </div>
    );
  }

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      id,
      name,
      active,
      taxable,
      tax_deferred,
      tax_free,
      annuity,
    } = client_holding_type;

    const data = {
      name,
      active,
      taxable,
      tax_deferred,
      tax_free,
      annuity,
    };

    if (isNew) {
      apiFetch('/client_holding_types', {
        method: 'POST',
        body: data,
      });
    } else {
      apiFetch(`/client_holding_types/${id}`, {
        method: 'PUT',
        body: data,
      });
    }

    history.goBack();
  };

  return (
    <Box
      component="form"
      sx={{
        display: 'grid',
        width: '80%',
        margin: 'auto',
        paddingTop: '30px',
        '& .MuiTextField-root': { m: 2 },
      }}
      onSubmit={handleSubmit}
    >
      <FormControl
        sx={{ flexDirection: 'column' }}
        component="fieldset"
        variant="standard"
      >
        <TextField
          id="name"
          name="name"
          label="Client Holding Type Name"
          value={name}
          onChange={handleTextChange}
        />
        <Box
          sx={{
            m: 2,
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }}
        >
          <Toggle
            name="taxable"
            value={taxable}
            label="Taxable"
          />
          <Toggle
            name="tax_deferred"
            value={tax_deferred}
            label="Tax Deferred"
          />
          <Toggle
            name="tax_free"
            value={tax_free}
            label="Tax Free"
          />
          <hr width="80%"/>
          <Toggle
            name="annuity"
            value={annuity}
            label="Is an Annuity?"
          />
          <hr width="80%"/>
          <Toggle
            name="active"
            value={active}
            label="Active"
          />
          <div style={{ margin: '10px' }}>
            <Button gold type="submit" title="Save" />
          </div>
        </Box>
      </FormControl>
    </Box>
  );
};

export default ClientHoldingTypeForm;
