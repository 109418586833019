import React, { useState, useEffect } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Paper from '@mui/material/Paper';
import FolderIcon from '@mui/icons-material/Folder';
import { sortBy } from 'lodash';

import { apiFetch } from '../lib/fetch';

import { FolderItem } from './file-tree';

const useStyles = makeStyles({
  gridContainer: {
    width: '100%',
    display: 'flex',
    padding: '5px',
    flexDirection: 'column',
  },
  fileBox: {
    width: '100%',
    margin: '5px',
    border: '1px solid black',
    borderRadius: '3px',
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  emptyGridContainer: {
    width: '100%',
    display: 'flex',
    height: 50,
    padding: 10,
  },
  submitBtn: {
    margin: '15px',
  },
  fileHeader: {
    display: 'flex',
    fontSize: '1.1rem',
    alignItems: 'center',
    cursor: 'pointer',
  },
  errorText: {
    color: 'red',
    fontSize: '1.1rem',
    fontWeight: 'bold',
  },
});

const DocList = (props) => {
  const {
    agent_visible,
    agent_id,
    label,
  } = props;
  const classes = useStyles();
  // const [uploading, setUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [error, setError] = useState(null);

  const folderMaps = {};
  const allFileMap = {};

  const sortedFiles = sortBy(files, ['name']);
  // const topLevelFolders = sortedFiles.filter((f) => !f.parent_id && f.folder);
  const rf = {
    name: label,
    id: 0,
    nodeId: 'n0',
    children: [],
    folder: true,
    rootFolder: true,
    files: [],
    agent_id,
  };

  sortedFiles.forEach((f) => {
    f.nodeId = 'n' + f.id;
    if (f.folder) {
      f.children = [];
      // folderMap['n' + f.id] = f;
      if (folderMaps[f.agent_id]) {
        folderMaps[f.agent_id]['n' + f.id] = f;
      }
      f.files = [];
    }
    allFileMap[f.nodeId] = f;
  });

  sortedFiles.forEach((f) => {
    // const parent = folderMap['n' + f.parent_id];
    // const folderMap = folderMaps[f.agent_id];
    // const parent = folderMap ? folderMap['n' + f.parent_id] : null;
    const parent = allFileMap['n' + f.parent_id];
    if (f.folder && parent && parent.folder) {
      parent.children.push(f);
    } else if (!parent && f.folder && f.parent_id) {
      // console.log('how did this happen?', f);
      // if (rootFileMap[f.agent_id] && rootFileMap[f.agent_id].children) {
      //   rootFileMap[f.agent_id].children.push(f);
      // }
      // if (allFileMap['n0']) {
      //   allFileMap['n0'].children.push(f);
      // }
    } else if (parent && !f.folder) {
      parent.files.push(f);
    } else if (!parent && !f.folder) {
      // console.log('ok how did this happen?', f);
      // if (rootFileMap[f.agent_id] && rootFileMap[f.agent_id].files) {
      //   rootFileMap[f.agent_id].files.push(f);
      // }
    } else if (!parent && f.folder) {
      rf.children.push(f);
    }
  });

  async function saveFolder(name, parent_id) {
    try {
      setError(null);
      const path = '/agent_files/folders';
      const toSave = {
        name,
        agent_visible,
        agent_id,
      };
      if (parent_id) {
        toSave.parent_id = parent_id;
      }
      const newFile = await apiFetch.post(path, toSave);
      const filesClone = files.slice(0);
      filesClone.push(newFile);
      setFiles(filesClone);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      setError(e.message);
    }
  }

  async function delFile(fileId) {
    try {
      setError(null);
      const path = `/agent_files/f/${fileId}`;
      await apiFetch.del(path);
      const filesClone = [];
      files.forEach((f) => {
        if (f.id !== fileId) {
          filesClone.push(f);
        }
      });
      setFiles(filesClone);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      setError(e.message);
    }
  }

  async function renameFolder(fileId, newName) {
    try {
      setError(null);
      const path = `/agent_files/${fileId}`;
      await apiFetch.put(path, { name: newName, agent_id });
      const filesClone = [];
      files.forEach((f) => {
        if (f.id === fileId) {
          f.name = newName;
        }
        filesClone.push(f);
      });
      setFiles(filesClone);
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      setError(e.message);
    }
  }

  async function resolvePreFunc(toResolve) {
    toResolve.agent_visible = agent_visible;
    const result = await apiFetch.post(`/agent_files/${agent_id}/resolve/pre`, toResolve);
    return result;
  }

  useEffect(async () => {
    const path = `/agent_files?agent_visible=${agent_visible}&agent_id=${agent_id}`;
    const savedFiles = await apiFetch(path);
    setFiles(savedFiles);
  }, []);

  return (
    <div>
      {error ? (
        <div className={classes.errorText}>{String(error)}</div>
      ) : ''}
      <div>
        <div
          style={{ width: '100%' }}
        >
          <FolderItem
            type="agent"
            agent_visible={agent_visible}
            depth={1}
            key={rf.id}
            record={rf}
            nodeId={rf.nodeId}
            basePath={`agent_files/${agent_id}`}
            agent_id={rf.agent_id}
            labelIcon={<FolderIcon/>}
            renameFunction={(fileId, name) => {
              renameFolder(fileId, name);
            }}
            handleDelete={(id) => {
              delFile(id);
            }}
            saveFolder={(name, parent_id) => {
              saveFolder(name, parent_id);
            }}
            renameFolder={(fileId, agentId, name) => {
              renameFolder(fileId, agentId, name);
            }}
            deleteFolder={(fileId) => {
              delFile(fileId);
            }}
            handleFileAdded={(newFile) => {
              const filesClone = files.slice(0);
              filesClone.push(newFile);
              setFiles(filesClone);
            }}
            resolvePreFunc={(toResolve) => {
              return resolvePreFunc(toResolve);
            }}
            setError={setError}
          />
        </div>
      </div>
    </div>
  );
};

const AgentFiles = (props) => {
  const { agent_id, agent } = props;
  const classes = useStyles();

  return (
    <>
      <Paper>
        <div className={classes.gridContainer}>
          <DocList
            agent_id={agent_id}
            agent_visible={false}
            agent={agent}
            label="Admin Only Viewing Rights(No agent Access)"
          />
          <DocList
            agent_id={agent_id}
            agent_visible={true}
            agent={agent}
            label="Agent Has Viewing Rights"
          />
        </div>
      </Paper>
    </>
  );
};

export default AgentFiles;
