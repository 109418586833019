import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import makeStyles from '@mui/styles/makeStyles';

import { DataGrid } from '@mui/x-data-grid';
import { apiFetch } from '../lib/fetch';

import Layout from '../components/layout';
import DownloadButton from '../components/download-csv';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  {
    field: 'from_num',
    headerName: 'From Agent #',
    align: 'right',
    width: 100,
  },
  {
    field: 'from_firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'from_lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'tier',
    headerName: 'Pillar',
    align: 'right',
    width: 75,
  },
  {
    field: 'amount',
    headerName: 'Amount',
    align: 'right',
    width: 130,
    renderCell: (params) => {
      return <span>{Number(params.row.amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'max_amount',
    headerName: 'Max Amount (qualified)',
    align: 'right',
    width: 200,
    renderCell: (params) => {
      return <span>{Number(params.row.max_amount).toFixed(2)}</span>;
    },
  },
  {
    field: 'qualify',
    headerName: 'Qualified?',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      const style = {};
      if (!params.row.qualify) {
        style.color = 'red';
      }
      return <span style={style}>{params.row.qualify ? 'true' : 'false'}</span>;
    },
  },
  {
    field: 'statement_date',
    headerName: 'Month',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <span>{params.row.statement_date ? params.row.statement_date.substring(0, 7) : ''}</span>;
    },
  },
];

const StrongholdPayments = () => {
  const classes = useStyles();
  const params = useParams();
  const { agent_id } = params;
  const [err] = useState(null);
  const [agent, setAgent] = useState(null);
  const [details, setDetails] = useState([]);
  useEffect(async () => {
    if (agent_id) {
      const a = await apiFetch(`/users/${agent_id}`);
      const od = await apiFetch(`/override_details?to_agent=${agent_id}&orderBy=created_at&limit=5000`);
      setAgent(a);
      setDetails(od);
    }
  }, []);

  details.forEach((d) => {
    d.amount = Number(d.amount);
    d.max_amount = Number(d.max_amount);
  });

  const filename = !agent ? `override_details_to_${agent_id}`
    : `override_details_to_${agent.firstname}_${agent.lastname}_${agent_id}`;

  return (
    <Layout headerTitle="Override Details">
      <div className={classes.mainBody}>
        <div>{err?.toString()}</div>
        <div>
          {agent ? (
            <Typography variant="h5">
              To Agent: {`${agent.firstname} ${agent.lastname} #${agent.num}`}
            </Typography>
          ) : ''}
        </div>
        <Paper>
          {details && details.length ? (<DownloadButton rows={details} name={filename}/>) : ''}
          {agent ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={details || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default StrongholdPayments;
