import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';

import TrustedAdvisorsForm from '../components/trusted_advisors-form';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const TrustedAdvisorType = () => {
  const [isNew, setIsNew] = useState(false);
  const params = useParams();
  const location = useLocation();

  const { trustedAdvisorId } = params;
  const [trustedAdvisor, setTrustedAdvisorType] = useState({
    name: '',
    active: true,
  });

  function findTrustedAdvisorType(id) {
    return apiFetch(`/trusted_advisor_types/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    setIsNew(location.pathname === '/trusted_advisors/new');
    if (trustedAdvisorId) {
      const trustedAdvisor = await findTrustedAdvisorType(trustedAdvisorId);
      setTrustedAdvisorType(trustedAdvisor);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setTrustedAdvisorType({ ...trustedAdvisor, [name]: value });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setTrustedAdvisorType({ ...trustedAdvisor, [name]: checked });
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="Trusted Advisor Form">
      {
        location.pathname === '/trusted_advisors/new' || trustedAdvisor ? (
          < TrustedAdvisorsForm
            trustedAdvisor={trustedAdvisor}
            isNew={isNew}
            handleTextChange={handleTextChange}
            handleToggleChange={handleToggleChange}
          />
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress size={100} />
          </div>
        )
      }
    </Layout>
  );
};

export default TrustedAdvisorType;
