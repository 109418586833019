import React from 'react';

function UserHeader(props) {
  const { user, style } = props;
  const dispStyle = {
    color: 'white',
    margin: '10px',
    ...(style || {}),
  };
  return (
    <div style={dispStyle}>
      <h2>{user.firstname} {user.lastname} #{user.num}</h2>
    </div>
  );
}

export default UserHeader;
