import { apiFetch } from './fetch';

let columns;

export async function getColumnLabels() {
  if (!columns) {
    columns = await apiFetch('/columns/labels');
  }
  return columns;
}
