import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';

import CarrierForm from '../components/carrier-form';

import { apiFetch } from '../lib/fetch';
import Layout from '../components/layout';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const Carrier = () => {
  const [isNew, setIsNew] = useState(false);
  const params = useParams();
  const location = useLocation();

  const { carrierId } = params;
  const [carrier, setCarrier] = useState({
    name: '',
    code: '',
    active: true,
  });

  function findCarrier(id) {
    return apiFetch(`/carriers/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    setIsNew(location.pathname === '/carriers/new');
    if (carrierId) {
      const carrier = await findCarrier(carrierId);
      setCarrier(carrier);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setCarrier({ ...carrier, [name]: value });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setCarrier({ ...carrier, [name]: checked });
  };

  const classes = useStyles();

  return (
    <Layout headerTitle="Carrier Type Form">
      {
        location.pathname === '/carriers/new' || carrier ? (
          < CarrierForm
            carrier={carrier}
            isNew={isNew}
            handleTextChange={handleTextChange}
            handleToggleChange={handleToggleChange}
          />
        ) : (
          <div className={classes.loadingCircle}>
            <CircularProgress size={100} />
          </div>
        )
      }
    </Layout>
  );
};

export default Carrier;
