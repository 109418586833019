import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  TextField, Card, CardHeader, Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { auth, oauth } from '../state/user';
import { colors, formButton, Button } from '../lib/styles';
import { GoogleButton, MicrosoftButton, startOauth } from '../lib/oAuth';

const styles = {
  container: {
    backgroundAttachment: 'scroll',
    backgroundRepeat: 'repeat',
    height: '100%',
    minHeight: '900px',
    padding: '10px',
  },
  forgotPassword: {
    color: colors.primaryText,
    fontFamily: [
      'Maven Pro, Roboto, sans-serif, Circular Std, sans-serif',
    ].join(','),
  },
  tintedBackground: {
    margin: '0 auto 100px',
    textAlign: 'center',
    width: '500px',
    backgroundColor: colors.blackOpacity,
    color: colors.primaryText,
  },
  errorText: {
    color: colors.secondaryAccent,
    height: '18px',
    paddingTop: '18px',
    margin: '10px',
  },
  form: {
    margin: 'auto',
    padding: '35px 50px 50px',
  },
  loginField: {
    margin: '10px',
  },
  textField: {
    '& label.Mui-focused': {
      color: colors.lightGrey,
    },
    '& .MuiInputLabel-root': {
      color: colors.lightGrey,
    },
    '& .MuiOutlinedInput-input': {
      color: colors.lightGrey,
    },
    '& .MuiOutlinedInput-root': {
      '& > fieldset': {
        borderColor: colors.lightGrey,
        '& .Mui-focused': {
          color: colors.lightGrey,
        },
      },
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      color: colors.lightGrey,
      '& > fieldset': {
        borderColor: colors.lightGrey,
        color: colors.lightGrey,
      },
    },
    '& .MuiOutlinedInput-root:hover': {
      '& > fieldset': {
        borderColor: colors.lightGrey,
        color: colors.lightGrey,
      },
    },
  },
  loginBrand: {
    height: '18px',
    width: '18px',
    marginRight: '15px',
  },
  loginBrandText: {
    fontSize: '1.2em',
    width: '100%',
    textTransform: 'none',
    textAlign: 'left',
  },
  orSpacer: {
    margin: '10px',
    fontSize: '1.2em',
    color: colors.white,
  },
};

const Login = (props) => {
  const [loginError, setLoginError] = useState();
  const [oauthProviders, setOauthProviders] = useState(null);

  useEffect(async () => {
    const result = await startOauth('dlogin');
    setOauthProviders(result);
  }, []);

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Required'),
      password: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const { email, password } = values;

      props.auth(email, password)
        .then(() => {
          props.history.push('/users');
        })
        .catch((e) => {
          setLoginError(e);
        });
    },
  });

  const { user } = props;
  const propErrors = {};
  let errorMsg = '';
  if (user.authError && user.authError.details) {
    user.authError.details.forEach((detail) => {
      propErrors[detail.path] = detail.message;
    });
  }
  const err = user.authError || user.oauthError || loginError;
  if (err) {
    // eslint-disable-next-line
    errorMsg = get(err, 'content.message') || get(err, 'content.error') || err.message;
  }
  return (
    <div style={styles.container} >
      <Card style={styles.tintedBackground}>
        <CardHeader style={styles.cardHeader} title={<Typography variant="h5">Sign In</Typography>} />
        <div style={styles.errorText}>{errorMsg}</div>
        <div style={styles.form}>
          {oauthProviders && Object.keys(oauthProviders).length ? (
            <div style={{ marginBottom: '20px' }}>
              <div>
                {oauthProviders.google ? (
                  <GoogleButton
                    authUrl={oauthProviders?.google?.url}
                    style={{ display: oauthProviders && oauthProviders.google ? '' : 'none' }}
                  />
                ) : ''}
                {oauthProviders.microsoft ? (
                  <MicrosoftButton
                    authUrl={oauthProviders?.microsoft?.url}
                    style={{ display: oauthProviders && oauthProviders.microsoft ? '' : 'none' }}
                  />
                ) : ''}
              </div>
              <div style={styles.orSpacer}>Or:</div>
            </div>
          ) : null}
          <form onSubmit={formik.handleSubmit}>
            <TextField
              style={styles.loginField}
              label="email"
              name="email"
              fullWidth={true}
              error={formik.touched.email && !!formik.errors.email}
              helperText={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              sx={styles.textField}
            />
            <TextField
              style={styles.loginField}
              label="Password"
              name="password"
              fullWidth={true}
              error={formik.touched.password && !!formik.errors.password}
              helperText={formik.touched.password && formik.errors.password}
              value={formik.values.password}
              type="password"
              onChange={formik.handleChange}
              sx={styles.textField}
            />
            <Button
              gold
              type="submit"
              style={formButton}
              title="Sign In"
            />
            <p><Link style={styles.forgotPassword} to="/forgot">Forgot your password?</Link></p>
          </form>
        </div>
      </Card>
    </div>
  );
};

function mapStateToProps(state) {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps, { auth, oauth })(Login);
