import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import useSWR from 'swr';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';

import { DataGrid } from '@mui/x-data-grid';

import makeStyles from '@mui/styles/makeStyles';
import { colors } from '../lib/styles';

import DownloadButton from '../components/download-csv';
import Layout from '../components/layout';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  { field: 'id', hide: true },
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'left',
    width: 150,
  },
  {
    field: 'firstname',
    headerName: 'First',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last',
    align: 'left',
    width: 150,
  },
  {
    field: 'total',
    headerName: 'Total',
    align: 'right',
    width: 150,
  },
  {
    field: 'most_recent',
    headerName: 'Most Recent',
    align: 'right',
    width: 150,
    renderCell: (params) => {
      return <span>{params.row.most_recent ? params.row.most_recent.substring(0, 10) : ''}</span>;
    },
  },
  {
    field: 'agent',
    headerName: 'Details',
    align: 'left',
    width: 100,
    renderCell: (params) => {
      return <Link style={{ color: colors.darkAccent }} to={`/users/${params.row.agent}/chargebacks`}>Details</Link>;
    },
  },
];

const Chargebacks = () => {
  const classes = useStyles();
  const [err] = useState(null);

  const url = '/chargeback_summary?orderBy=firstname&limit=5000';

  const { data: chargebacks, loading: chargebacksLoading } = useSWR(url);
  if (chargebacks && chargebacks.length) {
    chargebacks.forEach((c, idx) => {
      c.id = idx;
    });
  }

  return (
    <Layout headerTitle="Chargeback Summary">
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        {chargebacks && chargebacks.length ? (<DownloadButton rows={chargebacks} name="chargebacks"/>) : ''}
        <Paper>
          {!chargebacksLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={chargebacks || []} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default Chargebacks;
