import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  title: '',
  navbarExpanded: false,
  navbarPinned: false,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    updateTitle: (state, action) => {
      const { payload } = action;

      state.title = payload.title;
    },
    expandNav: (state) => {
      state.navbarExpanded = true;
    },
    compressNav: (state) => {
      state.navbarExpanded = false;
    },
    pinNav: (state) => {
      state.navbarPinned = true;
    },
    unpinNav: (state) => {
      state.navbarPinned = false;
    },
  },
});

export const {
  updateTitle,
  expandNav,
  compressNav,
  pinNav,
  unpinNav,
} = navigationSlice.actions;

export default navigationSlice.reducer;
