import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router';

import makeStyles from '@mui/styles/makeStyles';

import CircularProgress from '@mui/material/CircularProgress';

import ClientInsuranceTypeForm from '../components/client_institution-form';

import { apiFetch } from '../lib/fetch';

const useStyles = makeStyles({
  loadingCircle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
  },
});

const ClientInsuranceType = () => {
  const [isNew, setIsNew] = useState(false);
  const params = useParams();
  const location = useLocation();

  const { client_institutionId } = params;
  const [client_institution, setClientInsuranceType] = useState({
    name: '',
    active: true,
  });

  function findClientInsuranceType(id) {
    return apiFetch(`/client_institutions/${id}`, { method: 'GET' });
  }

  useEffect(async () => {
    setIsNew(location.pathname === '/client_institutions/new');
    if (client_institutionId) {
      const client_institution = await findClientInsuranceType(client_institutionId);
      setClientInsuranceType(client_institution);
    }
  }, []);

  const handleTextChange = (e) => {
    const { name, value } = e.target;
    setClientInsuranceType({ ...client_institution, [name]: value });
  };

  const handleToggleChange = (e) => {
    const { name, checked } = e.target;
    setClientInsuranceType({ ...client_institution, [name]: checked });
  };

  const classes = useStyles();

  return (
    <div style={classes.container}>
      <div>
        {
      location.pathname === '/client_institutions/new' || client_institution ? (
        < ClientInsuranceTypeForm
          client_institution={client_institution}
          isNew={isNew}
          handleTextChange={handleTextChange}
          handleToggleChange={handleToggleChange}
        />
      ) : (
        <div className={classes.loadingCircle}>
          <CircularProgress size={100} />
        </div>
      )
}

      </div>
    </div>
  );
};

export default ClientInsuranceType;
