import React from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'lodash';
import useSWR from 'swr';

import { colors } from '../lib/styles';
import ConfigTypesLayout from '../components/config-types-layout';

const ClientAnnuityTypes = () => {
  const columns = [
    {
      field: 'name',
      headerName: 'Client Annuity Type',
      align: 'left',
    },
    {
      field: 'active',
      headerName: 'Active',
      align: 'right',
    },
    {
      field: 'edit',
      headerName: 'Edit',
      align: 'right',
    },
  ];

  const { data: annuityTypes, isValidating } = useSWR('/client_annuity_types');

  let rows = [];
  if (annuityTypes) {
    rows = annuityTypes.map((row) => {
      return {
        id: row.id,
        active: capitalize(String(row.active)),
        name: row.name,
        order_val: row.order_val,
        edit: <Link to={`/client_annuity_types/${row.id}/edit`} style={{ color: colors.darkAccent }}>Edit</Link>,
      };
    });
  }

  return (
    <ConfigTypesLayout
      rows={rows}
      isValidating={isValidating}
      columns={columns}
      title="Client Annuity Type"
      path="client_annuity_types"
    />
  );
};

export default ClientAnnuityTypes;
