import React, { useState } from 'react';
import { useParams } from 'react-router';
import makeStyles from '@mui/styles/makeStyles';

// import { apiFetch } from '../lib/fetch';
import { Button } from '../lib/common';
import config from '../config';

const useStyles = makeStyles({
  container: {
    margin: '5px',
    display: 'flex',
    backgroundColor: 'white',
  },
  noteContainer: {
    border: '1px solid grey',
    padding: '3px',
    background: 'white',
  },
  noteText: {
    width: '100%',
    minHeight: '20px',
  },
  notePre: {
    whiteSpace: 'pre-wrap',
  },
  noteBy: {
    backgroundColor: '#DDD',
  },
});

const ImageViewer = () => {
  const classes = useStyles();
  const params = useParams();
  const [downloading, setDownloading] = useState(false);
  const [maybeFinished, setMaybeFinished] = useState(false);
  const [loading, setLoading] = useState(true);
  const [closeable, setCloseable] = useState(false);
  const { id, type } = params;

  const imgUrl = `${config.API_URL}/${type}/${id}/download`;

  return (
    <div className={classes.container}>
      <div>
        {closeable ? (
          <Button
            onClick={() => {
              window.close();
            }}
            style={{ margin: '10px' }}
          >
            Close
          </Button>
        ) : ''}
        {!loading && !downloading ? (
          <div style={{ float: 'right' }}>
            <Button
              onClick={() => {
                setTimeout(() => {
                  setCloseable(true);
                }, 1000);
                setDownloading(true);
                setTimeout(() => {
                  setMaybeFinished(true);
                }, 3000);
                document.location.href = imgUrl;
              }}
              style={{ margin: '10px' }}
            >
              Download
            </Button>
          </div>
        ) : ''}
        {loading ? (
          <div>Loading...</div>
        ) : ''}
        {downloading && !maybeFinished ? (
          <div>downloading...</div>
        ) : ''}
        <br/>
        <div
          style={{
            width: '90%',
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: 'white',
            alignSelf: 'center',
          }}
        >
          <img
            src={`${imgUrl}?inline=true`}
            alt="client file"
            style={{ width: '100%' }}
            onLoad={() => {
              setLoading(false);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageViewer;
