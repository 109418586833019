import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { DataGrid } from '@mui/x-data-grid';
import makeStyles from '@mui/styles/makeStyles';

import { colors } from '../lib/styles';
import Layout from '../components/layout';

const useStyles = makeStyles({
  container: {
    margin: 10,
  },
  gridContainer: {
    width: '100%',
    display: 'flex',
    height: 450,
  },
});

const columns = [
  {
    field: 'num',
    headerName: 'Agent #',
    align: 'right',
    width: 80,
    renderCell: (params) => {
      return <Link to={`/users/${params.row.id}/overview`} style={{ color: colors.darkAccent }}>{params.row.num}</Link>;
    },
  },
  {
    field: 'firstname',
    headerName: 'First Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'lastname',
    headerName: 'Last Name',
    align: 'left',
    width: 150,
  },
  {
    field: 'highest_tier',
    headerName: 'Highest Pillar',
    align: 'right',
    width: 110,
  },
  {
    field: 'latest_tier',
    headerName: 'Latest Pillar',
    align: 'right',
    width: 110,
  },
  {
    field: 'overpay_total',
    headerName: 'Total',
    align: 'right',
    width: 130,
    renderCell: (params) => {
      return <span>{Number(params.row.overpay_total).toFixed(2)}</span>;
    },
  },
  {
    field: 'overpay_count',
    headerName: '# WS Payouts',
    align: 'right',
    width: 130,
    renderCell: (params) => {
      return <span>{Number(params.row.overpay_count)}</span>;
    },
  },
  {
    field: 'id',
    headerName: 'Details',
    align: 'right',
    width: 100,
    renderCell: (params) => {
      return <Link to={`/users/${params.row.id}/overrides`} style={{ color: colors.darkAccent }}>Details</Link>;
    },
  },
];

const WealthSharePayments = () => {
  const classes = useStyles();
  const [err] = useState(null);
  const url = '/users/wealthshare/perf?orderBy=latest_tier&is_deleted=false';

  const { data: payments, loading: paymentsLoading } = useSWR(url);
  // const dlName = user ? `${user.firstname}_${user.lastname}_${user.num}_override-payments` : 'override-payments';
  let cleanPayments = [];
  if (payments && payments.length) {
    cleanPayments = payments.map((p) => {
      p.amount = Number(p.amount);
      p.overpay_count = Number(p.overpay_count);
      p.overpay_total = Number(p.overpay_total);
      p.highest_tier = Number(p.highest_tier);
      p.latest_tier = Number(p.latest_tier);
      p.id = p.agent;
      return p;
    }).filter((p) => p.latest_tier > 0);
  }

  return (
    <Layout headerTitle="Wealthshare Leaderboard" >
      <div className={classes.container}>
        <div>{err?.toString()}</div>
        <Paper>
          {!paymentsLoading ? (
            <div className={classes.gridContainer}>
              <DataGrid columns={columns} rows={cleanPayments} />
            </div>
          ) : <CircularProgress/>}
        </Paper>
      </div>
    </Layout>
  );
};

export default WealthSharePayments;
